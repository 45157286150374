import React from "react";
import { Skeleton, TableCell, TableRow } from "@mui/material";

const TableShimmer = ({ columns }) =>
  [...Array(10)].map((_, i) => (
    <TableRow key={-i}>
      {[...Array(columns)].map((_, j) => {
        return (
          <TableCell key={-j}>
            <Skeleton animation="wave" />
          </TableCell>
        );
      })}
    </TableRow>
  ));

export default TableShimmer;
