import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { styles } from "./styles";
import ChangeRequestsStats from "./Stats";
import { TabContext, TabPanel } from "@mui/lab";
import { styles as positionStyles } from '../AddPosition/styles'
import AllChangeRequests from "./AllRequests";
import ApproverChangeRequests from "./ApproverChangeRequests";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

const views = [
  {
    label: 'Change Requests',
    value: 'change-requests',
  },
  {
    label: 'Approval Requests',
    value: 'approval-requests',
  }
]

const ChangeRequests = () => {
  // TODO: to be replaced with approver role
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const urlType = searchParams && searchParams.get("type")
  const [view, setView] = useState(urlType || 'change-requests');

  const handleTabNavigation = (value) => {
    setView(value)
    navigate(`?type=${value}`)
  }

  return (
    <Box sx={styles.growBox}>
      <Container maxWidth="lg" sx={styles.pageWrapper}>
        <Box id="changeRequestHeader" sx={{ pt: '12px !important', pb: '32px' }}>
          <Box sx={styles.flexCenterNone}>
            <Typography variant="h4">Change Requests</Typography>
            <Box sx={styles.growBox} />
          </Box>
        </Box>
        <ChangeRequestsStats />
        <Card sx={styles.dataWrappper}>
          <CardContent>
            <TabContext value={view}>
              <Box>
                <Tabs
                  value={view}
                  onChange={(event, value) => handleTabNavigation(value)}
                  aria-label='basic tabs example'
                >
                  {views.map((option) => (
                    <Tab key={option.label} disabled={false} label={option.label} value={option.value} />
                  ))}
                </Tabs>
                <Divider />
              </Box>
              <TabPanel sx={{ ...positionStyles.tabpanel, pt: 0 }} value={'change-requests'}>
                <AllChangeRequests />
              </TabPanel>
              <TabPanel sx={{ ...positionStyles.tabpanel, pt: 0 }} value={'approval-requests'}>
                <ApproverChangeRequests />
              </TabPanel>
            </TabContext>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default ChangeRequests;
