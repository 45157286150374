import React, { useEffect, useState, useCallback } from 'react';
import { Box, Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Plus as PlusIcon } from '../../assets/icons/plus';
import { styles } from './styles';
import CustomTable from '../../components/CustomTable';
import { api, formDataApi } from '../../axios';
import { LocationsFilter } from '../../components/Locations/LocationFilter';
import { columns } from './columns';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { updateLocations, updateSelectedLocations, updateAllSelectedLocations } from '../../store/slices/locations';
import PermissionChecker from '../../components/PermissionChecker';

const Locations = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [controller, setController] = useState({
    filters: [],
    page: 0,
    sort: 'desc',
    sortBy: 'id',
    view: 'all',
    searched: false,
    pageSize: 10,
  });

  const { enqueueSnackbar } = useSnackbar();
  const { locations, selectedLocations } = useSelector((state) => state.locations);

  const fetchData = useCallback(async (searched, filters, sort, sortBy, page, pageSize) => {
    setLoading(true);
    try {
      if (searched) {
        const query = new URLSearchParams({});
        if (filters.length) {
            // filters.forEach((filter) => query.append(filter.property, filter.value));
            query.append('c', filters[0].property);
            query.append('q', filters[0].value);
        }
        if (sortBy) {
          query.append('sort', sortBy);
          query.append('order', sort);
        }
        query.append('offset', page * pageSize);
        query.append('max', pageSize);
        const response = await api.post(`/searchLocationAction?${query.toString()}`);
        if (response.data) {
          dispatch(updateLocations(response.data));
        }
      } else {
        const query = new URLSearchParams({});
        if (sortBy) {
          query.append('sort', sortBy);
          query.append('order', sort);
        }
        query.append('offset', page * pageSize);
        query.append('max', pageSize);
        const response = await api.post(`/locations?${query.toString()}`);
        if (response.data) {
          dispatch(updateLocations(response.data));
        }
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (fetchData)
      fetchData(controller.searched, controller.filters, controller.sort, controller.sortBy, controller.page, controller.pageSize);
  }, [controller, fetchData]);

  const handleFiltersApply = (newFilters) => {
    const parsedFilters = newFilters.map((filter) => ({
      property: filter.property.name,
      value: filter.value,
      operator: filter.operator.value,
    }));

    setController({
      ...controller,
      page: 0,
      filters: parsedFilters,
      searched: true,
    });
  };
  const handleFiltersClear = () => {
    setController({
      ...controller,
      page: 0,
      filters: [],
      searched: false,
    });
  };
  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage - 1,
    });
  };

  const handlePageSizeChange = (newPageSize) => {
    setController({
      ...controller,
      page: 0,
      pageSize: newPageSize
    });
    
  }

  const handleSortChange = (event, property) => {
    const isAsc = controller.sortBy === property && controller.sort === 'asc';

    setController({
      ...controller,
      page: 0,
      sort: isAsc ? 'desc' : 'asc',
      sortBy: property,
    });
  };

  const handleRowClick = (row) => {
    navigate(`/settings/locations/${row}`);
  };

  const handleSelect = (event, rowId) => {
    dispatch(updateSelectedLocations({ isChecked: event.target.checked, rowId }));
  };

  const handleSelectAll = (event) => {
    dispatch(updateAllSelectedLocations({ isChecked: event.target.checked }));
  };

  const handleDeleteLocation = async (id) => {
    const bodyFormData = new FormData();
    bodyFormData.append('id', id);
    try {
      const response = await formDataApi.post('/deleteLocation', bodyFormData);
      if (response.data?.status === 'SUCCESS') {
        enqueueSnackbar('Location deleted successfuly', { variant: 'success' });
        fetchData(controller.searched, controller.filters, controller.sort, controller.sortBy, controller.page);
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  return (
    <Box sx={styles.growBox}>
      <Container maxWidth='lg' sx={styles.pageWrapper}>
        <Grid container direction='column' spacing={2}>
          <Grid
            sx={{ py: '32px !important' }}
            item
            container
            spacing={1}
            justifyContent='flex-start'
            alignItems='center'
            py={4}
          >
            <Grid item>
              <Typography variant='h4'>Locations</Typography>
            </Grid>
            <Grid item xs></Grid>
            <PermissionChecker
              requiredPermissions={['ROLE_BASE_PERMISSION_INDEX_CREATE_LOCATION']}
              noAuthRedirect={false}
            >
              <Grid item xs='auto'>
                <Button
                  color='primary'
                  onClick={() => navigate('/settings/locations/add')}
                  size='medium'
                  startIcon={<PlusIcon fontSize='small' />}
                  variant='contained'
                >
                  Add Location
                </Button>
              </Grid>
            </PermissionChecker>
          </Grid>
        </Grid>
        <Grid item sx={{ pt: '0px !important' }}>
          <Card>
            <CardContent>
              <LocationsFilter
                disabled={false}
                filters={controller.filters}
                onFiltersApply={handleFiltersApply}
                onFiltersClear={handleFiltersClear}
                selectedLocations={selectedLocations}
                filterProperties={columns}
              />
              <CustomTable
                onRowClick={handleRowClick}
                isLoading={loading}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                onSelect={handleSelect}
                onSelectAll={handleSelectAll}
                onSortChange={handleSortChange}
                page={controller.page + 1}
                columns={columns}
                rows={locations.data}
                rowsCount={locations.count}
                selectedRows={selectedLocations}
                sort={controller.sort}
                sortBy={controller.sortBy}
                showRowSelector={false}
                showPagignation
                keyColumn='id'
                action={handleDeleteLocation}
              />
            </CardContent>
          </Card>
        </Grid>
      </Container>
    </Box>
  );
};

export default Locations;
