import { useEffect, useState } from 'react'
import { Grid, Box, Button, Card, CardContent, Container, Typography, Divider } from '@mui/material'
import toast from 'react-hot-toast'
import { ChevronLeft as ChevronLeftIcon } from '../../../assets/icons/chevron-left'
import CompanyData from '../CompanyData'
import { styles as positionStyles } from '../../Positions/styles'
import { styles } from './styles'
import { useNavigate, useParams } from 'react-router-dom'
import { formDataApi } from '../../../axios'
import { ActionsMenu } from '../../../components/ActionsMenu'
import { Delete, Edit } from '@mui/icons-material'
import { ConfirmationDialog } from '../../../components/ConfirmationDialog'
import { utilStyles } from '../../../utils/styles'

const View = (props) => {
  const navigate = useNavigate()
  const [range, setRange] = useState('Modify')
  const params = useParams()
  const [companyDetails, setCompanyDetails] = useState({ isLoading: true })
  const [openDialog, setOpenDialog] = useState(false);

  const handleCancel = () => {
    setOpenDialog(false);
  };

  const deleteCompany = async () => {
    setOpenDialog(false);

    const bodyFormData = new FormData();
    bodyFormData.append('id', Number(params.id));
    try {
      const response = await formDataApi.post('/deleteCompany', bodyFormData);
      if (!response?.data) {
        toast.error('Something went wrong! Position could not be deleted.');
      }
    } catch (err) {
      toast.error('Something went wrong! Position could not be deleted.');
    }
  }



  useEffect(() => {
    setCompanyDetails({ ...companyDetails, isLoading: true })
    async function fetchData() {
      const bodyFormData = new FormData()
      bodyFormData.append('id', params.id)
      try {
        const response = await formDataApi.post('/displayCompany', bodyFormData)
        if (response?.data) {
          setCompanyDetails(response?.data)
        }
      } catch (err) {
        setCompanyDetails({ isLoading: false })
        toast.error('Something went wrong! Customer could not be fetched.')
      }
    }
    fetchData()
  }, [params.id])


  const actions = [
    {
      label: 'Edit',
      onClick: () => {
        setRange('Edit')
        navigate(`/company/edit/${params.id}`)
      },
      icon: <Edit fontSize='small' sx={utilStyles.mr(2)} />
    },
    {
      label: 'Delete',
      onClick: (id) => {
        setRange('Delete')
        setOpenDialog(true)
      },
      icon: <Delete fontSize='small' sx={utilStyles.mr(2)} />
    }
  ]

  return (
    <Box sx={positionStyles.growBox}>
      <Container maxWidth='lg' sx={styles.pageWrapper}>
        <Grid container direction='column' spacing={2}>
          <Grid item container justifyContent='space-between'>
            <Typography variant='h4'>Customer</Typography>
            {<ActionsMenu actions={actions} label={range} size='large' variant='outlined' sx={styles.actionMenuButton} />}
          </Grid>
          <Grid item>
            <Card {...props}>
              <CardContent>
                <Grid container direction='column' spacing={2}>
                  <Grid item>
                    <Button startIcon={<ChevronLeftIcon />} variant='text' color='secondary' onClick={() => navigate('/company')}>
                      Back
                    </Button>
                  </Grid>
                </Grid>
                <Grid item>
                  <CompanyData position={companyDetails} />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <ConfirmationDialog
              title="Delete Company"
              message="Are you sure you want to delete company?"
              open={openDialog}
              onCancel={handleCancel}
              onConfirm={deleteCompany}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default View
