import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

export const usePositionPublicationStatus = () => {
  const [disablePublish, setDisablePublish] = useState(false);
  const [disableCR, setDisableCR] = useState(false);
  const { positions, selectedPositions } = useSelector((state) => state.positions);

  useEffect(() => {
    positions.data.every((position) => {
      if (selectedPositions.indexOf(position.id) !== -1) {
        if (position.publicationStatus === 'Unpublished') {
          setDisablePublish(true);
          return false;
        } else {
          setDisablePublish(false);
        }
      }
      return true;
    });
    positions.data.every((position) => {
      if (selectedPositions.indexOf(position.id) !== -1) {
        if (!position.hasOwnProperty('changeRequestPolicy')) {
          setDisableCR(true);
          return false;
        } else {
          setDisableCR(false);
        }
      }
      return true;
    });
  }, [positions, selectedPositions]);

  return [disablePublish, disableCR];
};
