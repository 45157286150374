import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { alpha } from '@mui/material/styles';

export const InputField = (props) => {
  const { InputProps, InputLabelProps, onChange, value, sx, ...other } = props;

  const updatedValue = value === null || value === undefined ? '' : value;

  return (
    <TextField
      size='small'
      value={updatedValue}
      onChange={onChange}
      inputProps={{
        sx: {
          alignItems: 'center',
          display: 'flex',
          fontSize: 14,
          px: 1.5,
          py: 0.75,
          fontWeight: 400,
          lineHeight: '22px',
          letterSpacing: '0.15000000596046448px',
          '&.MuiInputBase-inputAdornedStart': {
            pl: 0,
          },
        },
      }}
      InputLabelProps={{
        sx: {
          color: 'text.primary',
          fontSize: 12,
          fontWeight: 500,
        },
      }}
      sx={{
        '& .MuiInputBase-root': {
          height: 48,
        },
        '& .MuiFilledInput-root': {
          backgroundColor: 'background.paper',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: (theme) => (theme.palette.mode == 'light' ? 'neutral.300' : 'neutral.700'),
          borderRadius: 1,
          boxShadow: '0px 1px 2px 0px rgba(9, 30, 66, 0.08)',
          overflow: 'hidden',
          p: 0,
          transition: (theme) => theme.transitions.create(['border-color', 'box-shadow']),
          '&:before': {
            borderBottom: 0,
          },
          '&:hover': {
            backgroundColor: 'background.paper',
          },
          '&.Mui-focused': {
            backgroundColor: 'background.paper',
            boxShadow: (theme) => `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          },
          '&.Mui-disabled': {
            backgroundColor: 'action.disabledBackground',
            boxShadow: 'none',
            borderColor: alpha('#D6DBE1', 0.5),
          },
          '.MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)':
            {
              mt: 0,
              ml: 1.5,
            },
        },
        ...sx,
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        ...InputProps,
        // disableUnderline: true
      }}
      {...other}
    />
  );
};

InputField.propTypes = {
  InputProps: PropTypes.object,
  sx: PropTypes.object,
};
