import { Box, Button, Card, CardContent, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, MenuItem, Skeleton, Typography } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { ChevronLeft } from "@mui/icons-material";
import {formDataApi } from "../../axios";
import { styles } from './styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from "react-router-dom";
import { StyledMenu } from "../ViewCRPolicies";
import PermissionChecker from "../../components/PermissionChecker";
import { ModifyRule } from "./ModifyRule";
import { AddRule } from "./AddRule";
import { useSnackbar } from "notistack";
import { PolicyStatusColor } from "./columns";
import AuditLog from "../../components/AuditLog";

export const initialFieldDefinitions = {
  1: { 
    "id":1,
    "fieldLabel":"Designation",
    "fieldDataType":'Single Selection List',
    "fieldListOptionsData": {
      key: "designationsList",
      id: "id",
      value: "name"
    },
  },
  2: {
    "id":2,
    "fieldLabel": "Budget Lower Limit",
    "fieldDataType":'String',
  },
  3: {
    "id":3,
    "fieldLabel":"Budget Upper Limit",
    "fieldDataType":'String',
  },
  4: {
    "id":4,
    "fieldLabel":"Budget Mid Limit",
    "fieldDataType":'String',
  },
  5: {
    "id":5,
    "fieldLabel":"Department",
    "fieldDataType":'Single Selection List',
    "fieldListOptionsData": {
      key: "departmentsList",
      id: "id",
      value: "name"
    },
  },
  6: {
    "id":6,
    "fieldLabel":"Location",
    "fieldDataType":'Single Selection List',
    "fieldListOptionsData": {
      key: "locationsList",
      id: "id",
      value: "name"
    },
  },
  7: {
    "id":7,
    "fieldLabel":"Position Code",
    "fieldDataType":'String'
  },
  8: {
    "id":8,
    "fieldLabel":"Position Status",
    "fieldDataType":'String'
  },
  9: {
    "id":9,
    "fieldLabel":"Position Start Date",
    "fieldDataType":'Date'
  }
}

export const ViewRecruitmentPolicy = () => {
    const [isLoading, setLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [resyncing, setResyncing] = useState(false);
    const params = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [modifyId, setModifyId] = useState();
    const [action, setAction] = useState("view");
    const [anchorEl, setAnchorEl] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const open = Boolean(anchorEl);

    useEffect(() => {
      action === "add" && window.scrollTo(0, document.body.scrollHeight);
    }, [action]); 

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
          const bodyFormData = new FormData();
          bodyFormData.append('recruitmentPolicyId', params.id);
          const preRuleData = await formDataApi.post(`/preCreateRulesForRecruitmentPolicy`,bodyFormData);
          const prePositionData = await formDataApi.post(`/preCreatePosition`);
          if (prePositionData.data){
             let definitions = JSON.parse(JSON.stringify(initialFieldDefinitions));
             Object.keys(definitions)?.map(item => {
               if(definitions[item].fieldDataType === "Single Selection List" || definitions[item].fieldDataType === 'Multiple Selection List'){
                 const fieldListOptionsData = definitions[item]["fieldListOptionsData"];
                 const listOptions = prePositionData.data[fieldListOptionsData?.key]?.map(item => ({
                  key: item[fieldListOptionsData?.id],
                  label: item[fieldListOptionsData?.value],
                 }))
                 definitions[item]["fieldListOptions"] = listOptions || []
               }
             })

             if(prePositionData.data?.extendedDef?.metaCustomFieldDefinitions){
              prePositionData.data?.extendedDef?.metaCustomFieldDefinitions?.map(item => {
                definitions[item.id] = {
                  id: item.id,
                  fieldLabel: item.fieldLabel,
                  fieldDataType: item.fieldDataType,
                  fieldListOptions: item.fieldListOptions
                }
              })
            }

            if(preRuleData?.data){
              const attributes = preRuleData?.data?.attributes.map(item => ({
                ...item,
                definitions: definitions[item.id]
              }))

              setData({...preRuleData?.data, attributes});
            }
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }, []);

    useEffect(() => {
        fetchData();
    },[])

    const handleBack = () => {
      navigate("/recruitmentPolicies")
    }

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleRefresh = () => {
      fetchData();
      setAction("view")
    }

    const handleModify = (id) => {
      setAction("modify")
      setModifyId(id)
    }

    const handleAdd = () => {
      setAction("add");
      setModifyId();
      handleClose();
    }

    const handleResync = async () => {
      if(resyncing) return;
      const bodyFormData = new FormData();
      bodyFormData.append('recruitmentPolicyId', params.id);
      try {
        setResyncing(true)
        const response = await formDataApi.post('/resyncSingleRecruitmentPolicy',bodyFormData);
        if (response?.data && response?.data?.status === "SUCCESS"){
          enqueueSnackbar(response?.data?.message, { variant: 'success' });
        }else enqueueSnackbar(response?.data?.message, { variant: 'error' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      } finally {
        setOpenDialog(false)
        setResyncing(false)
      }
    }

    return <>
    <Box sx={styles.growBox}>
      <Container>
        <Box id="modifyChangeRequestHeader" sx={{ pt: '12px !important', pb: '32px' }}>
          <Box>
            <Typography variant="h4">
              Recruitment Policy
            </Typography>
          </Box>
        </Box>
        <Card sx={{ ...styles.dataWrappper, pt: 0, mt: 0 }}>
          <CardContent>
            <>
              <Grid container direction='column' spacing={2}>
                <Grid item sx={{display: "flex"}}>
                    <Button startIcon={<ChevronLeft />} variant='text' color='secondary' onClick={handleBack}>
                      Back
                    </Button>
                    <Box sx={styles.growBox} />
                    <Box>
                      <Button
                        id='demo-customized-button'
                        aria-controls={open ? 'demo-customized-menu' : undefined}
                        aria-haspopup='true'
                        aria-expanded={open ? 'true' : undefined}
                        variant='contained'
                        disableElevation
                        onClick={handleClick}
                        endIcon={!open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                        sx={styles.selectButton}
                      >
                        <span>Actions</span>
                      </Button>
                      <StyledMenu
                        id='demo-customized-menu'
                        MenuListProps={{
                          'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                      >
                        {data?.attributes?.length !== 0 && <PermissionChecker
                          requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_SAVE_RULES_FOR_RECRUITMENT_POLICY']}
                          noAuthRedirect={false}
                        >
                          {data?.recruitmentPolicyStatus?.toLowerCase() === "active" && <MenuItem onClick={handleAdd}>
                            <AddIcon />
                            Add Rule
                          </MenuItem>}
                        </PermissionChecker>}
                        {data?.attributes?.length !== 0 && <PermissionChecker
                          requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_RESYNC_SINGLE_RECRUITMENT_POLICY']}
                          noAuthRedirect={false}
                        >
                          {data?.recruitmentPolicyStatus?.toLowerCase() === "active" && <MenuItem onClick={() => setOpenDialog(true)}>
                            <AutorenewIcon />
                            Resync
                          </MenuItem>}
                        </PermissionChecker>}
                      </StyledMenu>
                    </Box>
                </Grid>
                <Grid item marginX={4}>
                  <Box>
                    <Typography variant="h5">
                      Recruitment Policy Details
                    </Typography>
                  </Box>
                </Grid>
                {isLoading 
                  ? <Grid item marginX={4}>
                      <Skeleton height={42} />
                      <Skeleton height={42} />
                      <Skeleton height={42} />
                    </Grid> 
                  : <Grid item marginX={4}>
                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                          <Box sx={styles.dataItem}>
                            <Typography component={'span'} sx={styles.dataItemLable}>
                              Name
                            </Typography>
                            <Typography component={'span'} sx={styles.dataItemValue}>
                              {data?.recruitmentPolicyName}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box sx={styles.dataItem}>
                            <Typography component={'span'} sx={styles.dataItemLable}>
                              Status
                            </Typography>
                            <Box>
                            <Chip label={ data?.recruitmentPolicyStatus} sx={{ backgroundColor: PolicyStatusColor(data?.recruitmentPolicyStatus) }} />
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid>
                        {data?.attributes?.length > 0
                          ? data?.rules?.length > 0 || action === "add"
                            ? data?.rules?.map((item, index) => 
                                <ModifyRule key={item.id} index={index} rule={item} data={data} modifyId={modifyId} handleModify={handleModify}  handleRefresh={handleRefresh}/>)
                            : <Box py="20px" textAlign="center">No rules found</Box>
                          : action !== "add" && <Box py="20px" textAlign="center">No rule attributes found</Box>}
                        {action === "add" && <AddRule data={data} handleCancel={() => setAction("view")} handleRefresh={handleRefresh}/>}
                      </Grid>
                    </Grid>}
              </Grid>
              <Box mt={3} mx={2}>
                <AuditLog data={data?.commentsMap?.commentsMap || []} />
              </Box>
            </>
          </CardContent>
        </Card>
      </Container>
      <Dialog open={openDialog} >
            <DialogContent sx={styles.tableMenuDialog}>
            <Box sx={styles.tableMenuDialog}>
                <DialogTitle>Resync Recruitment Policy</DialogTitle>
                <DialogContentText>Are you sure you want to resync this Recruitment Policy with Positions?</DialogContentText>
            </Box>
            <DialogActions>
                <Button size='medium' variant='text' onClick={() => setOpenDialog(false)} disabled={resyncing}>
                No
                </Button>
                <Button size='medium' variant='contained' onClick={handleResync} startIcon={resyncing ? <CircularProgress  color='inherit' size='1.2rem' /> : <></>}>
                Yes
                </Button>
            </DialogActions>
            </DialogContent>
        </Dialog>
    </Box>
  </>
}
