import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Export as ExportIcon } from '../../assets/icons/export';
import PositionBulkActions from './PositionBulkActions';
import { FilterDialog } from '../FilterDialog';

import PermissionChecker from '../PermissionChecker';

import { useSnackbar } from 'notistack';
import fileDownload from 'js-file-download';
import { downloadFileApi } from '../../axios';

import { useSelector } from 'react-redux';
import { hasPermission } from '../../utils/permission';

export const PositionFilter = (props) => {
  const {
    filters,
    initialFilters,
    onFiltersApply,
    onFiltersClear,
    selectedPositions,
    filterProperties,
    onBulkStatusChangeConfirmation,
  } = props;

  const [openFilterDialog, setOpenFilterDialog] = useState(false);

  const [downloading, setDownloading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasCompaExportPermisson = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_RECRUIT_INDEX_COMPA_EXPORT_POSITIONS_DATA',
  ]);

  const downloadPositionData = async () => {
    try {
      const exportApiPath = userHasCompaExportPermisson ? '/exportPositionDataWithCompaFields' : '/exportPositionData';
      setDownloading(true);
      const response = await downloadFileApi.post(exportApiPath);

      fileDownload(new Blob([response.data]), 'positions.xlsx');
    } catch (error) {
      console.log('inside catche ', error);
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setDownloading(false);
    }
  };

  return (
    <>
      <Grid container spacing={1} direction='row-reverse' justifyContent='flex-start' alignItems='center' mb={2}>
        {selectedPositions.length > 0 && (
          <PermissionChecker
            requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_BULK_UPDATE_POSITION_STATUS']}
            noAuthRedirect={false}
          >
            <Grid item alignContent='flex-end' xs='auto'>
              <PositionBulkActions
                selectedPositions={selectedPositions}
                selectedCount={selectedPositions.length}
                onBulkStatusChangeConfirmation={onBulkStatusChangeConfirmation}
              />
            </Grid>
          </PermissionChecker>
        )}
        <PermissionChecker
          requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_SEARCH_POSITIONS']}
          noAuthRedirect={false}
        >
          <Grid item alignContent='flex-end' xs='auto'>
            <Button
              color='primary'
              disabled={false}
              onClick={() => setOpenFilterDialog(true)}
              startIcon={<FilterAltIcon />}
              size='medium'
              variant={filters.length ? 'contained' : 'text'}
            >
              Filter
            </Button>
            <FilterDialog
              onApply={onFiltersApply}
              onClear={onFiltersClear}
              onClose={() => setOpenFilterDialog(false)}
              open={openFilterDialog}
              operators={[]}
              properties={filterProperties}
              initialFilters={initialFilters}
            />
          </Grid>
        </PermissionChecker>
        <PermissionChecker
          requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_EXPORT_POSITIONS_DATA']}
          noAuthRedirect={false}
        >
          <Grid item alignContent='flex-end' xs='auto'>
            <LoadingButton
              loading={downloading}
              color='secondary'
              disabled={false}
              onClick={downloadPositionData}
              size='medium'
              variant='text'
              startIcon={<ExportIcon fontSize='small' />}
            >
              Export
            </LoadingButton>
          </Grid>
        </PermissionChecker>
      </Grid>
    </>
  );
};

PositionFilter.defaultProps = {
  filters: [],
  selectedPositions: [],
  view: 'all',
  actionItems: <></>,
};

PositionFilter.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.array,
  onFiltersApply: PropTypes.func,
  onFiltersClear: PropTypes.func,
  onQueryChange: PropTypes.func,
  onViewChange: PropTypes.func,
  query: PropTypes.string,
  selectedPositions: PropTypes.array,
  view: PropTypes.string,
  actionItems: PropTypes.element,
};
