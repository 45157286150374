import React, { useEffect, useState, useMemo } from 'react';
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  MenuItem,
  Typography,
  Box,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { InputField } from '../InputField';
import { DateCalendar } from '../DateCalendar';
import moment from 'moment';
import { styles } from './styles';


const UpdatePositionStartDate = (props) => {
  const { open, onResult, title, confirmation, customModal, actionType, data, item, setItem, handleConfirm, handleClose, ...other } = props;
  const [openConfirmation, setOpenConfirmation] = useState(false);

  useEffect(() => {
    data?.startDate && setItem({ startDate: data.startDate})
  },[data])

  const handleChangeConfirmation = () => {
    setOpenConfirmation(true)
  }

  return <>
    <DialogTitle disableTypography>
    <Typography variant='h4' align='center'>
      {!openConfirmation ? title  : "Confirm Position Start Date"}
    </Typography>
    <Typography variant='subtitle1' align='center'>
      {confirmation}
    </Typography>
  </DialogTitle>
  <DialogContent sx={{margin: "0 32px"}}>
      {openConfirmation ? <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography variant='subtitle1' mx="24px">
          Are you sure you want to update position details of
        </Typography>
          <Box sx={styles.infoLine}></Box>
          <Typography variant={'body2'} mx="24px">{`Position Code: ${data?.positionCode}`}</Typography>
          <Typography variant={'body2'} mx="24px" mt="6px">{`Current Start Date: ${data?.startDate ? moment(data?.startDate).format('MMM DD YYYY'): '-'}`}</Typography>
          <Typography variant={'body2'} mx="24px" mt="6px" color={"#3F455A"}>{`Updated Start Date: ${item?.startDate ? moment(item?.startDate).format('MMM DD YYYY'): '-'}`}</Typography>
          <Box sx={styles.infoLine}></Box>
        </Grid>
      </Grid>: <Grid container spacing={2}>
        <Grid item xs={12}>
        <Box sx={styles.infoLine}></Box>
        <Typography variant={'body2'} mx="24px">{`Position Code: ${data?.positionCode}`}</Typography>
        <Typography variant={'body2'} mx="24px" mt="6px">{`Current Start Date: ${data?.startDate ? moment(data?.startDate).format('MMM DD YYYY'): '-'}`}</Typography>
        <Box sx={styles.infoLine}></Box>
        <Typography variant='subtitle1' mx="24px">
          Select the updated date from calendar
        </Typography>
        <DateCalendar  
          value={item.startDate}
          onChange={(_key, value) => setItem({ ...item, startDate: value })}
          disableFuture={true}/>
        </Grid>
      </Grid>
    }
  </DialogContent>
  <DialogActions>
    <Button variant='text' color='primary' onClick={handleClose}>
      Cancel
    </Button>
    <Button
      disabled={(data?.startDate && moment(data?.startDate).format("YYYY-MM-DD")) === (item?.startDate && moment(item?.startDate).format("YYYY-MM-DD"))}
      variant='contained'
      color='primary'
      onClick={(!openConfirmation) ? handleChangeConfirmation : handleConfirm}
    >
      {(!openConfirmation) ? 'Update' : 'Confirm' }
    </Button>
  </DialogActions>
</>
}

const ChangeStatus = (props) => {
  const { open, onResult, title, confirmation, customModal, actionType, data, item, setItem, handleConfirm, handleClose, ...other } = props;
  const [modalType, setModalType] = useState(1);

  const filteredStatus = useMemo(() => {
    return data?.availablePositionStatuses?.filter((status) => status !== data?.status);
  },[data?.availablePositionStatuses, data?.status])

  const {mainTitle, subTitle, mainContent, actions} = useMemo(() => {
    switch(modalType){
      case 1: return {
        mainTitle: title,
        subTitle: confirmation,
        mainContent: <Grid container spacing={2} mb={4}>
        <Grid item xs={12}>
          <InputField
            fullWidth
            label='Change Position Status'
            name='change-status'
            onChange={(event) => setItem({ ...item, status: event.target.value })}
            select
            value={item.status || ''}
          >
            {(filteredStatus || [])?.map((status, index) => (
              <MenuItem key={index} value={status}>
                {status}
              </MenuItem>
            ))}
          </InputField>
        </Grid>
      </Grid>,
        actions: <><Button variant='text' color='primary' onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'                                      // Commeted position prompt integration 
          onClick={handleConfirm}                              // () => setModalType(2)
        >
          Confirm                                              
        </Button></>,                                          // Next
      }
      case 2: return {
        mainTitle: "Reset Start Date",
        subTitle: `Would you like to reset the start date for this position (${data?.positionCode})?`,
        mainContent: <></>,
        actions: <><Button variant='text' color='primary' onClick={handleConfirm}>
        Skip
      </Button>
      <Button
        variant='contained'
        color='primary'
        onClick={() => setModalType(3)}
      >
        Yes
      </Button></>,
      }
      case 3: return {
        mainTitle: "Update Position Start Date",
        subTitle: "",
        mainContent: <Grid container spacing={2}>
        <Grid item xs={12}>
        <Box sx={styles.infoLine}></Box>
        <Typography variant={'body2'} mx="24px">{`Position Code: ${data?.positionCode}`}</Typography>
        <Typography variant={'body2'} mx="24px" mt="6px">{`Current Start Date: ${data?.startDate ? moment(data?.startDate).format('MMM DD YYYY'): '-'}`}</Typography>
        <Box sx={styles.infoLine}></Box>
        <Typography variant='subtitle1' mx="24px">
          Select the updated date from calendar
        </Typography>
        <DateCalendar  
          value={item?.startDate || data?.startDate}
          onChange={(_key, value) => setItem({ ...item, startDate: value })}
          disableFuture={true}/>
        </Grid>
      </Grid>,
        actions: <>
        <Button variant='text' color='primary' onClick={handleClose}>
        Cancel
        </Button>
        <Button
          disabled={(data?.startDate && moment(data?.startDate).format("YYYY-MM-DD")) === (item?.startDate && moment(item?.startDate).format("YYYY-MM-DD"))}
          variant='contained'
          color='primary'
          onClick={() => setModalType(4)}
        >
          Update
        </Button>
      </>,
      }
      case 4: return {
        mainTitle: "Confirm Position Start Date",
        subTitle: " Are you sure you want to update position details of",
        mainContent: <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box sx={styles.infoLine}></Box>
          <Typography variant={'body2'} mx="24px">{`Position Code: ${data?.positionCode}`}</Typography>
          <Typography variant={'body2'} mx="24px" mt="6px" color={"#3F455A"}>{`Position Status: ${item?.status}`}</Typography>
          <Typography variant={'body2'} mx="24px" mt="6px">{`Current Start Date: ${data?.startDate ? moment(data?.startDate).format('MMM DD YYYY'): '-'}`}</Typography>
          <Typography variant={'body2'} mx="24px" mt="6px" color={"#3F455A"}>{`Updated Start Date: ${item?.startDate ? moment(item?.startDate).format('MMM DD YYYY'): '-'}`}</Typography>
          <Box sx={styles.infoLine}></Box>
        </Grid>
      </Grid>,
        actions: <>
          <Button variant='text' color='primary' onClick={handleClose}>
          Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </>,
      }
    }
  }, [modalType, item, data])

  return <>
    <DialogTitle disableTypography>
      <Typography variant='h4' align='center'>
        {mainTitle}
      </Typography>
      <Typography variant='subtitle1' align='center'>
        {subTitle}
      </Typography>
    </DialogTitle>
    <DialogContent sx={{margin: "0 32px"}}>
      {mainContent}
    </DialogContent>
    <DialogActions>
      {actions}
    </DialogActions>
    </>

}

const PositionBulkActionDialog = (props) => {
  const { open, onResult, title, confirmation, customModal, actionType, data, ...other } = props;
  const crPolicies = useSelector((state) => state.crPolicies.crPolicies);
  const [item, setItem] = useState({});

  const filteredCRPolicies = useMemo(() => {
    return crPolicies.filter((crPolicy) => crPolicy?.status !== 'Inactive' && crPolicy?.id !== data?.changeRequestPolicyId);
  },[crPolicies, data?.changeRequestPolicyId])

  const handleConfirm = () => {
    onResult(item);
    setItem({});
  };

  const handleClose = () => {
    onResult(null);
    setItem({});
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} {...other} maxWidth='sm' fullWidth sx={styles.dialog}>
        {customModal 
        ? <>
            {actionType === 'update-start-date' && <UpdatePositionStartDate {...props} item={item} setItem={setItem} handleConfirm={handleConfirm} handleClose={handleClose}/>}
            {actionType === 'change-status' && <ChangeStatus {...props}  item={item} setItem={setItem} handleConfirm={handleConfirm} handleClose={handleClose}/>}
          </> 
        : <><DialogTitle disableTypography>
          <Typography variant='h4' align='center'>
            {title}
          </Typography>
          <Typography variant='subtitle1' align='center' >
            {confirmation}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {actionType === 'reject' && (
            <Grid container alignItems='center' spacing={2} mb={2}>
              <Grid item xs={12}>
                <InputField
                  name='comment'
                  label='Comment'
                  placeholder='Type here...'
                  value={item.comment || ''}
                  onChange={(event) => setItem({ ...item, comment: event.target.value })}
                  fullWidth
                />
              </Grid>
            </Grid>
          )}
          {actionType === 'map-cr-policy' && (
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12}>
                {filteredCRPolicies?.length ? (
                  <InputField
                    fullWidth
                    label='Change Policy'
                    name='cr-policy'
                    onChange={(event) => setItem({ ...item, crPolicy: event.target.value })}
                    select
                    value={item.crPolicy || ''}
                  >
                    {filteredCRPolicies.map((crPolicy) => (
                      <MenuItem key={crPolicy.id} value={crPolicy.id}>
                        {crPolicy.name}
                      </MenuItem>
                    ))}
                  </InputField>
                ) : (
                  <>No Change Policies to map</>
                )}
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant='text' color='primary' onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={(actionType === 'map-cr-policy' && !filteredCRPolicies?.length)}
            variant='contained'
            color='primary'
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </DialogActions>
        </>}
      </Dialog>
    </>
  );
};

export default PositionBulkActionDialog;
