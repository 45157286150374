import React, { useState } from 'react';
import { Box, Button, IconButton, Menu, MenuList, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import { usePopover } from '../../hooks/use-popover';
import { DotsVertical as DotsVerticalIcon } from '../../assets/icons/dots-vertical';
import CachedTwoToneIcon from '@mui/icons-material/CachedTwoTone';
import CompareArrowsTwoToneIcon from '@mui/icons-material/CompareArrowsTwoTone';
import { Delete } from '@mui/icons-material';
import { styles } from './styles';
import { useDispatch } from 'react-redux';
import { deleteChangeRequestPolicy } from '../../store/slices/listCRPolicies';
import { useNavigate } from 'react-router-dom';
import WorkIcon from '@mui/icons-material/Work';

import PermissionChecker from '../PermissionChecker';

export const CRPoliciesTableMenu = ({ row, column, route }) => {
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const [openDialog, setOPenDialog] = useState(false);
  const [action, setAction] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDialogClose = () => {
    setOPenDialog(false);
  };

  const handleDelete = () => {
    dispatch(deleteChangeRequestPolicy({ id: row.id }));
    handleClose();
    setOPenDialog(false);
  };

  const actionItems = [
    {
      label: 'Modify Policy',
      type: 'modify_policy',
      icon: <EditIcon />,
      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_UPDATE_CHANGE_REQUEST_POLICY'],
    },
    {
      label: 'Delete',
      type: 'delete',
      icon: <Delete />,
      onClick: handleDelete,
      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_DELETE_CHANGE_REQUEST_POLICY'],
    },
    {
      label: 'View Mapped Positions',
      type: 'view_mapped_positions',
      icon: <WorkIcon />,
      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_VIEW_POSITIONS_MAPPED_TO_CHANGE_REQUEST_POLICY'],
    },
    {
      label: 'Update Mapped Positions',
      type: 'update_mapped_positions',
      icon: <CachedTwoToneIcon />,
      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_BULK_UPDATE_POSITION_CHANGE_REQUEST_POLICY_MAPPINGS'],
    },
  ];

  const handleMenuItemClick = (action) => {
    if (action.type === 'modify_policy') {
      navigate(`/changerequest/crpolicies/modify/${row.id}`);
      return;
    } else if (action.type === 'view_mapped_positions') {
      navigate(`/crpolicies/mappedpositions/view/${row.id}`);
      return;
    } else if (action.type === 'update_mapped_positions') {
      navigate(`/crpolicies/mappedpositions/update/${row.id}`);
      return;
    }

    setOPenDialog(true);

    setAction(() => {
      switch (action.type) {
        case 'delete':
          return {
            title: 'Delete Change Policy',
            confirmation: `Are you sure you want to delete this Change Policy?`,
            callback: action.callback,
            ...action,
          };
        default:
          return action;
      }
    });
  };

  const renderDialogContent = (action) => (
    <DialogContent sx={styles.tableMenuDialog}>
      <Box sx={styles.tableMenuDialog}>
        <DialogTitle>{action.title}</DialogTitle>
        {action.confirmation && <DialogContentText>{action.confirmation}</DialogContentText>}
      </Box>
      {action.children}
      <DialogActions>
        <Button size='large' onClick={handleDialogClose}>
          Cancel
        </Button>
        <Button size='large' variant='contained' onClick={action.callback}>
          Delete
        </Button>
      </DialogActions>
    </DialogContent>
  );

  const renderMenuActions = () =>
    actionItems.map((action, index) => (
      <PermissionChecker requiredPermissions={action.permissions} key={index} noAuthRedirect={false}>
        <MenuItem
          key={action.type}
          onClick={() =>
            handleMenuItemClick({
              label: action.label,
              type: action.type,
              callback: action.onClick,
            })
          }
        >
          <ListItemIcon>{action.icon}</ListItemIcon>
          <ListItemText variant='body2'>{action.label}</ListItemText>
        </MenuItem>
      </PermissionChecker>
    ));

  return (
    <>
      <IconButton
        onClick={handleOpen}
        ref={anchorRef}
        color='primary'
        disabled={row['publicationStatus'] === 'Published'}
      >
        <DotsVerticalIcon fontSize='small' />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList dense>{renderMenuActions()}</MenuList>
      </Menu>
      <Dialog open={openDialog} onClose={() => setOPenDialog(false)}>
        {renderDialogContent(action)}
      </Dialog>
    </>
  );
};
