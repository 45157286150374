import React, { useEffect, useState } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Done } from "@mui/icons-material";
import { utilStyles } from '../../../utils/styles'
import {
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  Typography
} from '@mui/material';
import { InputField } from "../../../components/InputField";
import { formDataApi } from "../../../axios";
import { useSnackbar } from "notistack";
import keyBy from "lodash/keyBy";

const ChangePassword = ({ user, fetchUserData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { authorityList = [], roleIDBasedMap = {} } = user || {};
  const [selectedRoles, setSelectedRoles] = useState([]);

  useEffect(() => {
    const selectedRoleValues = Object.keys(roleIDBasedMap).filter((item) => roleIDBasedMap[item] === true);
    setSelectedRoles(selectedRoleValues.map(Number));
  }, [user]);

  const formik = useFormik({
    initialValues: {
      confirmPassword: "",
      newPassword: "",
      submit: null
    },
    validationSchema: Yup.object().shape({
      confirmPassword: Yup.string().when("newPassword", { // this should match with input field name
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("newPassword")],
          "Password does not match"
        ),
      }),
      newPassword: Yup.string().min(8, "New Password must be at least 8 characters long.").matches(/(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).*$/, {message: "New Password must contain at least one digit, one uppercase letter, and one lowercase letter."}).required('No password provided.'),
    }),
    onSubmit: async (values, helpers) => {
      const bodyFormData = new FormData();
      const authorityListObject = keyBy(authorityList, 'id');

      const data = {
        'id': Number(user.secUserInstance.id),
        'newPasswd': values.confirmPassword,
        'newPasswd2': values.confirmPassword,
      }

      Object.keys(data).forEach(key => {
        if (data[key] !== '' || data[key]) {
          bodyFormData.append(key, data[key]);
        }
      })

      selectedRoles.forEach((selectedRole) => {
        bodyFormData.append(authorityListObject[selectedRole]['authority'], 'on');
      });

      try {
        const response = await formDataApi.post('changeMyPassword', bodyFormData);
        if (response?.data?.message) {
          fetchUserData()
          enqueueSnackbar(response?.data?.message, { variant: 'success' });
        }
      } catch (err) {
        enqueueSnackbar('Something went wrong! Passwords could not be updated', { variant: 'error' });
      }
      try {
        //enqueueSnackbar('Password updated', { variant: 'success' });
        helpers.resetForm();
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  return (
    <Card sx={utilStyles.mt(3)}>
      <CardContent>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            md={5}
            xs={12}
          >
            <Typography
              color="textPrimary"
              variant="h6"
            >
              Change password
            </Typography>
          </Grid>
          <Grid
            item
            md={7}
            xs={12}
          >
            <form onSubmit={formik.handleSubmit}>
              <div>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={6}
                  >
                    <InputField
                      error={Boolean(formik.touched.newPassword && formik.errors.newPassword)}
                      fullWidth
                      helperText={formik.touched.newPassword && formik.errors.newPassword}
                      label="New password"
                      name="newPassword"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.newPassword}
                      type="password"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                  >
                    <InputField
                      error={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
                      fullWidth
                      helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                      label="Confirm new password"
                      name="confirmPassword"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.confirmPassword}
                      type="password"
                    />
                  </Grid>
                  {formik.errors.submit && (
                    <Grid
                      item
                      xs={12}
                    >
                      <FormHelperText error>
                        {formik.errors.submit}
                      </FormHelperText>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                  >
                    <Button
                      color="primary"
                      size="large"
                      type="submit"
                      variant="contained"
                      startIcon={<Done />}
                    >
                      Save Password
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </form>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ChangePassword;
