import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Skeleton, Typography } from "@mui/material"
import {  useState } from "react"
import { formDataApi } from "../../axios";
import { styles } from './styles';
import { useParams } from "react-router-dom";
import { useRuleInputFormik } from "./formik";
import { useSnackbar } from "notistack";
import CircularProgress from '@mui/material/CircularProgress';
import DynamicField from "../../components/DynamicForm/Field";

export const AddRule = (props) => {
  const { data, handleRefresh, handleCancel } = props;
  const params = useParams();
  const [isSaving, setSaving] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { formik } = useRuleInputFormik(
    data?.attributes,
    null,
    (values) => handleSubmit(values)
  );

  const handleSubmit = () => {
    setOpenDialog(true)
  }

  const handleSave = async () => {
    if(isSaving) return;
    const bodyFormData = new FormData();
    bodyFormData.append('recruitmentPolicyId', params.id);
    bodyFormData.append('ruleDefiningIds', Object.keys(formik.values));
    bodyFormData.append('rulesValues', Object.values(formik.values));
    try {
      setSaving(true)
      const response = await formDataApi.post('/saveRulesForRecruitmentPolicy',bodyFormData);
      if (response?.data && response?.data?.status === "SUCCESS"){
        enqueueSnackbar(response?.data?.message, { variant: 'success' });
        handleRefresh()
      }else enqueueSnackbar(response?.data?.message, { variant: 'error' });
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setOpenDialog(false)
      setSaving(false)
    }
  }

  return <>
    <Box >
        <Grid>
            <Divider style={{marginBottom: "20px", marginTop: "30px"}}/>
            <Box mb={2}><Typography variant='h5' sx={styles.basicInformationText}>{data?.attributes?.length > 0 ? `Rule ${data?.rules?.length + 1}` : "Rule"}</Typography></Box>
            {data?.attributes?.length > 0 
                ? <Grid>
                    <Grid container spacing={3}>{data?.attributes?.map(item => 
                      <Grid item xs={4} key={item.id}>
                          <DynamicField formik={formik} field={item.definitions} fieldKey="id"/>
                          </Grid>
                      )}</Grid>
                    <Grid item sx={{ pt: '0 !important', display: 'flex' }} mt="32px">
                      <Box sx={{ flexGrow: 1 }} />
                      <Button sx={{marginRight: "10px"}} size='medium' variant='text' color='primary' onClick={handleCancel}>
                        Cancel
                      </Button>
                      <Button size='medium' variant='contained' color='primary' onClick={formik.handleSubmit}>
                        Create
                      </Button>
                    </Grid>
                  </Grid>
                : <Box py="20px" textAlign="center">No rule attributes found</Box>}
            </Grid>
    </Box>
    <Dialog open={openDialog} >
      <DialogContent sx={styles.tableMenuDialog}>
        <Box sx={styles.tableMenuDialog}>
          <DialogTitle>Save Recruitment Policy Rules</DialogTitle>
          <DialogContentText>Are you sure you want to save this Recruitment Policy?</DialogContentText>
        </Box>
        <DialogActions>
          <Button size='medium' variant='text' onClick={() => setOpenDialog(false)} disabled={isSaving}>
            Cancel
          </Button>
          <Button size='medium' variant='contained' onClick={handleSave} startIcon={isSaving ? <CircularProgress  color='inherit' size='1.2rem' /> : <></>}>
            Save
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  </>
}