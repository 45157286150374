import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { PositionStatusChip } from '../../components/Position/PositionStatusChip';
import { Status } from '../../components/Status';

import { useSelector } from 'react-redux';

const columns = [
  {
    fieldName: 'positionCode',
    name: 'c',
    label: 'POSITION CODE',
    type: 'number',
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row[column.fieldName]}
      </Typography>
    ),
  },
  {
    fieldName: 'title',
    name: 't',
    label: 'POSITION TITLE',
    type: 'string',
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row[column.fieldName]}
      </Typography>
    ),
  },
  {
    fieldName: 'status',
    name: 's',
    label: 'STATUS',
    type: 'string',
    formatValue: ({ row, column }) => <PositionStatusChip label={row[column.fieldName]} />,
  },
  {
    fieldName: 'publicationStatus',
    name: 'publicationStatus',
    label: 'PUBLICATION STATUS',
    type: 'string',
    formatValue: ({ row, column }) => (
      <Status color={row[column.fieldName] === 'Unpublished' ? '#EFA12D' : '#27AB6E'} label={row[column.fieldName]} />
    ),
  },
  {
    fieldName: 'changeRequestPolicy',
    name: 'changeRequestPolicy',
    label: 'CHANGE POLICY',
    type: 'string',
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row[column.fieldName]?.name}
      </Typography>
    ),
  },
  {
    fieldName: 'department',
    name: 'department',
    label: 'DEPARTMENT',
    type: 'string',
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row[column.fieldName]?.name}
      </Typography>
    ),
  },
  {
    fieldName: 'designation',
    name: 'designation',
    label: 'DESIGNATION',
    type: 'string',
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row[column.fieldName]?.name}
      </Typography>
    ),
  },
  {
    fieldName: 'location',
    name: 'location',
    label: 'LOCATION',
    type: 'string',
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row[column.fieldName]?.name}
      </Typography>
    ),
  },
];

export const useColumns = () => {
  const [columnState, setColumnState] = useState([]);
  const customAttributes = useSelector((state) => state.customAttributes.data);

  const getCustomColumnValue = (customObject, fieldLabel) => {
    let returnValue = '';
    if (customObject?.customFieldValues?.length) {
      customObject.customFieldValues.every((item) => {
        if (item['fieldLabel'] === fieldLabel) {
          returnValue = item['fieldValue'];
          return false;
        }
        return true;
      });

      return returnValue;
    }

    return '';
  };
  useEffect(() => {
    let finalColumns = [...columns];
    const customColumns = customAttributes.metaCustomFieldDefinitions?.map((item) => {
      return {
        fieldName: item.fieldLabel,
        label: item.fieldLabel,
        name: item.fieldLabel,
        type: item.fieldDataType,
        formatValue: ({ row, column }) => getCustomColumnValue(row['customObjectInstance'], column.label),
      };
    });
    if (customColumns) {
      finalColumns = [...finalColumns, ...customColumns];
    }
    setColumnState(finalColumns);
  }, [customAttributes]);
  return columnState;
};
