import React from 'react';
import Proptypes from 'prop-types';
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Pagination } from '../Pagination';
import { Scrollbar } from '../Scrollbar';
import TableShimmer from '../TableShimmer';
import { styles } from './styles';

const StickyTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.primary.primary,
    minWidth: '50px',
    right: 0,
    position: 'sticky',
    zIndex: theme.zIndex.appBar + 2,
  },
  body: {
    backgroundColor: theme.palette.background.default,
    minWidth: '50px',
    right: 0,
    position: 'sticky',
    zIndex: theme.zIndex.appBar + 1,
  },
}))(TableCell);

const CustomTable = (props) => {
  const {
    columns,
    isLoading,
    onPageChange,
    onPageSizeChange,
    onSelect,
    onSelectAll,
    onSortChange,
    page,
    rows,
    rowsCount,
    selectedRows,
    sort,
    sortBy,
    showPagignation,
    showRowSelector,
    keyColumn,
    onRowClick,
    route,
    action,
  } = props;
  const displayLoading = isLoading;
  return (
    <>
      <Box sx={styles.tableContainer}>
        <Scrollbar>
          <TableContainer sx={styles.tableContainer}>
            <Table sx={styles.table} size='small'>
              <TableHead>
                <TableRow>
                  {showRowSelector && (
                    <TableCell padding='checkbox'>
                      <Checkbox
                        color='secondary'
                        checked={rows.length > 0 && selectedRows.length === rows.length}
                        disabled={isLoading}
                        indeterminate={selectedRows.length > 0 && selectedRows.length < rows.length}
                        onChange={(event) => onSelectAll(event, keyColumn)}
                      />
                    </TableCell>
                  )}
                  {columns.map((column) => {
                    return !column.sticky ? (
                      <TableCell key={column.fieldName}>
                        {column?.disableSorting === true ? (
                          column.label
                        ) : (
                          <TableSortLabel
                            style={{ width: 'max-content' }}
                            active={sortBy === column.fieldName}
                            direction={sortBy === column.fieldName ? sort : 'asc'}
                            disabled={isLoading}
                            onClick={(event) => onSortChange(event, column.fieldName)}
                          >
                            {column.label}
                          </TableSortLabel>
                        )}
                      </TableCell>
                    ) : (
                      <StickyTableCell key={column.label}>{column.label}</StickyTableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              {!displayLoading ? (
                <TableBody>
                  {rows.map((row, rowIndex) => {
                    return (
                      <TableRow
                        key={rowIndex}
                        hover
                        selected={!!selectedRows.find((selectedCustomer) => selectedCustomer === row[keyColumn])}
                      >
                        {showRowSelector && (
                          <TableCell padding='checkbox' sx={styles.tableCell}>
                            <Checkbox
                              color='secondary'
                              checked={!!selectedRows.find((selectedCustomer) => selectedCustomer === row[keyColumn])}
                              onChange={(event) => onSelect(event, row[keyColumn])}
                            />
                          </TableCell>
                        )}
                        {columns.map((column, columnIndex) => {
                          return !column.sticky ? (
                            <TableCell
                              title={column.title?.({
                                row,
                                column})}
                              sx={{ cursor: onRowClick ? 'pointer' : 'unset', width: 'max-content', ...styles.tableCell }}
                              onClick={onRowClick ? () => {
                                onRowClick(row[keyColumn])
                              } : () => { }}
                              key={columnIndex}
                            >
                              {column.formatValue?.({
                                row,
                                column,
                                keyColumn,
                                route,
                                action,
                                rowIndex,
                              })}
                            </TableCell>
                          ) : (
                            <StickyTableCell key={columnIndex}>
                              {column.formatValue?.({
                                row,
                                column,
                                keyColumn,
                                route,
                                action,
                              })}
                            </StickyTableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableShimmer columns={columns.length + 1} />
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        {showPagignation && (
          <Pagination disabled={isLoading} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} page={page} rowsCount={rowsCount} />
        )}
      </Box>
    </>
  );
};

export default CustomTable;

CustomTable.defaultProps = {
  page: 1,
  rows: [],
  rowsCount: 0,
  selectedRows: [],
  sort: 'desc',
  sortBy: 'createdAt',
  showPagignation: true,
  showRowSelector: false,
};

CustomTable.propTypes = {
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSelect: Proptypes.func,
  onSelectAll: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  rows: Proptypes.array,
  rowsCount: Proptypes.number,
  selectedRows: Proptypes.array,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string,
};
