import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Close, Done } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router";
import { formDataApi } from "../../../axios";
import { updateCRStatus, getChangeRequests, resetSelectedCRs, cancelChangeRequest } from "../../../store/slices/listChangeRequests";
import { ChevronLeft as ChevronLeftIcon } from '../../../assets/icons/chevron-left'
import UpdateChangeRequests from "./updateRequestDetails";
import { styles } from "../ModifyChangeRequest/styles";
import { hasPermission } from "../../../utils/permission";
import { useSearchParams } from "react-router-dom";
import { styles as userStyles } from './styles'
import { useSnackbar } from "notistack";
import moment from "moment";
import minorCategoryIcon from '../../../assets/images/minorCategory.svg';
import majorCategoryIcon from '../../../assets/images/majorCategory.svg';
import { DateCalendar } from "../../../components/DateCalendar";
import { PositionStatusChip } from "../../../components/Position/PositionStatusChip";
import { StatusBackgroundColors } from "../columns";

export const ApproveRejectDialogContent = ({actionType, changeRequest, handleClose, handleConfirm}) => {
  const [modalType, setModalType] = useState(1);
  const [startDate, setStartDate] = useState();
  const [comment, setComment] = useState('');
  const currentUser = useSelector((state) => state.session.user);

  const {allowUpdatePositionStartDate, position} = useMemo(() => {
    const position = changeRequest?.position;
    const categoryType = changeRequest?.categoryType?.name;
    const majorApprovers = position?.changeRequestPolicy?.approvers?.filter(item => item?.categoryType?.name === "Major");
    const lastApprover = majorApprovers.length > 0 ? majorApprovers[majorApprovers.length - 1] : null;
    const allowUpdatePositionStartDate = categoryType === "Major" && lastApprover?.employeeId === parseInt(currentUser?.empDBID);
    return {allowUpdatePositionStartDate, position};
  }, [ changeRequest, currentUser ])

  if(!actionType) return <React.Fragment></React.Fragment>;

  const handleConfirmEvent = (status) => {
    handleConfirm({
      actType: status,
      ...startDate && { startDate },
      ...comment?.length > 0 && { comment },
    })
  }

  const handleCloseEvent = () => {
    setStartDate();
    setComment();
    setModalType(1);
    handleClose();
  }

  const getModalDetails = () => {
    if(actionType === "approve"){
      switch(modalType){
        case 1: 
        return {
          mainTitle: 'Approve change request',
          subTitle: 'Are you sure you want to approve the change request?',
          actions: <><Button variant='text' color='primary' onClick={handleCloseEvent}>
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={() => allowUpdatePositionStartDate ? setModalType(2) :  handleConfirmEvent("Approve")}
          >
            {allowUpdatePositionStartDate ? `Next` : `Approve`}
          </Button></>,
        }
        case 2: return {
          mainTitle: "Reset Start Date",
          subTitle: `Would you like to reset the start date for this position (${position?.positionCode})?`,
          mainContent: <></>,
          actions: <><Button variant='text' color='primary' onClick={() => handleConfirmEvent("Approve")}>
          Skip
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() => setModalType(3)}
        >
          Yes
        </Button></>,
        }
        case 3: return {
          mainTitle: "Update Position Start Date",
          subTitle: "",
          mainContent: <Box>
          <Box sx={userStyles.infoLine}></Box>
          <Typography variant={'body2'} mt="4px">{`Position Code: ${position?.positionCode}`}</Typography>
          <Typography variant={'body2'} mt="4px">{`Current Start Date: ${position?.startDate ? moment(position?.startDate).format('MMM DD YYYY'): '-'}`}</Typography>
          <Box sx={userStyles.infoLine}></Box>
          <Typography variant='subtitle1'>
            Select the updated date from calendar
          </Typography>
          <DateCalendar  
            value={startDate || position?.startDate}
            onChange={(_key, value) => setStartDate(value)}
            disableFuture={true}/>
          </Box>,
          actions: <>
          <Button variant='text' color='primary' onClick={handleCloseEvent}>
          Cancel
          </Button>
          <Button
            disabled={!startDate || (position?.startDate && moment(position?.startDate).format("YYYY-MM-DD")) === (startDate && moment(startDate).format("YYYY-MM-DD"))}
            variant='contained'
            color='primary'
            onClick={() => setModalType(4)}
          >
            Update
          </Button>
        </>,
        }
        case 4: return {
          mainTitle: "Approve Change Request and Confirm Position Start Date",
          subTitle: "Are you sure you want to Approve Change Request and update position details of",
          mainContent: <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box sx={userStyles.infoLine}></Box>
            <Typography variant={'body2'} mx="24px">{`Position Code: ${position?.positionCode}`}</Typography>
            <Typography variant={'body2'} mx="24px" mt="6px">{`Current Start Date: ${position?.startDate ? moment(position?.startDate).format('MMM DD YYYY'): '-'}`}</Typography>
            <Typography variant={'body2'} mx="24px" mt="6px" color={"#3F455A"}>{`Updated Start Date: ${startDate ? moment(startDate).format('MMM DD YYYY'): '-'}`}</Typography>
            <Box sx={userStyles.infoLine}></Box>
          </Grid>
        </Grid>,
          actions: <>
            <Button variant='text' color='primary' onClick={handleCloseEvent}>
            Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={() => handleConfirmEvent("Approve")}
            >
              Confirm
            </Button>
          </>,
        }
      }
    } else if(actionType === "reject") {
        return ({
          mainTitle: 'Reject Change Request',
          subTitle: `Are you sure you want to reject the change request?`,
          mainContent: <TextField
              fullWidth
              label="Reason"
              placeholder="Type here"
              size="small"
              variant="outlined"
              onChange={(e) => setComment(e.target.value)}
              multiline
              rows={5}
            />,
          actions: <><Button variant='text' color='primary' onClick={handleCloseEvent}>
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={() => handleConfirmEvent("Revert")}
          >
            Confirm
          </Button></>,
        })
    } else if(actionType === "cancel_policy") {
      return ({
        mainTitle: 'Cancel Change Request',
        subTitle: `Are you sure you want to cancel the change request?`,
        confirmation:  `Once cancelled, the change request would become unactionable, and no further actions can be taken on it.`,
        mainContent: <TextField
            fullWidth
            label="Reason"
            placeholder="Type here"
            size="small"
            variant="outlined"
            onChange={(e) => setComment(e.target.value)}
            multiline
            rows={5}
          />,
        actions: <><Button variant='text' color='primary' onClick={handleCloseEvent}>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={comment?.length <= 0}
          onClick={() => handleConfirmEvent("Cancel")}
        >
          Confirm
        </Button></>,
      })
    }
  }

  const  {mainTitle, subTitle, mainContent, actions, confirmation} = getModalDetails();

  return <React.Fragment>
    <DialogTitle disableTypography>
      <Typography variant='h4' align='center' mb="10px">
        {mainTitle}
      </Typography>
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
          {subTitle && <Typography variant='h6' mb={2}>{subTitle}</Typography>}
          {confirmation && <Box mb={2}>{confirmation}</Box>}
          {mainContent}
        </DialogContentText>
    </DialogContent>
    <DialogActions>
      {actions}
    </DialogActions>
  </React.Fragment>
}

const ViewChangeRequests = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [hasApproverRole, setHasApproverRole] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const [isApproverCR, setIsApproverCR] = useState(false)
  const currentUser = useSelector((state) => state.session.user);
  const [openDialog, setOpenDialog] = useState(false);
  const [actionType, setActionType] = useState();
  const [isRequestingUpdateCRStatus, setRequestingUpdateCRStatus] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasCompaDisplayPermisson = hasPermission(authorities, ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_COMPA_VIEW_CHANGE_REQUEST'])

  const handleMenuItemClick = (action) => {
    setOpenDialog(true);
    setActionType(action)
  };

  useEffect(() => {
    if (searchParams){
      if(searchParams.get("type"))
        searchParams.get("type") === "approver" && setIsApproverCR(true)
      else setSearchParams({type: "all"})
    }
  }, [searchParams])

  useEffect(() => {
    dispatch(getChangeRequests())
  }, []);

  const [changeRequest, setChangeRequest] = useState({ isLoading: true });

  useEffect(() => {
    if (searchParams && searchParams.get("type")) {
      setChangeRequest({ ...changeRequest, isLoading: true })
      const getCRDetails = async () => {
        const bodyFormData = new FormData()
        bodyFormData.append('id', params.id)
        const apiPath = isApproverCR || userHasCompaDisplayPermisson ? '/displayChangeRequestWithCompaFields' : '/displayChangeRequest'
        const response = await formDataApi.post(apiPath, bodyFormData);
        if (response.data)  setChangeRequest(response.data);
      };
      getCRDetails();
    }
  }, [userHasCompaDisplayPermisson, isApproverCR, searchParams]);

  useEffect(() => {
    const approvers = changeRequest?.changeRequest?.position?.changeRequestPolicy?.approvers;
    const currentCRCategory = changeRequest?.changeRequest?.categoryType?.name;
    if (currentUser?.empDBID && currentCRCategory && Array.isArray(approvers) && approvers.length > 0 ) {
      const currentCRCategoryApprover = approvers.find(item => (item?.categoryType?.name === currentCRCategory) && (parseInt(currentUser?.empDBID) === item?.employeeId))
      if(currentCRCategoryApprover)  setHasApproverRole(true)
    }
  }, [currentUser?.empDBID, changeRequest?.changeRequest?.position?.changeRequestPolicy?.approvers])

  const changeCRStatus = (payload) => {
    if(isRequestingUpdateCRStatus) return;
    setRequestingUpdateCRStatus(true);
    const body = new FormData();
    body.append('changeRequestId', params.id);
    if(payload.actType !== 'Cancel'){
      Object.keys(payload).forEach(key => body.append(key, payload[key]))
      dispatch(updateCRStatus({
        body
      })).then(res => {
        if(res?.payload?.status === "SUCCESS")
          enqueueSnackbar(res?.payload?.message, { variant: 'success' });
        else 
          enqueueSnackbar(res?.payload?.message, { variant: 'error' });
        navigate(-1)
        handleDialogClose()
        dispatch(resetSelectedCRs())
      }).catch((err) => {
        enqueueSnackbar("Failed to update change request.", { variant: "error" });
      }).finally(() => {
        setRequestingUpdateCRStatus(false);
      })
    }else{
      body.append('rejectionComment', payload?.comment);
      dispatch(cancelChangeRequest({
        body
      })).then(res => {
        if(res?.payload?.status === "SUCCESS")
          enqueueSnackbar(res?.payload?.message, { variant: 'success' });
        else 
          enqueueSnackbar(res?.payload?.message, { variant: 'error' });
        navigate(-1)
        handleDialogClose()
        dispatch(resetSelectedCRs())
      }).catch((err) => {
        enqueueSnackbar("Failed to cancel change request.", { variant: "error" });
      }).finally(() => {
        setRequestingUpdateCRStatus(false);
      })
    }
  }

  const handleDialogClose = () => {
    setOpenDialog(false);
    setActionType();
  };
  
  const positionStatus = useMemo(() => {
    const statusAttributes = changeRequest?.changeRequest?.attributes?.find(item => item.name === "status");
    if(statusAttributes){
      return statusAttributes?.currentValue;
    }
    return changeRequest?.changeRequest?.position?.status
  }, [ changeRequest?.changeRequest ])

  return (
    <>
      <Box sx={styles.growBox}>
        <Container maxWidth="lg" sx={styles.pageWrapper}>
          <Box id="modifyChangeRequestHeader" sx={{ pt: '12px !important', pb: '32px' }}>
            <Box sx={styles.flexCenterNone}>
              <Typography variant="h4">
                {"Change Request Details"}
              </Typography>
            </Box>
          </Box>
          <Card sx={{ ...styles.dataWrappper, mt: 0, pt: 0 }}>
            <CardContent>
              {(changeRequest.isLoading || !(searchParams && searchParams.get("type"))) ?
                <Box sx={{ py: 4 }} >
                  <Skeleton height={42} />
                  <Skeleton />
                  <Skeleton />
                </Box>
                :
                <>
                  <Grid container direction='column' spacing={2}>
                    <Grid item sx={{ display: 'flex', pb: 2 }}>
                      <Button startIcon={<ChevronLeftIcon />} variant='text' color='secondary' onClick={() => navigate(-1)}>
                        {"Back"}
                      </Button>
                      <Box sx={styles.growBox} />
                      {(hasApproverRole && isApproverCR) &&
                        <>
                          <Button
                            color="primary"
                            onClick={
                              () => handleMenuItemClick("reject")
                            }
                            sx={{ mr: 2 }}
                            startIcon={<Close fontSize="small" />}
                            variant="text"
                          >
                            Reject
                          </Button>
                          <Button
                            color="primary"
                            onClick={
                              () => handleMenuItemClick("approve")
                            }
                            startIcon={<Done fontSize="small" />}
                            variant="contained"
                          >
                            Approve
                          </Button>
                        </>}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                    <Grid item container spacing={2} justifyContent="center">
                      <Grid item xs={3} display={'grid'}>
                        <Typography sx={styles.text} color='textSecondary' variant='subtitle2'>
                          Position Code:
                        </Typography>
                        <Typography sx={styles.text} color='textPrimary' variant='body2'>
                          {changeRequest?.changeRequest?.position?.positionCode}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}  display={'grid'}>
                        <Typography sx={styles.text} color="textSecondary" variant="subtitle2">
                          Position Status:
                        </Typography>
                        <Box>{positionStatus ? <PositionStatusChip label={positionStatus} /> : '-'}</Box>
                      </Grid>
                      <Grid item xs={3}  display={'grid'}>
                        <Typography sx={styles.text} color="textSecondary" variant="subtitle2">
                          Change Policy Name:
                        </Typography>
                        <Typography sx={styles.text} color="textPrimary" variant="body2">
                          {changeRequest?.changeRequest?.position?.changeRequestPolicy?.name || '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}  display={'grid'}>
                        <Typography sx={styles.text} color="textSecondary" variant="subtitle2">
                          Category:
                        </Typography>
                        <Typography sx={styles.text} color="textPrimary" variant="body2">
                        <img src={changeRequest?.changeRequest?.categoryType?.name === "Minor" ? minorCategoryIcon : majorCategoryIcon} alt={changeRequest?.changeRequest?.categoryType?.name} style={{paddingRight: "6px"}} />
                        {changeRequest?.changeRequest?.categoryType?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2} justifyContent="center">
                      <Grid item xs={3}  display={'grid'}>
                        <Typography sx={styles.text} color="textSecondary" variant="subtitle2">
                          Change request Status:
                        </Typography>
                        <Box><Chip label={changeRequest?.changeRequest?.status} sx={{ backgroundColor: StatusBackgroundColors[changeRequest?.changeRequest?.status.toLowerCase()] }} /></Box>
                      </Grid>
                      <Grid item xs={3}  display={'grid'}>
                        <Typography sx={styles.text} color="textSecondary" variant="subtitle2">
                          Request Submitted By:
                        </Typography>
                        <Typography sx={styles.text} color="textPrimary" variant="body2">
                          {changeRequest?.changeRequest?.createdBy?.employee.fullName  ? changeRequest?.changeRequest?.createdBy?.employee.fullName : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}  display={'grid'}>
                        <Typography sx={styles.text} color="textSecondary" variant="subtitle2">
                          Request Submitted On:
                        </Typography>
                        <Typography sx={styles.text} color="textPrimary" variant="body2">
                          {changeRequest?.changeRequest?.createdOn  ? moment(changeRequest?.changeRequest?.createdOn).format('MMM DD YYYY') : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}  display={'grid'}/>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <UpdateChangeRequests
                      changeRequest={changeRequest}
                    />
                  </Grid>
                  <Grid item sx={{ pt: 2 }} xs={12}>
                    <TextField
                      fullWidth
                      label="Comment"
                      size="small"
                      variant="outlined"
                      value={changeRequest?.changeRequest?.comments}
                      disabled
                      multiline
                      rows={5}
                    />
                  </Grid>
                </>}
            </CardContent>
          </Card>
        </Container>
      </Box>
      <Dialog
        sx={userStyles.dialog}
        open={openDialog}
        onClose={handleDialogClose}
      >
        <ApproveRejectDialogContent 
          actionType={actionType} 
          changeRequest={changeRequest?.changeRequest}
          handleClose={handleDialogClose} 
          handleConfirm={changeCRStatus}
        />
      </Dialog>
    </>
  );
};

export default ViewChangeRequests;
