import { Box, Button, Grid, Card, CardContent, Container, Typography, Divider } from '@mui/material';
import { styles as userStyles } from '../Users/styles';
import { ChevronLeft as ChevronLeftIcon } from '../../assets/icons/chevron-left';
import { styles } from './styles';
import { useEffect, useState } from 'react';
import { TabContext, TabPanel } from '@mui/lab';
import UserDetailsTab from '../../components/UserDetailsTab';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { formDataApi } from '../../axios';

import { useSnackbar } from 'notistack';

const AddEditUser = (props) => {
  const [view, setView] = useState('user-details');
  const params = useParams();
  const [userDetails, setUserDetails] = useState({
    user: {},
    isLoading: false,
  });
  const location = useLocation();
  const isEditMode = location.pathname.includes('edit');
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchData = async () => {
      setUserDetails({ ...userDetails, isLoading: true });
      const bodyFormData = new FormData();
      if(params.companyId){
        bodyFormData.append('company', params.companyId);
      }
      isEditMode && bodyFormData.append('id', params.id);
      const url = isEditMode ? '/modifyUser' : '/createUser';
      try {
        const response = await formDataApi.post(url, bodyFormData);
        if (response?.data) {
          setUserDetails({ user: response.data, isLoading: false });
        }
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    };

    fetchData();
  }, [params.id]);

  return (
    <Box sx={userStyles.growBox}>
      <Container maxWidth='lg' sx={userStyles.pageWrapper}>
        <Grid container direction='column' spacing={2}>
          <Grid item sx={{ py: '32px !important' }}>
            <Typography variant='h4'>{isEditMode ? 'Edit User' : 'Add User'}</Typography>
          </Grid>
          <Grid item sx={{ pt: '0px !important' }}>
            <Card {...props}>
              <CardContent>
                <Grid container direction='column' spacing={2}>
                  <Grid item>
                    <Button
                      startIcon={<ChevronLeftIcon />}
                      variant='text'
                      color='secondary'
                      onClick={() => navigate(params.companyId ? `/company/${params.companyId}/users` : "/users")}
                    >
                      Back
                    </Button>
                  </Grid>
                </Grid>
                <Grid item>
                  <TabContext value={view}>
                    <TabPanel sx={styles.tabpanel} value={'user-details'}>
                      <UserDetailsTab isEditMode={isEditMode} userDetails={userDetails}/>
                    </TabPanel>
                  </TabContext>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AddEditUser;
