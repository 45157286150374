import React, { useState } from 'react';
import { AppBar, Box, Divider, Toolbar, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSettings } from '../../contexts/settings-context';
import { Logo } from '../Logo';
import { AccountPopover } from '../dashboard/account-popover';
import { styles } from './styles';
import { loginApi } from '../../axios';
import { logout } from '../../store/slices/session';

const organizations = [
  {
    id: '6039124832',
    name: 'ACME LTD.',
  },
  {
    id: '3828312374',
    name: 'Division LTD.',
  },
];

const PageNavbar = () => {
  const { i18n, t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const { settings, saveSettings } = useSettings();
  const [darkMode, setDarkMode] = useState(settings.theme === 'dark');
  const [rtlDirection, setRtlDirection] = useState(settings.direction === 'rtl');
  const [currentOrganization, setCurrentOrganization] = useState(organizations[0]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.session.user);
  const [loading, setLoading] = useState(false);

  const handleLogout = async () => {
    setLoading(true);
    const response = await loginApi.post('/logout');
    if (response.status === 200) {
      setLoading(false);
      dispatch(logout());
      localStorage.removeItem('token');
      navigate('/login');
    }
  };

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    saveSettings({
      ...settings,
      language,
    });
    toast.success(t('Language changed'));
  };

  const handleSwitchTheme = () => {
    saveSettings({
      ...settings,
      theme: settings.theme === 'light' ? 'dark' : 'light',
    });

    setDarkMode(settings.theme === 'light');
  };

  const handleSwitchDirection = () => {
    saveSettings({
      ...settings,
      direction: settings.direction === 'ltr' ? 'rtl' : 'ltr',
    });

    setRtlDirection(settings.direction === 'rtl');
  };

  const handleOrganizationChange = (organizationId) => {
    const newOrganization = organizations.find((organization) => organization.id === organizationId);

    if (!newOrganization) {
      return;
    }

    setCurrentOrganization(newOrganization);
  };

  return (
    <AppBar elevation={0} sx={styles.appBar}>
      <Toolbar disableGutters sx={styles.toolBar}>
        <Typography noWrap component='a' sx={styles.centerFlex} href='/dashboard'>
          <Logo emblemOnly variant='light' />
        </Typography>
        <Divider flexItem orientation='vertical' sx={styles.divider} />
        <Typography
          component='a'
          color='secondary.secondary'
          variant='buttonMedium'
          sx={{ display: 'flex', textDecoration: 'none' }}
          href=''
        >
          {currentUser?.company}
        </Typography>
        <Divider flexItem orientation='vertical' sx={styles.divider} />
        {/*
        <Query disabled={false} onChange={console.log} sx={styles.searchBar} value={''} />
  */}
        <Box sx={styles.flexGrow} />
        {/**
         *  <NotificationsPopover sx={utilStyles.mr(2)} />
         */}

        <AccountPopover
          currentOrganization={currentOrganization}
          darkMode={darkMode}
          onLanguageChange={handleLanguageChange}
          onOrganizationChange={handleOrganizationChange}
          onSwitchDirection={handleSwitchDirection}
          onSwitchTheme={handleSwitchTheme}
          organizations={organizations}
          rtlDirection={rtlDirection}
          onLogout={() => setOpenDialog(true)}
        />
      </Toolbar>
      <Dialog open={openDialog}>
            <DialogContent sx={{width: "400px"}}>
            <Box sx={styles.tableMenuDialog}>
                <DialogTitle>Confirm</DialogTitle>
                <DialogContentText>Do you want to logout?</DialogContentText>
            </Box>
            <DialogActions>
                <Button size='medium' variant='text' onClick={() => setOpenDialog(false)} disabled={loading}>
                No
                </Button>
                <Button size='medium' variant='contained' onClick={handleLogout}  startIcon={loading ? <CircularProgress  color='inherit' size='1.2rem' /> : <></>}>
                Yes
                </Button>
            </DialogActions>
            </DialogContent>
        </Dialog>
    </AppBar>
  );
};

export default PageNavbar;
