import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus } from '../../assets/icons/plus';
import { styles } from './styles';
import { columns } from './columns';
import CustomTable from '../../components/CustomTable';
import { DepartmentFilter } from '../../components/Department/DepartmentFilter';
import { api, formDataApi } from '../../axios';
import FormData from 'form-data';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateDepartments,
  updateSelectedDepartments,
  updateAllSelectedDepartments,
} from '../../store/slices/departments';
import PermissionChecker from '../../components/PermissionChecker';

const Departments = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [controller, setController] = useState({
    filters: [],
    page: 0,
    sort: 'desc',
    sortBy: 'id',
    view: 'all',
    searched: false,
    pageSize: 10
  });

  const { enqueueSnackbar } = useSnackbar();
  const { departments, selectedDepartments } = useSelector((state) => state.departments);

  const fetchData = useCallback(async (searched, filters, sort, sortBy, page, pageSize) => {
    setLoading(true);
    try {
      if (searched) {
        const query = new URLSearchParams({});
        if (filters.length) {
          // filters.forEach((filter) => query.append(filter.property, filter.value));
          query.append('c', filters[0].property);
          query.append('q', filters[0].value);
        }
        if (sortBy) {
          query.append('sort', sortBy);
          query.append('order', sort);
        }
        query.append('offset', page * pageSize);
        query.append('max', pageSize);
        const response = await api.post(`/searchDepartmentAction?${query.toString()}`);
        if (response.data) {
          dispatch(updateDepartments(response.data));
        }
      } else {
        const query = new URLSearchParams({});
        if (sortBy) {
          query.append('sort', sortBy);
          query.append('order', sort);
        }
        query.append('offset', page * pageSize);
        query.append('max', pageSize);
        const response = await api.post(`/departments?${query.toString()}`);
        if (response.data) {
          dispatch(updateDepartments(response.data));
        }
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (fetchData)
      fetchData(controller.searched, controller.filters, controller.sort, controller.sortBy, controller.page, controller.pageSize);
  }, [controller, fetchData]);

  const handleFiltersApply = (newFilters) => {
    const parsedFilters = newFilters.map((filter) => ({
      property: filter.property.name,
      value: filter.value,
      operator: filter.operator.value,
    }));

    setController({
      ...controller,
      page: 0,
      filters: parsedFilters,
      searched: true,
    });
  };
  const handleFiltersClear = () => {
    setController({
      ...controller,
      page: 0,
      filters: [],
      searched: false,
    });
  };
  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage - 1,
    });
  };

  const handlePageSizeChange = (newPageSize) => {
    setController({
      ...controller,
      page: 0,
      pageSize: newPageSize
    });
    
  }

  const handleSortChange = (event, property) => {
    const isAsc = controller.sortBy === property && controller.sort === 'asc';

    setController({
      ...controller,
      page: 0,
      sort: isAsc ? 'desc' : 'asc',
      sortBy: property,
    });
  };

  const handleRowClick = (row) => {
    navigate(`/settings/departments/${row}`);
  };

  const handleSelect = (event, rowId) => {
    dispatch(updateSelectedDepartments({ isChecked: event.target.checked, rowId }));
  };

  const handleSelectAll = (event) => {
    dispatch(updateAllSelectedDepartments({ isChecked: event.target.checked }));
  };

  const handleDeleteDepartment = async (id) => {
    const bodyFormData = new FormData();
    bodyFormData.append('id', id);
    try {
      const response = await formDataApi.post('/deleteDepartment', bodyFormData);
      if (response.data?.status === 'SUCCESS') {
        enqueueSnackbar('Department deleted successfuly', { variant: 'success' });
        fetchData(controller.searched, controller.filters, controller.sort, controller.sortBy, controller.page);
      } else throw new Error(response.data?.message)
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  return (
    <>
      <Box sx={styles.growBox}>
        <Container maxWidth='lg' sx={styles.pageWrapper}>
          <Grid container direction='column' spacing={2}>
            <Grid
              sx={{ py: '32px !important' }}
              item
              container
              spacing={1}
              justifyContent='flex-start'
              alignItems='center'
              py={4}
            >
              <Grid item>
                <Typography variant='h4'>Departments</Typography>
              </Grid>
              <Grid item xs></Grid>
              <PermissionChecker
                requiredPermissions={['ROLE_BASE_PERMISSION_INDEX_CREATE_DEPARTMENT']}
                noAuthRedirect={false}
              >
                <Grid item xs='auto'>
                  <Button
                    color='primary'
                    onClick={() => navigate('/settings/departments/add')}
                    size='medium'
                    startIcon={<Plus fontSize='small' />}
                    variant='contained'
                  >
                    Add Department
                  </Button>
                </Grid>
              </PermissionChecker>
            </Grid>
          </Grid>
          <Grid item>
            <Card>
              <CardContent>
                <DepartmentFilter
                  disabled={false}
                  onFiltersApply={handleFiltersApply}
                  onFiltersClear={handleFiltersClear}
                  filterProperties={columns}
                  filters={controller.filters}
                />
                <CustomTable
                  isLoading={loading}
                  onSelect={handleSelect}
                  onSelectAll={handleSelectAll}
                  onRowClick={handleRowClick}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                  onSortChange={handleSortChange}
                  page={controller.page + 1}
                  columns={columns}
                  rows={departments.data}
                  rowsCount={departments.count}
                  sort={controller.sort}
                  sortBy={controller.sortBy}
                  showRowSelector={false}
                  showPagignation
                  keyColumn='id'
                  action={handleDeleteDepartment}
                />
              </CardContent>
            </Card>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Departments;
