import React, {useState} from 'react';
import {Box, Button, IconButton, Menu, MenuList, MenuItem, ListItemIcon, ListItemText, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { usePopover } from '../../hooks/use-popover';
import { styles } from './styles';
import {formDataApi } from "../../axios";
import { DotsVertical as DotsVerticalIcon } from '../../assets/icons/dots-vertical';
import PermissionChecker from '../../components/PermissionChecker';

export const TableMenu = ({ row, column, route }) => {
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const [openDialog, setOpenDialog] = useState(false);
  const [resyncing, setResyncing] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const actionItems = [
    {
      label: 'Modify',
      type: 'modify',
      icon: <EditIcon />,
      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_PRE_CREATE_RULES_FOR_RECRUITMENT_POLICY'],
    },
    {
      label: 'Resync',
      type: 'resync',
      icon: <AutorenewIcon />,
      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_RESYNC_SINGLE_RECRUITMENT_POLICY'],
    }
  ];

  const handleMenuItemClick = async (action) => {
    if (action.type === 'modify') {
      navigate(`/recruitmentPolicies/${row.id}`);
      return;
    }if(action.type === 'resync'){
      setOpenDialog(true)
    }
  };

  const handleResync = async () => {
    if(resyncing) return;
    const bodyFormData = new FormData();
    bodyFormData.append('recruitmentPolicyId', row.id);
    try {
      setResyncing(true)
      const response = await formDataApi.post('/resyncSingleRecruitmentPolicy',bodyFormData);
      if (response?.data && response?.data?.status === "SUCCESS"){
        enqueueSnackbar(response?.data?.message, { variant: 'success' });
      }else enqueueSnackbar(response?.data?.message, { variant: 'error' });
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setOpenDialog(false)
      setResyncing(false)
    }
  }

  const renderMenuActions = () =>
    actionItems.map((action, index) => {
      if(row.statusOnHQ?.toLowerCase() !== "active" && (action.type === 'modify' || action.type === 'resync' )) return;
      return <PermissionChecker requiredPermissions={action.permissions} key={index} noAuthRedirect={false}>
        <MenuItem
          key={action.type}
          onClick={() =>
            handleMenuItemClick({
              label: action.label,
              type: action.type,
              callback: action.onClick,
            })
          }
        >
          <ListItemIcon>{action.icon}</ListItemIcon>
          <ListItemText variant='body2'>{action.label}</ListItemText>
        </MenuItem>
      </PermissionChecker>
});

  return (
    <>
      <IconButton
        onClick={handleOpen}
        ref={anchorRef}
        color='primary'
      >
        <DotsVerticalIcon fontSize='small' />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList dense>{renderMenuActions()}</MenuList>
      </Menu>
      <Dialog open={openDialog} >
            <DialogContent sx={styles.tableMenuDialog}>
            <Box sx={styles.tableMenuDialog}>
                <DialogTitle>Resync Recruitment Policy</DialogTitle>
                <DialogContentText>Are you sure you want to resync this Recruitment Policy with Positions?</DialogContentText>
            </Box>
            <DialogActions>
                <Button size='medium' variant='text' onClick={() => setOpenDialog(false)} disabled={resyncing}>
                No
                </Button>
                <Button size='medium' variant='contained' onClick={handleResync} startIcon={resyncing ? <CircularProgress  color='inherit' size='1.2rem' /> : <></>}>
                Yes
                </Button>
            </DialogActions>
            </DialogContent>
        </Dialog>
    </>
  );
};
