/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PageLayout from '../components/PageLayout';
import Layout from '../pages/main/components/Layout';

import { Helmet } from 'react-helmet-async';
import { usePageTitles } from '../hooks/use-page-title';

const withLayout = (Component) => (props) => {
  const pageTitle = usePageTitles();
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PageLayout menu={<Layout />}>
        <Component {...props} />
      </PageLayout>
    </>
  );
};

export default withLayout;
