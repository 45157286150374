import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { MenuList, MenuItem, ListItemText, ListItemIcon } from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import PermissionChecker from '../PermissionChecker';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import { useSelector } from 'react-redux';

const actions = [
  {
    label: 'Publish',
    type: 'Published',
    icon: <PublicIcon />,
    permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_PUBLISH_POSITION'],
  },
  {
    label: 'Approve',
    type: 'Open',
    icon: <CheckIcon />,
    permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_BULK_UPDATE_POSITION_STATUS'],
  },
  {
    label: 'Modify',
    type: 'modify',
    icon: <EditIcon />,
    permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_UPDATE_POSITION'],
  },
  {
    label: 'Reject',
    type: 'Cancelled',
    icon: <CloseIcon />,
    permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_BULK_UPDATE_POSITION_STATUS'],
  },
  {
    label: 'Map Change Policy',
    type: 'map-cr-policy',
    icon: <InsertDriveFileIcon />,
    permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_MAP_POSITION_TO_CHANGE_REQUEST_POLICY'],
  },
  {
    label: 'Resync',
    type: 'resync',
    icon: <AutorenewIcon />,
    permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_RESYNC_POSITION'],
  },
  {
    label: 'Create Change Request',
    type: 'create-cr',
    icon: <ChangeCircleIcon />,
    permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_CREATE_CHANGE_REQUEST'],
  },
  {
    label: 'Update Position Start Date',
    type: 'update-start-date',
    icon: <EditCalendarIcon />,
    permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_UPDATE_POSITION_START_DATE'],
  },
  {
    label: 'Change Status',
    type: 'change-status',
    icon: <PublishedWithChangesIcon />,
    permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_UPDATE_POSITION_STATUS'],
  },
];

export const PositionMenuList = ({ row, handleClose, setActionType, setOpenPositionDialog, setBulkActionsDialogProperties }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.session);

  const handleMenuItemClick = (action) => {
    if (action.type === 'modify') {
      navigate(`/positions/modify/${row.id}`);
      return;
    }

    if (action.type === 'create-cr') {
      navigate(`/changeRequests/create/${row.id}`);
      return;
    }
    handleClose();
    setActionType(action.type);
    setOpenPositionDialog(true);
    let title = '';
    let confirmation = '';
    let customModal = false;

    switch (action.type) {
      case 'Published':
        title = 'Publish Positions';
        confirmation = `Are you sure you want to publish position?`;
        break;
      case 'Open':
        title = 'Approve Positions';
        confirmation = `Are you sure you want to Approve position?`;
        break;
      case 'Cancelled':
        title = 'Reject Positions';
        confirmation = `Are you sure you want to reject position?`;
        break;
      case 'modify':
        title = 'Modify Positions';
        confirmation = `Are you sure you want to modify position?`;
        break;
      case 'map-cr-policy':
        title = 'Map Change Policy';
        confirmation = ``;
        break;
      case 'resync':
        title = 'Resync';
        confirmation = `Are you sure you want to resync this position with Canvas?`;
        break;
      case 'change_request':
        title = 'Create Change Request';
        confirmation = ``;
        break;
      case 'update-start-date':
        title = 'Update Position Start Date';
        confirmation = ``;
        customModal= true;
        // dialogConfirmationMessage= 'Confirm Position Start Date'
        break;
      case 'change-status':
        title = 'Change Position Status';
        confirmation = ``;
        customModal= true;
        break;
      default:
        return;
    }
    setBulkActionsDialogProperties({title,confirmation,customModal})
  };

  const actionComponent = {};
  
  actions.forEach((action) => {
    if (
      row['status'] === 'Draft' &&
      row['publicationStatus'] === 'Unpublished' &&
      (action.type === 'Open' ||
        action.type === 'modify' ||
        action.type === 'Cancelled')
    ) {
      actionComponent[action.type] = 
        <PermissionChecker requiredPermissions={action.permissions} noAuthRedirect={false} key={action.type}>
          <MenuItem
            onClick={() =>
              handleMenuItemClick({
                label: action.label,
                type: action.type,
              })
            }
          >
            <ListItemIcon>{action.icon}</ListItemIcon>
            <ListItemText variant='body2'>{action.label}</ListItemText>
          </MenuItem>
        </PermissionChecker>
    }
    if (
      (row['status'] !== 'Draft') &&
      (action.type === 'resync')
    ) {
      actionComponent[action.type] = 
        <PermissionChecker requiredPermissions={action.permissions} noAuthRedirect={false} key={action.type}>
          <MenuItem
            key={action.type}
            onClick={() =>
              handleMenuItemClick({
                label: action.label,
                type: action.type,
              })
            }
          >
            <ListItemIcon>{action.icon}</ListItemIcon>
            <ListItemText variant='body2'>{action.label}</ListItemText>
          </MenuItem>
        </PermissionChecker>
    }
    if (
      (row['status'] !== 'Filled') &&
      (action.type === 'map-cr-policy')
    ) {
      actionComponent[action.type] = 
        <PermissionChecker requiredPermissions={action.permissions} noAuthRedirect={false} key={action.type}>
          <MenuItem
            key={action.type}
            onClick={() =>
              handleMenuItemClick({
                label: action.label,
                type: action.type,
              })
            }
          >
            <ListItemIcon>{action.icon}</ListItemIcon>
            <ListItemText variant='body2'>{action.label}</ListItemText>
          </MenuItem>
        </PermissionChecker>
    }
    if (action.type === 'create-cr' && row['changeRequestPolicy'] && (['Draft', 'Filled'].indexOf(row['status']) < 0)) {
        actionComponent[action.type] = 
        <PermissionChecker requiredPermissions={action.permissions} noAuthRedirect={false} key={action.type}>
          <MenuItem
            key={action.type}
            onClick={() =>
              handleMenuItemClick({
                label: action.label,
                type: action.type,
              })
            }
          >
            <ListItemIcon>{action.icon}</ListItemIcon>
            <ListItemText variant='body2'>{action.label}</ListItemText>
          </MenuItem>
        </PermissionChecker>
    }
    if (action.type === 'change-status' && 
      ['Open Pending Intake','Open Active Sourcing','On Hold', 'Cancelled'].indexOf(row['status']) >= 0 
    ) {
      actionComponent[action.type] = 
        <PermissionChecker requiredPermissions={action.permissions} noAuthRedirect={false} key={action.type}>
          <MenuItem
            key={action.type}
            onClick={() =>
              handleMenuItemClick({
                label: action.label,
                type: action.type,
              })
            }
          >
            <ListItemIcon>{action.icon}</ListItemIcon>
            <ListItemText variant='body2'>{action.label}</ListItemText>
          </MenuItem>
        </PermissionChecker>
    }
    if (
      action.type === 'change-status' && 
      (['Draft', 'On Hold - HM Delay', 'Offer Released', 'Offer Accepted', 'Offer Negotiation'].indexOf(row['status']) >= 0 && !user.isIntegratedWithCanvas)
    ) {
      actionComponent[action.type] = 
        <PermissionChecker requiredPermissions={action.permissions} noAuthRedirect={false} key={action.type}>
          <MenuItem
            key={action.type}
            onClick={() =>
              handleMenuItemClick({
                label: action.label,
                type: action.type,
              })
            }
          >
            <ListItemIcon>{action.icon}</ListItemIcon>
            <ListItemText variant='body2'>{action.label}</ListItemText>
          </MenuItem>
        </PermissionChecker>
    }
    if (action.type === 'update-start-date' && 
      ['Open Pending Intake','Open Active Sourcing','On Hold'].indexOf(row['status']) >= 0
    ) {
      actionComponent[action.type] = 
        <PermissionChecker requiredPermissions={action.permissions} noAuthRedirect={false} key={action.type}>
           <MenuItem
            key={action.type}
            onClick={() =>
              handleMenuItemClick({
                label: action.label,
                type: action.type,
              })
            }
          >
            <ListItemIcon>{action.icon}</ListItemIcon>
            <ListItemText variant='body2'>{action.label}</ListItemText>
          </MenuItem>
        </PermissionChecker>
    }
  })

  useEffect(() => {
    Object.keys(actionComponent).length <= 0 && setActionType(-1)
  },[actionComponent])


  return (
        <MenuList dense>
          {Object.keys(actionComponent).map(action => actionComponent[action])}
        </MenuList>
  );
};
