import axios from 'axios';

const baseURL = `${process.env.REACT_APP_BASE_URL}`; //'http://135.181.205.50:8080'; // Also change in  forgot password and reset password pages

const api = axios.create({
  baseURL: `${baseURL}/restApi`,
  headers: {
    'Content-Type': 'application/json',
    'appType': 'hq-ui',
  },
});
api.interceptors.request.use((req) => {
  const token = localStorage.getItem('token');
  req.headers.Authorization = `Bearer ${token}`;
  return req;
});
api.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    return err;
  }
);

const loginApi = axios.create({
  baseURL: `${baseURL}/api`,
  headers: {
    'Content-Type': 'application/json',
    'appType': 'hq-ui',
  },
});
loginApi.interceptors.request.use((req) => {
  const token = localStorage.getItem('token');
  if (token) req.headers.Authorization = `Bearer ${token}`;
  return req;
});

const formDataApi = axios.create({
  baseURL: `${baseURL}/restApi`,
  headers: {
    'Content-Type': 'multipart/form-data',
    'appType': 'hq-ui',
  },
});
formDataApi.interceptors.request.use((req) => {
  const token = localStorage.getItem('token');
  if (token) req.headers.Authorization = `Bearer ${token}`;
  return req;
});

const downloadFileApi = axios.create({
  baseURL: `${baseURL}/restApi`,
  responseType: 'blob',
  headers: {
    Accept: 'application/octet-stream',
    'appType': 'hq-ui',
  },
});
downloadFileApi.interceptors.request.use((req) => {
  const token = localStorage.getItem('token');
  if (token) req.headers.Authorization = `Bearer ${token}`;
  return req;
});

export { loginApi, api, formDataApi, downloadFileApi };
