import React, { useCallback, useEffect, useState } from 'react';
import { Box, Card, CardContent, Container, FormControlLabel, FormGroup, Checkbox, Grid, Skeleton, Typography, Divider, Dialog, DialogContent, DialogTitle, DialogContentText, Button, DialogActions } from '@mui/material';
import { api, formDataApi } from '../../axios';
import { styles } from '../../components/Position/styles';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import PermissionChecker from '../../components/PermissionChecker';
import { useSelector } from 'react-redux';
import { hasPermission } from '../../utils/permission';
import AuditLog from '../../components/AuditLog';

const RecruitmentPolicyAttributes = () => {
    const [attributes, setAttributes] = useState([]);
    const [selectedAttributeIds, setSelectedAttributesIds] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [isSaving, setSaving] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [openDialog, setOpenDialog] = useState(false);
    const [isTouched, setTouched] = useState(false);

    const authorities = useSelector((state) => state.session.user.authorities);
    const userHasUpdatePermission = hasPermission(authorities, [
      'ROLE_BASE_PERMISSION_RECRUIT_INDEX_SAVE_RULE_SETTINGS_FOR_RECRUITMENT_POLICY',
    ]);

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await api.post(`/preCreateRuleSettingsForRecruitmentPolicy`);
            if (response.data) {
                setData(response.data)
                setAttributes([...response.data.basicAttributes || [], ...response.data.requiredCustomAttributes || []])
                setSelectedAttributesIds(response.data.ruleAttributeIds ? JSON.parse(response.data.ruleAttributeIds) : [])
            }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }, []);
    

    useEffect(() => {
        fetchData();
    },[])

    const handleChange = (id, isChecked) => {
      if(!isTouched) setTouched(true);
      let attributeIds = [...selectedAttributeIds];
      if(!isChecked) attributeIds.splice(attributeIds.indexOf(id), 1);
      else attributeIds = [...attributeIds, id]
      setSelectedAttributesIds(attributeIds)
    }

    const handleSubmit = () => {
      setOpenDialog(true)
    }

    const handleSave = async() => {
      if(isSaving) return;
      const bodyFormData = new FormData();
      bodyFormData.append('ruleAttributeIds', selectedAttributeIds);
      try {
        setSaving(true)
        const response = await formDataApi.post('/saveRuleSettingForRecruitmentPolicy',bodyFormData);
        if (response?.data && response?.data?.status === "SUCCESS"){
          enqueueSnackbar(response?.data?.message, { variant: 'success' });
          fetchData()
        }else enqueueSnackbar(response?.data?.message, { variant: 'error' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      } finally{
        setTouched(false);
        setSaving(false)
        setOpenDialog(false)
      }
    }

    return <Box sx={styles.growBox}>
    <Container maxWidth='lg' sx={styles.pageWrapper}>
      <Grid container direction='column' spacing={2}>
        <Grid
          sx={{ py: '32px !important' }}
          item
          container
          spacing={1}
          justifyContent='flex-start'
          alignItems='center'
          py={4}
        >
          <Grid item>
            <Typography variant='h4'>Recruitment Policy Attributes</Typography>
          </Grid>
          <Grid item xs></Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Card>
          <CardContent>
            <Box pb={2}><Typography variant='h5'>Rule Setting</Typography></Box>
            <Box><Typography variant='h6'>Select Attributes</Typography></Box>
            <Divider style={{marginBottom: "10px", marginTop: "15px"}}/>
            <Box>
            {isLoading ? (
              <Box pt="10px">
                <Skeleton height={42} />
                <Skeleton />
                <Skeleton />
              </Box>
            ) : (
              <Box>
                {attributes.length === 0 
                  ? <Box pt="20px" textAlign="center">No attributes found</Box>
                  : <Box pt="20px" pl="50px">
                      <FormGroup> 
                        {attributes.map(item => 
                          <FormControlLabel key={item.id}
                            disabled={!userHasUpdatePermission}
                            control={
                              <Checkbox checked={selectedAttributeIds?.includes(item.id)}
                              onChange={(e) => handleChange(item.id, e.target.checked)} name={item.id} />
                            }
                            label={item.attributeTitle}
                          />)}
                      </FormGroup>
                  </Box>
                }
              </Box>)}
            </Box>
            <Divider style={{marginBottom: "25px", marginTop: "25px"}}/>
            <PermissionChecker
                  requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_SAVE_RULE_SETTINGS_FOR_RECRUITMENT_POLICY']}
                  noAuthRedirect={false}
                >
              <Box item sx={{ pt: '0 !important', display: 'flex' }}>
                  <Box sx={{ flexGrow: 1 }} />
                  <LoadingButton
                    size='medium'
                    sx={{ ml: 2 }}
                    variant='contained'
                    color='primary'
                    onClick={handleSubmit}
                    disabled={!isTouched}
                  >
                    Update
                  </LoadingButton>
              </Box>
            </PermissionChecker>
            <Box mt={3} mx={1}>
              <AuditLog data={data?.commentsMap?.commentsMap || []} />
            </Box>
          </CardContent>
        </Card>
    </Grid>
    </Container>
    <Dialog open={openDialog} >
      <DialogContent sx={styles.tableMenuDialog}>
        <Box sx={styles.tableMenuDialog}>
          <DialogTitle>Save Rule Settings</DialogTitle>
          <DialogContentText>
            <Typography  variant='h5'><Box pb={2}>Important!!</Box></Typography>
            <Box pb={2}>Updating rule settings can result in making some policy rules non-functional. Please ensure you modify rule settings only if it is highly important. If you proceed, kindly check and validate all recruitment policies and rules to ensure smooth operations.</Box>
            <Box>Do you want to continue updating rule settings?</Box>
          </DialogContentText>
        </Box>
        <DialogActions>
          <Button size='medium' variant='text' onClick={() => setOpenDialog(false)} disabled={isSaving}>
            No
          </Button>
          <Button size='medium' variant='contained' onClick={handleSave} startIcon={isSaving ? <CircularProgress  color='inherit' size='1.2rem' /> : <></>}>
            Yes
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
    </Box>
}
export default RecruitmentPolicyAttributes;