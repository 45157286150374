import { createSlice } from '@reduxjs/toolkit';

const bulkUploadPositions = createSlice({
  name: 'bulkUploadPositions',
  initialState: {
    selectedPositions: [],
    exceptionList: [],
    positions: [],
    error: null,
  },
  reducers: {
    updatePositions(state, action) {
      const { positions = [], exceptionList = [] } = action.payload;
      state.positions = positions;
      state.exceptionList = exceptionList;
    },
    updateSelectedPositions(state, action) {
      const { isChecked, rowId } = action.payload;

      if (isChecked) {
        state.selectedPositions.push(rowId);
        return;
      }
      state.selectedPositions = state.selectedPositions.filter((selectedRow) => selectedRow !== rowId);
    },
    updateAllSelectedPositions(state, action) {
      const { isChecked } = action.payload;
      state.selectedPositions = [];
      if (isChecked) {
        state.positions.data.map((row) => state.selectedPositions.push(row['id']));
        return;
      }
      state.selectedPositions = [];
    },
    setBulkUpdatePositionIds(state, action) {
      state.bulkUpdatePositions = action.payload;
    },
  },
});

export const { updatePositions, updateSelectedPositions, updateAllSelectedPositions } = bulkUploadPositions.actions;

export default bulkUploadPositions.reducer;
