export const styles = {
  tableContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  table: {
    minWidth: 800,
  },
  avatar: {
    width: 64,
    height: 64,
  },
  titleWrapper: {
    alignItems: "center",
    display: "flex",
  },
  link: {
    display: "block",
  },
  resourceUnavailable: {
    flexGrow: 1,
    m: 2,
  },
  dialogTitle: {
    textAlign: 'center'
  },
  buttonsWrapper: {
    justifyContent: "center"
  },

  // Search filter related search
  searchFilterContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    py: 1.5,
  },
  actionMenu: {
    mr: 2,
    order: {
      sm: 1,
      xs: 2,
    },
  },
  searchInput: {
    order: {
      sm: 2,
      xs: 1,
    },
    width: "355px",
  },
  exportButton: {
    order: 3,
    color: (theme) => theme.palette.text.secondary,
  },
  filterButton: {
    order: 4,
  },
};
