import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Container, Grid, Typography, Skeleton } from '@mui/material';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { formDataApi } from '../../../axios';
import FormData from 'form-data';
import { useSnackbar } from 'notistack';
import { styles } from '../styles';
import { ChevronLeft as ChevronLeftIcon } from '../../../assets/icons/chevron-left';
import { InputField } from '../../../components/InputField';
import OrdersDnd from '../../../components/dashboard/order/orders-dnd';
import { LoadingButton } from '@mui/lab';

const AddEditRoles = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [targetKeys, setTargetKeys] = useState([]);

  const [preCreateUpdateDetails, setPreCreateUpdateDetails] = useState([]);
  const [isPreCreationLoading, setIsPrecreationLoading] = useState(false);
  const [columns, setColumns] = useState(null);
  const [name, setName] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [grantedPermissions, setGrantedPermissions] = useState([]);
  const [loading, setLoading] = useState(false);

  const isEditMode = location.pathname.includes('edit');

  useEffect(() => {
    const fetchPreCreateUpdateData = async () => {
      setIsPrecreationLoading(true);
      const bodyFormData = new FormData();
      isEditMode && bodyFormData.append('id', id);
      const url = isEditMode ? '/modifyRole' : '/createRole';
      try {
        const response = await formDataApi.post(url, bodyFormData);
        if (response?.data) {
          const { permissionsList, grantedPermissions = [], secRoleInstance = {} } = response.data;
          const availableRoles = permissionsList.map(({ id, description, name }) => ({
            id,
            key: name,
            status: 'available',
            title: description,
            isSelected: false,
            value: name,
          }));
          setName(secRoleInstance?.displayName || "");
          setGrantedPermissions(grantedPermissions);
          setPreCreateUpdateDetails(availableRoles);
          setIsPrecreationLoading(false);
        }
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
        setIsPrecreationLoading(false);
      }
    };

    fetchPreCreateUpdateData();
  }, [id]);

  useEffect(() => {
    if (isEditMode && grantedPermissions?.length && preCreateUpdateDetails?.length) {
      const grantedIds = grantedPermissions.map(
        permission =>
          preCreateUpdateDetails.find(element => element.key === permission)?.key
      )
      setTargetKeys(grantedIds);
    }
  }, [isEditMode, grantedPermissions, preCreateUpdateDetails])

  const createRole = async () => {
    setLoading(true);
    const bodyFormData = new FormData();
    const grantedValues = targetKeys.join(',');
    bodyFormData.append('displayName', name);
    bodyFormData.append('grantedPermissions', grantedValues);
    try {
      const response = await formDataApi.post('/saveRole', bodyFormData);
      if (response.data?.status !== 'ERROR') {
        enqueueSnackbar('Role created successfuly', { variant: 'success' });
        navigate('/settings/roles');
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'success' });
    } finally {
      setLoading(false);
    }
  };
  const modifyRoleAction = async () => {
    setLoading(true);
    const bodyFormData = new FormData();
    const grantedValues = targetKeys.join(',');
    bodyFormData.append('displayName', name);
    bodyFormData.append('grantedPermissions', grantedValues);
    bodyFormData.append('id', id);
    try {
      const response = await formDataApi.post('/modifyRoleAction', bodyFormData);
      if (response.data?.status !== 'ERROR') {
        enqueueSnackbar('Role updated successfuly', { variant: 'success' });
        navigate('/settings/roles');
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={styles.growBox}>
      <Container maxWidth='lg' sx={styles.pageWrapper}>
        <Grid container direction='column' spacing={2}>
          <Grid item container justifyContent='space-between' sx={{ py: '32px !important' }}>
            <Grid item>
              <Typography variant='h4'>Roles</Typography>
            </Grid>
            <Grid item xs></Grid>
          </Grid>
          <Grid item sx={{ pt: '0 !important' }}>
            <Card>
              <CardContent>
                <Grid container direction='column' spacing={2}>
                  <Grid item>
                    <Button
                      startIcon={<ChevronLeftIcon />}
                      variant='text'
                      color='secondary'
                      onClick={() => navigate('/settings/roles')}
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item>
                    <Typography variant='h4'>{!isEditMode ? 'Add New Role' : 'Modify Role'}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <InputField
                      label='Role Name'
                      name='name'
                      onChange={(event) => setName(event.target.value)}
                      error={name?.length <= 0}
                      helperText={name?.length <= 0 && "Role Name is required"}
                      value={name}
                    />
                  </Grid>
                  <Grid item>
                    {isPreCreationLoading ? (
                      <Box>
                        <Skeleton height={42} />
                        <Skeleton />
                        <Skeleton />
                      </Box>
                    ) : (
                      <OrdersDnd
                        allorders={preCreateUpdateDetails}
                        columns={columns}
                        setColumns={setColumns}
                        grantedPermissions={grantedPermissions}
                        targetKeys={targetKeys}
                        setTargetKeys={setTargetKeys}
                      />
                    )}
                  </Grid>
                  <Grid item sx={{ pt: '0 !important', display: 'flex' }}>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button size='large' variant='text' color='primary' onClick={() => { }}>
                      Cancel
                    </Button>
                    <LoadingButton
                      loading={loading}
                      size='medium'
                      sx={{ ml: 2 }}
                      variant='contained'
                      color='primary'
                      disabled={name?.length <= 0}
                      onClick={!isEditMode ? createRole : modifyRoleAction}
                    >
                      {isEditMode ? 'Update' : 'Create'}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AddEditRoles;
