export const styles = {
  label: {
    mb: 0,
  },
  value: {
    mb: 2.0,
    textTransform: 'capitalize',
  },
  dividerWrapper: {
    pt: 2,
  }
};
