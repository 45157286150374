export const styles = {
  tableContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  table: {
    minWidth: 800
  },
  titleWrapper: {
    alignItems: 'center',
    display: 'flex'
  },
  link: {
    display: 'block'
  },
  resourceUnavailable: {
    flexGrow: 1,
    m: 2
  },
  colPosition: {
    position: 'fixed'
  },
  searchInput: {
    order: {
      sm: 2,
      xs: 1
    }
  },
  filterSpace: {
    flexGrow: 1,
    order: 2
  },
  selectBox: {
    m: 1,
    width: '425px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  selectInput: {
    width: '100px',
    height: '40px',
    borderColor: '#E1E3EA',
    color: '#7C85A2'
  },
  actionMenu: (selectedPositions) => ({
    mr: 2,
    display: selectedPositions.length > 0 ? 'flex' : 'none',
    order: {
      sm: 1,
      xs: 2
    }
  }),
  searchFilter: {
    p: 1,
    width: '191px'
  },
  filterButtons: {
    width: '200px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    pr: 2
  },
  filterMenu: {
    m: 1,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    border: 'none',
    boxShadow: 'none'
  },
  title: {
    px: {
      sm: 3
    },
    pt: 4
  },
  filterButton: {
    order: 4
  },
  dialog: {
    '& .MuiPaper-root': {
      padding: "0 50px !important"
    },
    '& .MuiDialogTitle-root': {
      textAlign: 'center',
      paddingBottom: "0px !important",
    },
    '& .MuiDialogContent-root': {
      paddingTop: "8px !important",
    },
    '& .MuiDialogActions-root': {
      paddingTop: "12px !important"
    }
  },
}
