import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Plus as PlusIcon } from '../../assets/icons/plus';
import { styles } from './styles';
import CustomTable from '../../components/CustomTable';
import { api, formDataApi } from '../../axios';
import { columns } from './columns';
import FormData from 'form-data';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { updateCompanies, updateSelectedCompanies, updateAllSelectedCompanies } from '../../store/slices/companies';
import PermissionChecker from '../../components/PermissionChecker';

const Company = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [controller, setController] = useState({
    filters: [],
    page: 0,
    query: '',
    sort: 'desc',
    sortBy: 'id',
    view: 'all',
    pageSize: 10
  });

  const { enqueueSnackbar } = useSnackbar();
  const { companies, selectedCompanies } = useSelector((state) => state.companies);

  const fetchData = useCallback(async (filters, sort, sortBy, page, pageSize) => {
    setLoading(true);
    try {
      const query = new URLSearchParams({});
      if (sortBy) {
        query.append('sort', sortBy);
        query.append('order', sort);
      }
      query.append('offset', page * pageSize);
      query.append('max', pageSize);
      const response = await api.post(`/listCompanies?${query.toString()}`);
      if (response.data) {
        dispatch(updateCompanies(response.data));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (fetchData)
      fetchData(controller.filters, controller.sort, controller.sortBy, controller.page, controller.pageSize);
  }, [controller, fetchData]);

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage - 1,
    });
  };

  const handlePageSizeChange = (newPageSize) => {
    setController({
      ...controller,
      page: 0,
      pageSize: newPageSize
    });
  }

  const handleSortChange = (event, property) => {
    const isAsc = controller.sortBy === property && controller.sort === 'asc';

    setController({
      ...controller,
      page: 0,
      sort: isAsc ? 'desc' : 'asc',
      sortBy: property,
    });
  };

  const handleBulkStatusChangeConfirmation = async (selectedCompanies, items) => {
    try {
      const data = new FormData();
      data.append('positionIds', selectedCompanies.join(','));
      if (items.actionType && items.actionType === 'map-cr-policy') {
        data.append('changeRequestPolicyId', items.crPolicy);
        const response = await formDataApi.post('/updatePositionsMappedToChangeRequestPolicy', data);
        if (response.status === 200) {
          enqueueSnackbar(response.data.message, { variant: 'success' });
          fetchData(controller.filters, controller.sort, controller.sortBy, controller.page, controller.pageSize);
        }
      } else {
        for (const key in items) {
          data.append(key, items[key]);
        }
        const response = await formDataApi.post('/bulkUpdatePositionStatuses', data);
        if (response.status === 200) {
          enqueueSnackbar(response.data.message, { variant: 'success' });
          fetchData(controller.filters, controller.sort, controller.sortBy, controller.page, controller.pageSize);
        }
      }
    } catch (error) {
      console.log('error while updating bulk status update', error);
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
    }
  };

  const handleRowClick = (id) => {
    navigate(`/company/${id}`);
  };

  const handleSelect = (event, rowId) => {
    dispatch(updateSelectedCompanies({ isChecked: event.target.checked, rowId }));
  };

  const handleSelectAll = (event) => {
    dispatch(updateAllSelectedCompanies({ isChecked: event.target.checked }));
  };

  return (
    <Box sx={styles.growBox}>
      <Container maxWidth='lg' sx={styles.pageWrapper}>
        <Grid container direction='column'>
          <Grid item container spacing={1} justifyContent='flex-start' alignItems='center' py={4}>
            <Grid item>
              <Typography variant='h4'>Company</Typography>
            </Grid>
            <Grid item xs></Grid>
            <Grid item xs='auto'>
              <PermissionChecker
                requiredPermissions={['ROLE_BASE_PERMISSION_SYSTEM_INDEX_CREATE_COMPANY']}
                noAuthRedirect={false}
              >
                <Button
                  color='primary'
                  onClick={() => navigate('/company/add')}
                  size='large'
                  startIcon={<PlusIcon fontSize='small' />}
                  variant='contained'
                  sx={{ ':hover': { backgroundColor: 'secondary.main' } }}
                >
                  Add Company
                </Button>
              </PermissionChecker>
            </Grid>
          </Grid>

          <Grid item>
            <Card>
              <CardContent>
                <CustomTable
                  isLoading={loading}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                  onSelect={handleSelect}
                  onSelectAll={handleSelectAll}
                  onRowClick={handleRowClick}
                  onSortChange={handleSortChange}
                  page={controller.page + 1}
                  columns={columns}
                  rows={companies.data}
                  rowsCount={companies.count}
                  selectedRows={selectedCompanies}
                  sort={controller.sort}
                  sortBy={controller.sortBy}
                  showRowSelector={false}
                  showPagignation={true}
                  keyColumn='id'
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Company;
