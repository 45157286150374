import React from 'react';
import { Chip } from '@mui/material';

export const PositionStatusChip = ({ label, ...props }) => {
  const backgroundColors = {
    Draft: '#D3EDFF',
    Open: '#D6F5C7',
    'Open Pending Intake': '#D6F5C7',
    'Open Active Sourcing': '#FEE0FF',
    'On Hold - HM Delay': '#fff2cc',
    "Offer Negotiation": '#a3d1ff',
    'Offer Released': '#D5F9FC',
    'Offer Accepted': '#D5DEFC',
    Filled: '#F2F3BD',
    'On Hold': '#FFE8C5',
    Cancelled: '#FFE1D3',
  };
  return <Chip label={label} sx={{ backgroundColor: backgroundColors[label] }} props />;
};
