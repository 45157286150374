import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Collapse, List, Typography } from '@mui/material';
import { ChevronRight as ChevronRightIcon } from '../../../assets/icons/chevron-right';
import { ChevronDown as ChevronDownIcon } from '../../../assets/icons/chevron-down';

const LayoutItem = (props) => {
  const { active, activeHref, external, href, icon: Icon, routes, onOpen, open, pinned, title } = props;

  // Branch
  if (routes) {
    return (
      <List disablePadding sx={{ width: '100%' }}>
        <li>
          <Button
            endIcon={
              open ? (
                <ChevronDownIcon color='action' fontSize='small' />
              ) : (
                <ChevronRightIcon color='action' fontSize='small' />
              )
            }
            fullWidth
            onClick={onOpen}
            startIcon={<Icon />}
            sx={{
              fontWeight: 400,
              justifyContent: 'flex-start',
              lineHeight: 0,
              minWidth: 'fit-content',
              px: 1.25,
              py: 1.25,
              '& .MuiButton-startIcon': {
                color: active ? 'primary' : 'action.active',
                margin: 0,
              },
              '& .MuiButton-endIcon': {
                color: 'action.disabled',
                display: pinned ? 'flex' : 'none',
                marginLeft: 'auto',
              },
              ':hover': {
                backgroundColor: 'transparent',
              },
            }}
            variant='text'
          >
            <Typography
              color='textPrimary'
              sx={{
                color: active ? 'primary' : 'text.secondary',
                display: pinned ? 'flex' : 'none',
                ml: 1.25,
              }}
              variant='inherit'
            >
              {title}
            </Typography>
          </Button>
        </li>
        <Collapse in={open} unmountOnExit>
          <List disablePadding sx={{ width: '100%' }}>
            {routes.map((route) => {
              const isActive = activeHref === route.href;

              return (
                <li key={route.href}>
                  <Button
                    component={Link}
                    to={route.href}
                    fullWidth
                    sx={{
                      color: isActive ? 'primary' : 'text.secondary',
                      fontWeight: 400,
                      justifyContent: 'flex-start',
                      pl: 5,
                      whiteSpace: 'nowrap',
                      ':hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                    variant='text'
                  >
                    {route.title}
                  </Button>
                </li>
              );
            })}
          </List>
        </Collapse>
      </List>
    );
  }

  // Leaf
  return (
    <li>
      <Button
        component={Link}
        to={href}
        fullWidth
        startIcon={<Icon />}
        target={external ? '_target' : '_self'}
        sx={{
          fontWeight: 400,
          justifyContent: 'flex-start',
          lineHeight: 0,
          minWidth: 'fit-content',
          px: 1.25,
          py: 1.25,
          '& .MuiButton-startIcon': {
            color: active ? 'primary' : 'text.secondary',
            margin: 0,
          },
          '& .MuiButton-endIcon': {
            color: 'action.disabled',
            display: pinned ? 'flex' : 'none',
            marginLeft: 'auto',
          },
          ':hover': {
            backgroundColor: 'transparent',
          },
        }}
        variant='text'
      >
        <Typography
          color='textPrimary'
          sx={{
            color: active ? 'primary' : 'text.secondary',
            display: pinned ? 'flex' : 'none',
            ml: 1.25,
          }}
          variant='inherit'
        >
          {title}
        </Typography>
      </Button>
    </li>
  );
};

export default LayoutItem;
