import React, { useState, useEffect } from 'react';
import { Box, List } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WorkIcon from '@mui/icons-material/Work';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Groups2Icon from '@mui/icons-material/Groups2';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import { useLocation } from 'react-router-dom';
import LayoutItem from './LayoutItem';
import { hasPermission } from '../../../utils/permission';
import withAuth from '../../../helpers/withAuth';

const routes = [
  // {
  //   id: 1,
  //   title: 'Dashboard',
  //   icon: DashboardIcon,
  //   href: '/dashboard',
  //   permissions: ['ROLE_BASE_PERMISSION_SENTINEL_EMP'],
  // },
  {
    id: 2,
    title: 'Positions',
    icon: WorkIcon,
    href: '/positions',
    permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_LIST_POSITIONS'],
  },
  {
    id: 3,
    title: 'Change Policies',
    icon: InsertDriveFileIcon,
    href: '/changerequest/crpolicies',
    permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_LIST_CHANGE_REQUEST_POLICIES'],
  },
  {
    id: 4,
    title: 'Recruitment Policies',
    icon: WorkHistoryIcon,
    href: '/recruitmentPolicies',
    permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_FETCH_RECRUITMENT_POLICY'],
  },
  {
    id: 5,
    title: 'Change Requests',
    icon: ChangeCircleIcon,
    href: '/changerequests',
    permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_LIST_CHANGE_REQUESTS'],
  },
  {
    id: 6,
    title: 'Companies',
    icon: ManageAccountsIcon,
    href: '/company',
    // TODO: add right permission
    permissions: ['ROLE_BASE_PERMISSION_SYSTEM_INDEX_LIST_COMPANIES'],
  },
  {
    id: 7,
    title: 'Users',
    icon: Groups2Icon,
    href: '/users',
    permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_LIST_SECUSER'],
    permissionsNA: ['ROLE_SUPER_ADMINISTRATOR'],
  },
  // {
  //   id: 8,
  //   title: 'Contacts',
  //   icon: MailOutlineIcon,
  //   href: '/contacts',
  //   permissions: ['ROLE_BASE_PERMISSION_INDEX_LIST_SIMPLE_CONTACTS'],
  // },
  {
    id: 9,
    title: 'Settings',
    icon: SettingsIcon,
    permissions: [], //["ROLES_CUSTOM_ATTRIBUTE", "ROLES_ROLES"],
    routes: [
      {
        title: 'Demand Attributes',
        href: '/settings/demand-attributes',
        permissions: [
          'ROLE_BASE_PERMISSION_INDEX_CREATE_META_OBJECT_DEFN',
          'ROLE_BASE_PERMISSION_INDEX_LIST_META_OBJECT_DEFN',
          'ROLE_BASE_PERMISSION_INDEX_VIEW_META_OBJECT_DEFN',
          'ROLE_BASE_PERMISSION_INDEX_UPDATE_META_OBJECT_DEFN',
          'ROLE_BASE_PERMISSION_INDEX_SEARCH_META_OBJECT_DEFN',
        ], // ["ROLES_CUSTOM_ATTRIBUTE"],
      },
      {
        title: 'Change Policy Roles',
        href: '/settings/change-policy/roles',
        permissions: ['ROLE_ADMINISTRATOR'],
      },
      {
        title: 'Roles',
        icon: LocationOnIcon,
        href: '/settings/roles',
        permissions: ['ROLE_BASE_PERMISSION_SECROLE_LIST'],
      },
      {
        title: 'Roles',
        icon: LocationOnIcon,
        href: '/settings/roles',
        permissions: ['ROLE_SUPER_ADMINISTRATOR'],
      },
      {
        title: 'Recruitment Policy Attributes',
        icon: WorkHistoryIcon,
        href: '/settings/recruitment-policy/attributes',
        permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_PRE_CREATE_RULE_SETTINGS_FOR_RECRUITMENT_POLICY'],
      },
      {
        title: 'Locations',
        icon: LocationOnIcon,
        href: '/settings/locations',
        permissions: ['ROLE_BASE_PERMISSION_INDEX_LIST_LOCATIONS'],
      },
      {
        title: 'Designations',
        icon: LocationOnIcon,
        href: '/settings/designations',
        permissions: ['ROLE_BASE_PERMISSION_INDEX_LIST_DESIGNATIONS'],
      },
      {
        title: 'Departments',
        href: '/settings/departments',
        permissions: ['ROLE_BASE_PERMISSION_INDEX_LIST_DEPARTMENTS'],
      },
      {
        title: 'Refresh Integation',
        href: '/settings/update-integration',
        permissions: ['ROLE_SUPER_ADMINISTRATOR'],
      },
      {
        title: 'Modify Position Ext ID',
        href: '/settings/modify-position-external-ids',
        permissions: ['ROLE_ADMINISTRATOR'],
      },
      {
        title: 'Bulk Upload Position Data',
        href: '/settings/upload-position-data',
        permissions: ['ROLE_ADMINISTRATOR'],
      },
      {
        title: 'Request Maps',
        icon: LocationOnIcon,
        href: '/settings/request-maps',
        permissions: ['ROLE_SUPER_ADMINISTRATOR'],
      },
      {
        title: 'System Permissions',
        icon: LocationOnIcon,
        href: '/settings/system-permissions',
        permissions: ['ROLE_SUPER_ADMINISTRATOR'],
      },
    ],
  },
];

const getSidebarItems = (permissions, items = routes) => {
  if (!items.length) return items;
  const authorizedItems = items
    .filter((item) => {
      const isDashboard = item.href === '/dashboard';
      if (isDashboard) {
        const isSuperAdmin = hasPermission(permissions, ['ROLE_SUPER_ADMINISTRATOR']);
        return isSuperAdmin ? false : true;
      }
      return hasPermission(permissions, item.permissions, item.permissionsNA);
    })
    .filter((item) => {
      if (item.routes) {
        item.routes = getSidebarItems(permissions, item.routes);
      }

      return true;
    });

  const uniqueItemObject = {};
  authorizedItems.forEach(item => uniqueItemObject[item.title] = item);

  return Object.keys(uniqueItemObject).reduce((uniqueItems, item) => {
    return [...uniqueItems, uniqueItemObject[item]];
  },[])
};

const Layout = ({ auth }) => {
  const hovered = true;
  const pinned = true;
  const location = useLocation();
  const [openedItem, setOpenedItem] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [activeHref, setActiveHref] = useState('');

  useEffect(() => {
    routes.forEach((route) => {
      if (route.routes) {
        for (let index = 0; index < route.routes.length; index++) {
          const active = location.pathname.match(route.routes[index].match || route.routes[index].href) !== null;
          //const active = matchPath({ path: route.routes[index].href, exact: false }, location.pathname);
          if (active) {
            setActiveItem(route);
            setActiveHref(route.routes[index].href);
            setOpenedItem(route);
            break;
          }
        }
      } else {
        const active = (location.pathname && location.pathname?.toLowerCase()).match(route.match?.toLowerCase() || route.href?.toLowerCase()) !== null;
        //const active = matchPath({ path: route.href, exact: false }, location.pathname);
        if (active) {
          setActiveItem(route);
          setOpenedItem(route);
        }
      }
    });
  }, [location.pathname]);

  const handleOpenItem = (item) => {
    if (openedItem === item) {
      setOpenedItem(null);
      return;
    }

    setOpenedItem(item);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          p: 2,
        }}
      >
        <List disablePadding>
          {activeItem &&
            getSidebarItems(auth.authorities).map((route) => (
              <LayoutItem
                active={activeItem?.title === route.title}
                activeHref={activeHref}
                key={route.title}
                onOpen={() => handleOpenItem(route)}
                open={openedItem?.title === route.title && (hovered || pinned)}
                pinned={pinned}
                {...route}
              />
            ))}
        </List>
        <Box sx={{ flexGrow: 1 }} />
        {/* <Divider />
        <Box sx={{ pt: 1 }}>
          <IconButton onClick={() => {}}>
            {pinned ? <ChevronLeftIcon color='action' /> : <ChevronRightIcon color='action' />}
          </IconButton>
            </Box>*/}
      </Box>
    </>
  );
};

export default withAuth(Layout);
