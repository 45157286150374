import React, { useState, useEffect } from 'react';
import { Box, Container, Card, CardContent, CardActions, Grid, Typography, Button, Divider, Tab, Tabs } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormData from 'form-data';
import MetaFields from '../PositionFields/MetaFields';

import { Plus as PlusIcon } from '../../../assets/icons/plus';
import { api, formDataApi } from '../../../axios';
import { styles } from './styles';

import { useSnackbar } from 'notistack';
import PermissionChecker from '../../../components/PermissionChecker';
import { useDispatch } from 'react-redux';
import { updateCustomAttributes } from '../../../store/slices/customAttributes';
import { TabContext, TabPanel } from '@mui/lab';
import BasicFields from '../PositionFields/BasicFields';

const views = [
  {
    label: 'Basic Attributes',
    value: 'basic-attributes',
  },
  {
    label: 'Custom Attributes',
    value: 'custom-attributes',
  },
];

const AddCustomAttributes = () => {
  const [view, setView] = useState('basic-attributes');
  const [loading, setLoading] = useState(false);
  const [metaRowsData, setMetaRowsData] = useState([]);
  const [basicRowsData, setBasicRowsData] = useState([]);
  const [deletedFieldIds, setDeletedFieldIds] = useState([]);
  const [metaCustomObjectId, setMetaCustomObjectId] = useState(null);
  const [isCreate, setIsCreate] = useState(true);
  const [isTouched, setTouched] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const getMetaCustomFields = async () => {
    const data = new FormData();
    data.append('metClassName', 'Position');
    const response = await formDataApi.post('/viewMetaObjectDefinition', data);
    if (response.data) {
      setMetaCustomObjectId(response.data?.metaCustomObjectDefinition?.id || null);
      setMetaRowsData(response.data?.metaCustomObjectDefinition?.metaCustomFieldDefinitions || []);
      setBasicRowsData(response.data?.basicAttributes || [])
      response.data?.metaCustomObjectDefinition?.metaCustomFieldDefinitions.length > 0 ? setIsCreate(false) : setIsCreate(true);
    }
  };

  const dispatchCustomAttributes = async () => {
    const response = await api.post('/listMetaObjectDefinitions');
    if (response.data?.metaCustomObjectDefinitions?.length) {
      dispatch(updateCustomAttributes(response.data.metaCustomObjectDefinitions[0]));
    }
  };

  useEffect(() => {
    getMetaCustomFields();
  }, []);

  const handleAdd = () => {
    if(!isTouched) setTouched(true);
    const rowsInput = {
      fieldLabel: '',
      fieldDataType: 'String',
      fieldListOptions: '',
      category: 'Minor',
      isRequired: false
    };
    setMetaRowsData([...metaRowsData, rowsInput]);
  };

  const handleChange = (index, e) => {
    if(!isTouched) setTouched(true);
    const { name, value } = e.target;
    const rowsInput = [...metaRowsData];
    rowsInput[index][name] = value;
    rowsInput[index]['isModify'] = true;
    setMetaRowsData(rowsInput);
  };

  const handleDelete = (index) => {
    if(!isTouched) setTouched(true);
    const rows = [...metaRowsData];
    const deletedRow = rows.splice(index, 1);
    setDeletedFieldIds([...deletedFieldIds,deletedRow[0]?.id])
    setMetaRowsData(rows);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const data = new FormData();
      data.append('baseObjectMetaClassName', 'Position');
      data.append('id', metaCustomObjectId);
      deletedFieldIds.length > 0 && data.append('deletedFieldIds', deletedFieldIds);
      let index = 0;
      metaRowsData.forEach((row) => {
        if(row.id && !row.isModify) return;
        data.append(`metaCustomFieldDefinitions[${index}].fieldLabel`, row.fieldLabel);
        data.append(`metaCustomFieldDefinitions[${index}].fieldDataType`, row.fieldDataType);
        data.append(`metaCustomFieldDefinitions[${index}].fieldListOptions`, row.fieldListOptions);
        data.append(`metaCustomFieldDefinitions[${index}].category`, row.category?.name || row.category || '');
        data.append(`metaCustomFieldDefinitions[${index}].isRequired`, row.isRequired);
        if (row.id) {
          data.append(`metaCustomFieldDefinitions[${index}].id`, row.id);
        }
        index++;
      });

      const url = !isCreate ? '/modifyMetaObjectDefinitionAction' : '/saveMetaObjectDefinition';

      const response = await formDataApi.post(url, data);
      if (response.status === 200 && response.data) {
        if(response.data.status === "SUCCESS")
          enqueueSnackbar(response.data.message, { variant: 'success' });
        else
          enqueueSnackbar(response.data.message, { variant: 'error' });
        setDeletedFieldIds([])
        getMetaCustomFields();
        dispatchCustomAttributes();
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setTouched(false);
      setLoading(false);
    }
  };

  return (
    <>
      <Box sx={styles.growBox}>
        <Container maxWidth='lg' sx={styles.pageWrapper}>
          <Box id="positionHeader" justifyContent='space-between' sx={{ pt: '20px', pb: '32px', display: "flex" }}>
            <Box sx={styles.flexCenterNone}>
              <Typography variant='h4'>Demand Attributes</Typography>
            </Box>
            {view === "custom-attributes" && <Box>
              <PermissionChecker
                requiredPermissions={[
                  'ROLE_BASE_PERMISSION_INDEX_CREATE_META_OBJECT_DEFN',
                  'ROLE_BASE_PERMISSION_INDEX_UPDATE_META_OBJECT_DEFN',
                ]}
                noAuthRedirect={false}
              >
                <Button
                  color='primary'
                  onClick={() => handleAdd()}
                  size='medium'
                  startIcon={<PlusIcon fontSize='small' />}
                  variant='contained'
                >
                  Add
                </Button>
              </PermissionChecker>
            </Box>}
          </Box>
          <Card>
            <CardContent>
              <Grid container direction='column' spacing={2}>
                {/*<Grid item>
                  <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} elevation={0} square>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel1a-content'
                      id='panel1a-header'
                    >
                      <Typography variant='body2'>Default Attributes</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant='body2'>Default attribute list goes here</Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>*/}
                <Grid item container justifyContent='space-between' alignItems='center'>
                  <Grid item>
                    <TabContext value={view}>
                      <Tabs
                        value={view}
                        onChange={(event, value) => setView(value)}
                        aria-label='basic tabs example'
                      >
                        {views.map((option) => (
                          <Tab key={option.label} disabled={false} label={option.label} value={option.value} />
                        ))}
                      </Tabs>
                      <Divider sx={styles.divider} />
                      <TabPanel sx={{...styles.tabpanel, px :"0px", pt:"0px" }} value={'basic-attributes'}>
                        <BasicFields data={basicRowsData}/>
                      </TabPanel>
                      <TabPanel sx={{...styles.tabpanel, px :"0px", pt:"40px" }} value={'custom-attributes'}>
                        <MetaFields data={metaRowsData} handleChange={handleChange} handleDelete={handleDelete} />
                      </TabPanel>
                    </TabContext>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            {view === 'custom-attributes' &&
              <CardActions disableSpacing sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <PermissionChecker
                  requiredPermissions={[
                    'ROLE_BASE_PERMISSION_INDEX_CREATE_META_OBJECT_DEFN',
                    'ROLE_BASE_PERMISSION_INDEX_UPDATE_META_OBJECT_DEFN',
                  ]}
                  noAuthRedirect={false}
                >
                  <LoadingButton
                    loading={loading}
                    color='primary'
                    variant='contained'
                    size='large'
                    onClick={handleSubmit}
                    disabled={!isTouched}
                  >
                    {!isCreate ? 'Update' : 'Save'}
                  </LoadingButton>
                </PermissionChecker>
              </CardActions>
            }
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default AddCustomAttributes;
