import { useEffect, useState } from 'react';
import { Grid, Box, Button, Card, CardContent, Container, Typography, Divider } from '@mui/material';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { ChevronLeft as ChevronLeftIcon } from '../../assets/icons/chevron-left';
import { styles as positionStyles } from '../Positions/styles';
import { styles } from './styles';
import { formDataApi } from '../../axios';
import UserData from '../../components/UserData';

const ViewUser = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [userDetails, setUserDetails] = useState({ isLoading: true });

  useEffect(() => {
    setUserDetails({ ...userDetails, isLoading: true });
    async function fetchData() {
      const bodyFormData = new FormData();
      if(params.companyId){
        bodyFormData.append('company', params.companyId);
      }
      bodyFormData.append('id', params.id);
      try {
        const response = await formDataApi.post('/displayUser', bodyFormData);
        if (response?.data.employeeInstance) {
          setUserDetails(response?.data);
        }
      } catch (err) {
        setUserDetails({ isLoading: false });
        toast.error('Something went wrong! User could not be fetched.');
      }
    }
    fetchData();
  }, [params.id]);

  return (
    <Box sx={positionStyles.growBox}>
      <Container maxWidth='lg' sx={styles.pageWrapper}>
        <Grid container direction='column' spacing={2}>
          <Grid sx={{ py: '32px !important' }} item container justifyContent='space-between'>
            <Grid item>
              <Typography variant='h4'>Users</Typography>
            </Grid>
          </Grid>
          <Grid item sx={{ pt: '0px !important' }}>
            <Card {...props}>
              <CardContent>
                <Grid container direction='column' spacing={2}>
                  <Grid item>
                    <Button
                      startIcon={<ChevronLeftIcon />}
                      variant='text'
                      color='secondary'
                      onClick={() => navigate(params.companyId ? `/company/${params.companyId}/users` : "/users")}
                    >
                      Back
                    </Button>
                  </Grid>
                </Grid>
                <Grid item>
                  <UserData user={userDetails} id={params.id} />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ViewUser;
