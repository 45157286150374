import React, { useState } from 'react';
import { Button, Menu, MenuItem, ListItemText, ListItemIcon } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PublicIcon from '@mui/icons-material/Public';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { usePopover } from '../../hooks/use-popover';
import { styles } from './styles';
import PositionBulkActionDialog from './PositionBulkActionDialog';
import { useDispatch } from 'react-redux';
import AutorenewIcon from '@mui/icons-material/Autorenew';

import { useNavigate } from 'react-router-dom';

import { usePositionPublicationStatus } from '../../hooks/use-position-publication-status';
import { usePositionStatus } from '../../hooks/use-position-status';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';

import PermissionChecker from '../PermissionChecker';

const actions = [
  {
    label: 'Publish',
    type: 'Published',
    icon: <PublicIcon />,
    permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_PUBLISH_POSITION'],
  },
  {
    label: 'Approve',
    type: 'Open',
    icon: <CheckIcon />,
    permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_BULK_UPDATE_POSITION_STATUS'],
  },
  /* {
    label: 'Modify',
    type: 'modify',
    icon: <EditIcon />,
    permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_UPDATE_POSITION'],
  },*/
  {
    label: 'Reject',
    type: 'Cancelled',
    icon: <CloseIcon />,
    permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_BULK_UPDATE_POSITION_STATUS'],
  },
  {
    label: 'Map Change Policy',
    type: 'map-cr-policy',
    icon: <InsertDriveFileIcon />,
    permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_MAP_POSITION_TO_CHANGE_REQUEST_POLICY'],
  },
  {
    label: 'Resync',
    type: 'resync',
    icon: <AutorenewIcon />,
    permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_RESYNC_POSITION'],
  },
  /*{
    label: 'Create Change Request',
    type: 'create-cr',
    icon: <ChangeCircleIcon />,
    permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_CREATE_CHANGE_REQUEST'],
  },*/
  {
    /*{
    label: 'Change Status',
    type: 'change-status',
    icon: <ChangeCircleIcon />,
    permissions: [],
  },*/
  },
];

const PositionBulkActions = ({ selectedPositions, onBulkStatusChangeConfirmation }) => {
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const [openPositionDialog, setOpenPositionDialog] = useState(false);
  const [actionType, setActionType] = useState('');
  const [title, setTitle] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [disableStatusMenuItem, positionStatus] = usePositionStatus();
  const [disablePublish, disableCR] = usePositionPublicationStatus();

  const handleMenuItemClick = (action) => {
    handleClose();
    if (action.type === 'create-cr') {
      if (selectedPositions.length === 1) navigate(`/changeRequests/create/${selectedPositions[0]}`);
      else navigate(`/changeRequests/bulk/create`);
      return;
    }

    if (action.type === 'modify') {
      navigate('/positions/bulk-modify');
      return;
    }
    setActionType(action.type);
    setOpenPositionDialog(true);
    let title;
    let confirmation;

    switch (action.type) {
      case 'Published':
        title = 'Publish Positions';
        confirmation = `Are you sure you want to publish ${selectedPositions.length} ${selectedPositions.length > 1 ? 'positions' : 'position'
          }?`;
        break;
      case 'Open':
        title = 'Approve Positions';
        confirmation = `Are you sure you want to Approve ${selectedPositions.length} ${selectedPositions.length > 1 ? 'positions' : 'position'
          }?`;
        break;
      case 'Cancelled':
        title = 'Reject Positions';
        confirmation = `Are you sure you want to reject ${selectedPositions.length} ${selectedPositions.length > 1 ? 'positions' : 'position'
          }?`;
        break;
      case 'map-cr-policy':
        title = 'Map Change Policy';
        confirmation = ``;
        break;
      case 'resync':
        title = 'Resync';
        confirmation = `Are you sure you want to Resync ${selectedPositions.length} ${selectedPositions.length > 1 ? 'positions' : 'position'} to canvas?`;
        break;
      case 'change-status':
        title = 'Change Status';
        confirmation = ``;
        break;
      default:
        return;
    }
    setTitle(title);
    setConfirmation(confirmation);
  };

  const handleResult = (result) => {
    setOpenPositionDialog(false);
    if (!result) return;

    if (actionType === 'Open' || actionType === 'Cancelled') {
      result = { ...result, status: actionType };
    } else if (actionType === 'Published') {
      result = { ...result, publicationStatus: actionType };
    } else if (actionType === 'map-cr-policy') {
      result = { ...result, actionType };
    } else if (actionType === 'resync') {
      result = { ...result, actionType };
    }

    onBulkStatusChangeConfirmation(selectedPositions, result);
  };
  return disableStatusMenuItem || (['Cancelled', 'Filled', 'On Hold - HM Delay', 'Offer Negotiation', 'Offer Released', 'Offer Accepted'].indexOf(positionStatus) >= 0) ? null : (
    <>
      <Button
        color='primary'
        onClick={handleOpen}
        ref={anchorRef}
        size='medium'
        endIcon={<ExpandMoreIcon fontSize='small' />}
        variant='contained'
      >
        Bulk Actions
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={styles.buttonMenu}
      >
        {actions.map((action) => {
          if (
            positionStatus === 'Draft' &&
            disablePublish &&
            (action.type === 'Open' ||
              action.type === 'modify' ||
              action.type === 'Cancelled' ||
              action.type === 'map-cr-policy')
          ) {
            return (
              <PermissionChecker requiredPermissions={action.permissions} noAuthRedirect={false} key={action.type}>
                <MenuItem
                  onClick={() =>
                    handleMenuItemClick({
                      label: action.label,
                      type: action.type,
                    })
                  }
                >
                  <ListItemIcon>{action.icon}</ListItemIcon>
                  <ListItemText variant='body2'>{action.label}</ListItemText>
                </MenuItem>
              </PermissionChecker>
            );
          }
          if (positionStatus !== 'Draft' && action.type === 'resync') {
            return (
              <PermissionChecker requiredPermissions={action.permissions} noAuthRedirect={false} key={action.type}>
                <MenuItem
                  onClick={() =>
                    handleMenuItemClick({
                      label: action.label,
                      type: action.type,
                    })
                  }
                >
                  <ListItemIcon>{action.icon}</ListItemIcon>
                  <ListItemText variant='body2'>{action.label}</ListItemText>
                </MenuItem>
              </PermissionChecker>
            );
          }
          if (
            (['Open', 'Open Pending Intake', 'Open Active Sourcing'].indexOf(positionStatus) >= 0) &&
            disablePublish &&
            (action.type === 'Published' || action.type === 'map-cr-policy')
          ) {
            return (
              <PermissionChecker requiredPermissions={action.permissions} noAuthRedirect={false} key={action.type}>
                <MenuItem
                  onClick={() =>
                    handleMenuItemClick({
                      label: action.label,
                      type: action.type,
                    })
                  }
                >
                  <ListItemIcon>{action.icon}</ListItemIcon>
                  <ListItemText variant='body2'>{action.label}</ListItemText>
                </MenuItem>
              </PermissionChecker>
            );
          }
          if ((['Open', 'Open Pending Intake', 'Open Active Sourcing'].indexOf(positionStatus) >= 0) && !disablePublish && action.type === 'map-cr-policy') {
            return (
              <PermissionChecker requiredPermissions={action.permissions} noAuthRedirect={false} key={action.type}>
                <MenuItem
                  key={action.type}
                  onClick={() =>
                    handleMenuItemClick({
                      label: action.label,
                      type: action.type,
                    })
                  }
                >
                  <ListItemIcon>{action.icon}</ListItemIcon>
                  <ListItemText variant='body2'>{action.label}</ListItemText>
                </MenuItem>
              </PermissionChecker>
            );
          }
          if (positionStatus === 'On Hold' && action.type === 'map-cr-policy') {
            return (
              <PermissionChecker requiredPermissions={action.permissions} noAuthRedirect={false} key={action.type}>
                <MenuItem
                  onClick={() =>
                    handleMenuItemClick({
                      label: action.label,
                      type: action.type,
                    })
                  }
                >
                  <ListItemIcon>{action.icon}</ListItemIcon>
                  <ListItemText variant='body2'>{action.label}</ListItemText>
                </MenuItem>
              </PermissionChecker>
            );
          }
          if (action.type === 'create-cr' && !disableCR &&
            (['Open', 'Open Pending Intake', 'Open Active Sourcing'].indexOf(positionStatus) >= 0)) {
            return (
              <PermissionChecker requiredPermissions={action.permissions} noAuthRedirect={false} key={action.type}>
                <MenuItem
                  onClick={() =>
                    handleMenuItemClick({
                      label: action.label,
                      type: action.type,
                    })
                  }
                >
                  <ListItemIcon>{action.icon}</ListItemIcon>
                  <ListItemText variant='body2'>{action.label}</ListItemText>
                </MenuItem>
              </PermissionChecker>
            );
          }
          if (action.type === 'change-status') {
            return (
              <PermissionChecker requiredPermissions={action.permissions} noAuthRedirect={false} key={action.type}>
                <MenuItem
                  onClick={() =>
                    handleMenuItemClick({
                      label: action.label,
                      type: action.type,
                    })
                  }
                >
                  <ListItemIcon>{action.icon}</ListItemIcon>
                  <ListItemText variant='body2'>{action.label}</ListItemText>
                </MenuItem>
              </PermissionChecker>
            );
          }
        })}
      </Menu>
      <PositionBulkActionDialog
        actionType={actionType}
        title={title}
        confirmation={confirmation}
        open={openPositionDialog}
        onResult={handleResult}
        keepMounted
      />
    </>
  );
};

export default PositionBulkActions;
