export const styles = {
  growBox: {
    flexGrow: 1
  },
  dataWrappper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    boxShadow: ' 0 0 9px #9c9c9c61',
    background: "#fff",
    pt: "20px",
    mt: "20px"
  },
  menuButtons: {
    display: "flex",
    justifyContent: 'space-between',
    alignItems: "center",
    p: "10px",
    pr: "10px",
  },
  addTable: {
    width: ' 100%',
    p: '30px 60px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  backButton: {
    width: '300px',
    color: '#3F455A'
  },
  dataBox: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  dataItem: {
    width: () => ({
      md: "260px",
      sm: "500px",
      xs: "260px"
    }),
    height: '70px',
    margin: "20px 0px",
    display: "flex",
    flexDirection: 'column',
    justifyContent: "space-between",
  },
  dataItemLable: {
    fontSize: "14px",
    margin: '5px 0',
    color: "#7C85A2"
  },
  dataItemValue: {
    color: "#3F455A"
  },
  coloreLine: {
    width: "100%",
    height: '1px',
    margin: '20px 0',
    background: "#C1C4CC",
  },
  selectButton: {
    with: "160px",
    alignItems: "center",
    background: "#FFF4F0",
    color: "#ED7342",
    border: "1px solid #ED7342",

    "&:hover": {
      background: "#FFF4F0",
      color: "#ED7342",
    },
  },
  tableMenuDialog: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}