import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Card, CardContent, Container, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import { styles } from './styles';
import { utilStyles } from '../../../utils/styles';
import { ChevronLeft as ChevronLeftIcon } from '../../../assets/icons/chevron-left';
import { formDataApi } from '../../../axios';
import { useFormik } from 'formik';
import UpdateChangeRequests from './updateRequestDetails';
import { getFormInitialValues } from '../../../utils/get-form-initial-values';
import DynamicField from '../../../components/DynamicForm/Field';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { hasPermission } from '../../../utils/permission';
import minorCategoryIcon from '../../../assets/images/minorCategory.svg';
import majorCategoryIcon from '../../../assets/images/majorCategory.svg';
import { PositionStatusChip } from '../../../components/Position/PositionStatusChip';

const ChangeRequests = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [metaCustomFields, setMetaCustomFields] = useState([]);
  const [changeRequest, setChangeRequest] = useState({ isLoading: true });
  const [preCreateUpdateDetails, setPreCreateUpdateDetails] = useState({ isLoading: true });
  const [categoryType, setCategoryType] = useState("Minor");
  const { positions } = useSelector((state) => state.positions);
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasCompaCreatePermisson = hasPermission(authorities, ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_COMPA_CREATE_CHANGE_REQUEST'])
  const userHasCompaModifyPermisson = hasPermission(authorities, ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_COMPA_UPDATE_CHANGE_REQUEST'])
  const userHasCompaDisplayPermisson = hasPermission(authorities, ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_COMPA_VIEW_CHANGE_REQUEST'])

  const [newMetaValues, setNewMetaValues] = useState([]);
  useEffect(() => {
    if (changeRequest?.changeRequest?.attributes) {
      let newVal = [];
      changeRequest?.changeRequest?.attributes.map((element) => {
        newVal.push({
          fieldLabel: element.name,
          fieldValue: element.newValue,
        });
      });
      setNewMetaValues(newVal);
    }
  }, [changeRequest]);

  let initialValues = getFormInitialValues(metaCustomFields, newMetaValues);
  const [isBulkModify, setIsBulkModify] = useState(false);
  const [isModify, setIsModfiy] = useState(false);
  const [isBulkCreate, setIsBulkCreate] = useState(false);
  const [isRequestingCR, setRequestingCR] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    setChangeRequest({ ...changeRequest, isLoading: true });
    const getCRDetails = async () => {
      if (params.id) {
        setPreCreateUpdateDetails({ ...preCreateUpdateDetails, isLoading: true });
        const bodyFormData = new FormData();
        bodyFormData.append('id', params.id);
        const apiPath = userHasCompaModifyPermisson ? '/preModifyChangeRequestWithCompaFields' : '/preModifyChangeRequest'
        const response = await formDataApi.post(apiPath, bodyFormData);
        if (response.data) {
          setChangeRequest(response.data);
          setMetaCustomFields(response?.data?.extendedDef?.metaCustomFieldDefinitions || []);
          setPreCreateUpdateDetails({
            designationsList: response?.data?.designationsList,
            departmentsList: response?.data?.departmentsList,
            locationsList: response?.data?.locationsList,
            availablePositionStatuses: response?.data?.availablePositionStatuses,
            basicAttributes: response?.data?.basicAttributes,
            isLoading: false 
          });
          setCategoryType(response.data?.changeRequest?.categoryType?.name)
        }else{
          setPreCreateUpdateDetails({ ...preCreateUpdateDetails, isLoading: false });
        }
      } else {
        if (!isBulkModify) {
          try {
            setPreCreateUpdateDetails({ ...preCreateUpdateDetails, isLoading: true });
            //const response = await api.post('/preCreatePosition');
            const bodyFormData = new FormData();
            bodyFormData.append('parentEntityId', params.positionId);
            const apiPath = userHasCompaCreatePermisson ? '/preCreateChangeRequestWithCompaFields' : '/preCreateChangeRequest'
            const preCreationCRData = await formDataApi.post(apiPath, bodyFormData);
            setMetaCustomFields(preCreationCRData?.data?.extendedDef?.metaCustomFieldDefinitions || []);
            setPreCreateUpdateDetails({ ...preCreationCRData.data, isLoading: false  });
            setPositionDetails({ ...preCreationCRData.data.position });
          } catch (err) {
            setPreCreateUpdateDetails({ ...preCreateUpdateDetails, isLoading: false });
            console.log('error in useEffect when precreate CR ', err);
            // enqueueSnackbar('Something went wrong!', { variant: 'error' });
          }
        }
      }
    };
    getCRDetails();
  }, []);

  useEffect(() => {
    if (!params.positionId && window.location.pathname.toLowerCase().includes('/bulk/modify')) {
      setIsBulkModify(true);
    } else if (!params.positionId && window.location.pathname.toLowerCase().includes('/bulk/create')) {
      setIsBulkCreate(true);
    } else if (params.positionId && window.location.pathname.includes('/modify')) {
      setIsModfiy(true);
    }
  }, [params, window.location.pathname]);

  // useEffect(() => {
  //   const getCustomAttributes = async () => {
  //     const response = await api.post('/listMetaObjectDefinitions');
  //     if (response.data) {
  //       setMetaCustomFields(response.data.metaCustomObjectDefinitions[0]?.metaCustomFieldDefinitions || []);
  //     }
  //   };
  //   getCustomAttributes();
  // }, []);

  const [positionDetails, setPositionDetails] = useState({ isLoading: true });
  const [positionMetaInfo, setPositionMetaInfo] = useState(null);
  useEffect(() => {
    if (params.positionId && isModify) {
      setPositionDetails({ ...positionDetails, isLoading: true });
      async function fetchData() {
        const bodyFormData = new FormData();
        bodyFormData.append('id', params.id);
        try {
          const apiPath = userHasCompaDisplayPermisson ? '/displayChangeRequestWithCompaFields' : '/displayChangeRequest'
          const response = await formDataApi.post(apiPath, bodyFormData);
          if (response?.data?.changeRequest?.position) {
            setPositionDetails(response?.data?.changeRequest?.position);
            setPositionMetaInfo(response?.data?.changeRequest?.position?.customObjectInstance?.customFieldValues);
          }
        } catch (err) {
          setPositionDetails({ isLoading: false });
          enqueueSnackbar('Something went wrong! Position could not be fetched', { variant: 'error' });
        }
      }
      fetchData();
    } else if (!(isModify || isBulkCreate || isBulkModify)) {
      const position = positions?.data.find(item => item.id == params.positionId)
      setPositionMetaInfo(position?.customObjectInstance?.customFieldValues);

    }
  }, [params.positionId, isBulkModify, positions, isBulkCreate, isModify, params.id]);

  const compaFields = (userHasCompaCreatePermisson || (userHasCompaModifyPermisson && isModify)) ? {
    budgetLowerLimit: '',
    budgetUpperLimit: '',
    budgetMidLimit: '',
  } : {}

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      positionId: '',
      positionCode: '',
      hiringManager: '',
      recruiter: '',
      yoe: '',
      department: '',
      designation: '',
      location: '',
      status: '',
      comments: isModify ? changeRequest?.changeRequest?.comments : '',
      ...compaFields,
      ...initialValues,
    },
    onSubmit: async (values, helpers) => {
      try {
        if (!Object.keys(formik.errors).length) {
          createCR()
          helpers.resetForm();
        }
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const getCustomFields = useCallback(() => {
    if (!metaCustomFields?.length) return [];
    return metaCustomFields.map((field) => {
      const positionMetaField =
        positionMetaInfo &&
        positionMetaInfo.find(
          ({ metaCustomFieldDefinitionID }) => metaCustomFieldDefinitionID === field.metaCustomFieldDefinitionID
        );
      let fieldListOptions = field?.fieldListOptions;
      const currentValue = positionMetaField?.fieldDataType === "Date" ?
        (!!positionMetaField?.fieldValue ? moment(positionMetaField?.fieldValue).format('DD-MMM-YYYY') : "")
        : positionMetaField?.fieldValue;

      if(["Single Selection List", "Multiple Selection List"].includes(positionMetaField?.fieldDataType)){
        fieldListOptions = fieldListOptions?.filter(item => item !== currentValue)
      }
      return {
        attributeName: field.fieldLabel,
        category: field.category?.name,
        currentValue,
        newValue: <DynamicField disabled={field.fieldLabel === "canvasId"} formik={formik} field={{...field,fieldListOptions}} />,
      };
    });
  }, [positionMetaInfo, metaCustomFields, formik]);

  const basicAttributesCategory = useMemo(() => {
    let categoryMapping = {};
    preCreateUpdateDetails?.basicAttributes?.forEach(item => {
      categoryMapping[item.attributeTitle] = item?.category;
    })

    return categoryMapping;
  },[preCreateUpdateDetails])

  const createCR = async () => {
    if(isRequestingCR) return;
    if (Object.keys(formik.errors).length) {
      return
    }
    const compaAttributes = (userHasCompaCreatePermisson || (userHasCompaModifyPermisson && isModify)) ?
      [{
        name: 'budgetLowerLimit',
        displayName: 'Budget Lower Limit',
        currentValue: positionDetails?.budgetLowerLimit,
        newValue: formik?.values?.budgetLowerLimit,
        type: 'Number',
      },
      {
        name: 'budgetMidLimit',
        displayName: 'Budget Mid Limit',
        currentValue: positionDetails?.budgetMidLimit,
        newValue: formik?.values?.budgetMidLimit,
        type: 'Number',
      },
      {
        name: 'budgetUpperLimit',
        displayName: 'Budget Upper Limit',
        currentValue: positionDetails?.budgetUpperLimit,
        newValue: formik?.values?.budgetUpperLimit,
        type: 'Number',
      }]
      : []

    const attributes = [
      // {
      //   name: 'title',
      //   displayName: 'Title',
      //   currentValue: positionDetails?.title,
      //   newValue: formik?.values?.title,
      //   type: 'String',
      // },
      {
        name: 'status',
        displayName: 'Status',
        currentValue: positionDetails?.status,
        newValue: formik?.values?.status,
        type: 'String',
      },
      {
        name: 'positionCode',
        displayName: 'Position code',
        currentValue: positionDetails?.positionCode,
        newValue: formik?.values?.positionCode,
        type: 'String',
      },
      {
        name: 'designation',
        displayName: 'Designation',
        currentValue: positionDetails?.designation?.name,
        newValue: formik?.values?.designation,
        type: 'String',
      },
      {
        name: 'department',
        displayName: 'Department',
        currentValue: positionDetails?.department?.name,
        newValue: formik?.values?.department,
        type: 'String',
      },
      {
        name: 'location',
        displayName: 'Location',
        currentValue: positionDetails?.location?.name,
        newValue: formik?.values?.location,
        type: 'String',
      },
      ...compaAttributes
    ];

    const data = {
      comments: formik?.values?.comments,
      parentEntityId: params.positionId,
      parentClassName: 'Position',
    };

    metaCustomFields.forEach((field) => {
      if (formik?.values[field.fieldLabel]) {
        const positionMetaField =
          positionMetaInfo &&
          positionMetaInfo.find(
            ({ metaCustomFieldDefinitionID }) => metaCustomFieldDefinitionID === field.metaCustomFieldDefinitionID
          );
        const newValue = field.fieldDataType === "Date" ? moment(formik?.values[field.fieldLabel]).format("DD-MMM-yyyy") : formik?.values[field.fieldLabel]
        attributes.push({
          name: field.fieldLabel,
          displayName: field.fieldLabel,
          currentValue: positionMetaField?.fieldValue,
          newValue: newValue,
          type: field.fieldDataType,
        });
      }
    });

    const bodyFormData = new FormData();

    Object.keys(data).forEach((key) => {
      if (data[key] !== '' || data[key]) {
        bodyFormData.append(key, data[key]);
      }
    });

    attributes
      .filter((attribute) => attribute.newValue && attribute.newValue !== '')
      .map((data, index) => {
        Object.keys(data).forEach((key) => {
          bodyFormData.append(`attributes[${index}].${key}`, data[key]);
        });
      });

    if (isModify) {
      bodyFormData.append('id', params.id);
    }

    bodyFormData.append("category",categoryType)

    const apiPath = isModify ? '/modifyChangeRequest' : '/createChangeRequest';

    try {
      console.log(formik.errors)
      setRequestingCR(true);
      const response = await formDataApi.post(apiPath, bodyFormData);
      if (response?.status === 200) {
        enqueueSnackbar(`Change request ${isModify ? 'modified' : 'created'} successfully`, { variant: 'success' });
        navigate(-1);
      }
    } catch (err) {
      enqueueSnackbar(err?.response?.data?.message, { variant: 'error' });
    } finally {
      setRequestingCR(false);
    }
  };

  const handleCategoryChange = (e) => {
    formik.resetForm()
    setCategoryType(e.target.value);
  }

  const allowCreate = useMemo(() => {
    return Object.keys(formik.values).filter(key => key !== "comments" && formik.values[key] && formik.values[key] !== '').length > 0;
  },[formik.values])

  return (
    <Box sx={styles.growBox}>
      <Container maxWidth='lg' sx={styles.pageWrapper}>
        <Box id='modifyChangeRequestHeader' sx={{ pt: '12px !important', pb: '32px' }}>
          <Box sx={styles.flexCenterNone}>
            <Typography variant='h4'>
              {isBulkModify ? 'Bulk Edit Change Requests' :
                (isBulkCreate ? 'Bulk Create Change Requests' :
                  (isModify ? "Update Change Request" : 'Change Request'))}
            </Typography>
          </Box>
        </Box>
        <Card sx={{ ...styles.dataWrappper, pt: 0, mt: 0 }}>
          <CardContent>
            <form onSubmit={formik.handleSubmit}>
              <Grid container direction='column' spacing={2}>
                <Grid item sx={{ display: 'flex', pb: 2 }}>
                  <Button
                    startIcon={<ChevronLeftIcon />}
                    variant='text'
                    color='secondary'
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </Button>
                  <Box sx={styles.growBox} />
                  <Button
                    sx={utilStyles.mx(2)}
                    color='primary'
                    size='large'
                    startIcon={<Check fontSize='small' />}
                    variant='contained'
                    type='submit'
                    disabled={preCreateUpdateDetails?.isLoading || !allowCreate}
                  >
                    {isModify || isBulkModify ? 'Update' : 'Create'}
                  </Button>
                </Grid>
              </Grid>
              {!(isBulkModify || isBulkCreate) && (
                <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                <Grid item container spacing={2} justifyContent="center">
                  <Grid item xs={3} display={'grid'}>
                    <Typography sx={styles.text} color='textSecondary' variant='subtitle2'>
                      Position Code:
                    </Typography>
                    <Typography sx={styles.text} color='textPrimary' variant='body2'>
                      {positionDetails?.positionCode}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} display={'grid'}>
                    <Typography sx={styles.text} color='textSecondary' variant='subtitle2'>
                      Position Status:
                    </Typography>
                    <Box><PositionStatusChip label={positionDetails?.status } /></Box>
                  </Grid>
                  <Grid item xs={3} display={'grid'}>
                    <Typography sx={styles.text} color="textSecondary" variant="subtitle2">
                      Change Policy Name:
                    </Typography>
                    <Typography sx={styles.text} color="textPrimary" variant="body2">
                      {positionDetails?.changeRequestPolicy?.name || '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} display={'grid'}/>
                </Grid>
                </Grid>
              )}
              <Grid item xs={12} mt={2}>
                <Box>
                  <FormControl sx={{ width: "200px", mt: "12px" }} size='small' disabled={isModify}>
                    <InputLabel required id='demo-simple-select-label'>
                      Change Category
                    </InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      label='Change Category'
                      value={categoryType}
                      onChange={handleCategoryChange}
                    >
                      <MenuItem value={'Minor'} key={'Minor'}>
                        <img src={minorCategoryIcon} alt="Minor" style={{paddingRight: "6px"}} />{'Minor'}
                      </MenuItem>
                      <MenuItem value={'Major'} key={'Major'}>
                        <img src={majorCategoryIcon} alt="Major" style={{paddingRight: "6px"}}/>{'Major'}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <UpdateChangeRequests
                  categoryType={categoryType}
                  formik={formik}
                  position={positionDetails}
                  metaCustomFields={getCustomFields()}
                  basicAttributesCategory={basicAttributesCategory}
                  isBulkModify={isBulkModify || isBulkCreate}
                  preCreateUpdateDetails={preCreateUpdateDetails}
                />
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default ChangeRequests;
