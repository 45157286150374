export const styles = {
  positionHeader: {
    py: 4
  },
  growBox: {
    flexGrow: 1
  },
  dataWrappper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  flexCenterNone: {
    alignItems: 'center',
    display: 'flex'
  },
  mx: (n) => ({
    mx: n
  }),
  pageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  separatorLine: {
    borderWidth: 0,
    borderStyle: "solid",
    borderColor: '#E1E3EA',
    borderBottomWidth: "thin",
  }
}
