import {
  Grid,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { styles as positionStyles } from '../Positions/styles';
import { ChevronLeft as ChevronLeftIcon } from '../../assets/icons/chevron-left';
import { styles } from './styles';
import { useEffect, useState } from 'react';
import { TabContext, TabPanel } from '@mui/lab';
import PositionDetailsTab from '../../components/PositionDetailsTab';
import CustomAttributes from '../../components/CustomAttributes';
import { useNavigate } from 'react-router-dom';
import { api, formDataApi } from '../../axios';

import { useSelector } from 'react-redux';
import { useCustomAttributesFormik, usePersonalDetailsFormik } from '../AddPosition/formik';
import { useSnackbar } from 'notistack';

const views = [
  {
    label: 'Position Details',
    value: 'position-details',
  },
  {
    label: 'Custom Attributes',
    value: 'custom-attributes',
  },
];

const BulkModifyPositions = (props) => {
  const [view, setView] = useState('position-details');
  const [positionDetails, setPositionDetails] = useState({});
  const [preCreationDetails, setPreCreationDetails] = useState({ isLoading: true });
  const personalDetailsFormik = usePersonalDetailsFormik(
    preCreationDetails?.data?.position,
    (value) => handleBasicDetails(value),
    true
  );
  const { customAttributesFormik, metaCustomFields } = useCustomAttributesFormik(
    null,
    (value) => handleCustomDetails(value),
    true
  );

  const navigate = useNavigate();

  const { selectedPositions } = useSelector((state) => state.positions);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setPreCreationDetails({ ...preCreationDetails, isLoading: true });
    async function fetchPreCreationData() {
      const response = await api.post('/preCreatePosition');
      console.log('pre create api resp', response.data);
      setPreCreationDetails({ ...preCreationDetails, data: response.data, isLoading: false });
    }
    fetchPreCreationData();
  }, []);

  const handleBasicDetails = (details) => {
    setPositionDetails(details);
    setView('custom-attributes');
  };

  const handleCustomDetails = async (details) => {
    const { submit, ...filteredData } = {
      ...positionDetails,
      ...details,
    };
    const bodyFormData = new FormData();
    for (const key in filteredData) {
      bodyFormData.append(key, filteredData[key]);
    }

    enqueueSnackbar('No end points defined for bulk modify positions', { variant: 'info' });
    return;
    const response = await formDataApi.post('', bodyFormData);
    if (response.data) {
      navigate('/positions');
    } else {
    }
  };

  return (
    <Box sx={positionStyles.growBox}>
      <Container maxWidth='lg' sx={positionStyles.pageWrapper}>
        <Grid container direction='column' spacing={2}>
          <Grid sx={{ py: '32px !important' }} item>
            <Typography variant='h4'>Positions</Typography>
          </Grid>

          <Grid sx={{ pt: '0px !important' }} item>
            <Card {...props}>
              <CardContent>
                {preCreationDetails.isLoading ? (
                  <Box sx={{ py: 4 }}>
                    <Skeleton height={42} />
                    <Skeleton height={42} />
                    <Skeleton height={42} />
                  </Box>
                ) : (
                  <>
                    <Grid container direction='column' spacing={2}>
                      <Grid item>
                        <Button
                          startIcon={<ChevronLeftIcon />}
                          variant='text'
                          color='secondary'
                          onClick={() => navigate('/positions')}
                        >
                          Back
                        </Button>
                      </Grid>
                      <Grid item>
                        <Typography color='textPrimary' variant='h4'>
                          Bulk Modify {selectedPositions.length} positions
                        </Typography>
                      </Grid>
                      <Grid item>
                        <TabContext value={view}>
                          <Box>
                            <Tabs
                              value={view}
                              onChange={(event, value) => setView(value)}
                              aria-label='basic tabs example'
                            >
                              {views.map((option) => (
                                <Tab disabled={false} label={option.label} value={option.value} />
                              ))}
                            </Tabs>
                            <Divider sx={styles.divider} />
                          </Box>
                          <TabPanel sx={styles.tabpanel} value={'position-details'}>
                            <PositionDetailsTab
                              formik={personalDetailsFormik}
                              preCreationDetails={preCreationDetails?.data}
                              isBulkModify
                            />
                          </TabPanel>
                          <TabPanel sx={styles.tabpanel} value={'custom-attributes'}>
                            <CustomAttributes
                              isModify
                              formik={customAttributesFormik}
                              metaCustomFields={metaCustomFields}
                            />
                          </TabPanel>
                        </TabContext>
                      </Grid>
                    </Grid>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default BulkModifyPositions;
