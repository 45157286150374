import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  DialogContentText,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DropzoneArea } from 'react-mui-dropzone';
import { styles } from '../../components/Position/styles';
import { formDataApi, downloadFileApi } from '../../axios';
import FormData from 'form-data';
import { useSnackbar } from 'notistack';
import { Export as ExportIcon } from '../../assets/icons/export';

import { Box, Container } from '@mui/system';
import fileDownload from 'js-file-download';
import { useSelector } from 'react-redux';
import { hasPermission } from '../../utils/permission';

export const UploadPositionData = () => {
  const [files, setFiles] = useState(null);
  const [loading, setLoading] = useState(false);
  const [downloadingTemplate, setDownloadingTemplate] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasCompaExportPermisson = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_RECRUIT_INDEX_COMPA_EXPORT_POSITIONS_DATA',
  ]);
  const userHasCompaDownloadTemplatePermisson = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_RECRUIT_INDEX_COMPA_DOWNLOAD_POSITION_TEMPLATE',
  ]);

  const { enqueueSnackbar } = useSnackbar();

  const handleFiles = (files) => {
    setFiles(files[0]);
  };

  const downloadPositionData = async () => {
    try {
      const exportApiPath = userHasCompaExportPermisson ? '/exportPositionDataWithCompaFields' : '/exportPositionData';
      setDownloading(true);
      const response = await downloadFileApi.post(exportApiPath);

      fileDownload(new Blob([response.data]), 'positions.xlsx');
    } catch (error) {
      console.log('inside catche ', error);
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setDownloading(false);
    }
  };

  const validate = async () => {
    if (!files) {
      enqueueSnackbar('Please select a file', { variant: 'error' });
      return
    }
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('dataFile', files);
      formData.append('isAdmin', true);
      const response = await formDataApi.post(`/loadPositionDataAction`, formData);
      if (response.data?.status !== 'ERROR') {
        enqueueSnackbar(response.data?.message, { variant: 'success' });
      } else {
        enqueueSnackbar(response.data?.message, { variant: 'error' });
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const downloadSampleFile = async () => {
    const dowmloadApiPath = userHasCompaDownloadTemplatePermisson
      ? '/downloadPositionDataTemplateWithCompaFields'
      : '/downloadPositionDataTemplate';
    try {
      setDownloadingTemplate(true);
      const response = await downloadFileApi.post(dowmloadApiPath);

      fileDownload(new Blob([response.data]), 'Template-PositionData.xlsx');
    } catch (error) {
      console.log('inside catche ', error);
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setDownloadingTemplate(false);
    }
  };

  return (
    <Box sx={styles.growBox}>
      <Container maxWidth='lg' sx={styles.pageWrapper}>
        <Grid container direction='column' spacing={2}>
          <Grid
            sx={{ py: '32px !important' }}
            item
            container
            spacing={1}
            justifyContent='flex-start'
            alignItems='center'
            py={4}
          >
            <Grid item>
              <Typography variant='h4'>Bulk Upload Position Data</Typography>
            </Grid>
            <Grid item xs></Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Card>
            <CardContent>
              <Grid container justifyContent='center' alignItems='center' mt={1}>
                <Grid item xs={12}>
                  <Box display="flex" xs='auto'  mb={2} justifyContent="space-between">
                    <DialogContentText>
                      To download a template file 
                      <LoadingButton loading={downloadingTemplate} variant='text' color='primary' onClick={downloadSampleFile}>
                      Click here
                      </LoadingButton>
                    </DialogContentText>
                    <LoadingButton
                      loading={downloading}
                      color='secondary'
                      disabled={false}
                      onClick={downloadPositionData}
                      size='medium'
                      variant='text'
                      startIcon={<ExportIcon fontSize='small' />}
                    >
                      Export Positions
                    </LoadingButton>
                  </Box>
                  <DropzoneArea
                    dropzoneText='Drag and drop a file here or click'
                    filesLimit={1}
                    onChange={handleFiles}
                    showAlerts={false}
                    showPreviews={true}
                    showPreviewsInDropzone={false}
                    useChipsForPreview
                    previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                    previewText='Selected files'
                  />
                  <LoadingButton disabled={!files} sx={{ mt: 3 }} loading={loading} variant='contained' color='primary' onClick={validate}>
                    Upload
                  </LoadingButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Container>
    </Box>
  );
};
