import React, { } from 'react';
import { Grid, Skeleton } from '@mui/material';
import DynamicForm from '../DynamicForm';
import { Box } from '@mui/system';

const CustomAttributes = ({ formik, isModify, metaCustomFields = [] }) => {
  return (
    <Grid container spacing={4}>
      <Grid item md={12} xs={12}>
        {(!formik.values && metaCustomFields?.metaCustomFieldDefinitions?.length) ? (
          <Box sx={{ py: 4 }}>
            <Skeleton height={42} />
            <Skeleton />
            <Skeleton />
          </Box>
        ) : (
          <DynamicForm
            formik={formik}
            formFields={metaCustomFields?.metaCustomFieldDefinitions}
            secondaryButton={'Save as draft'}
            primaryButton={isModify ? 'Update Position' : 'Create Position'}
            handleSecondaryButtonClick={() => { }}
            handlePrimaryButtonClick={() => { }}
            isModify={isModify}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default CustomAttributes;
