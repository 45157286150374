export const styles = {
  statusButton: {
    backgroundColor: 'secondary.hover',
    height: '35px',
    width: '80px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.15px',
    color: '#3F455A',

    '&:focus': {
      boxShadow: 'none'
    },

    '&:hover': {
      backgroundColor: 'secondary.hover'
    }
  },
  filterButtons: {
    alignItems: 'center',
    pr: 2
  },
  filterMenu: {
    m: 1,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    border: 'none',
    boxShadow: 'none'
  },
  tableMenuDialog: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectButton: {
    with: "160px",
    alignItems: "center",
    background: "#FFF4F0",
    color: "#ED7342",
    border: "1px solid #ED7342",

    "&:hover": {
      background: "#FFF4F0",
      color: "#ED7342",
    },
  },

}
