import PropTypes from 'prop-types';

export const Logo = (props) => {
  const { emblemOnly, variant } = props;

  const color = variant === 'light' ? '#ffffff' : '#1D262D';

  return (
    <svg width="86" height="24" viewBox="0 0 86 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_8780_18367)">
        <path d="M13.8948 5.19499L13.6047 5.13883L14.533 0.645846L14.1946 0.580322L13.2663 5.0733L12.9665 5.01714L12.7441 6.11231L13.6724 6.29015L13.8948 5.19499Z" fill="#FF5400" />
        <path d="M14.3781 5.23236L13.9043 6.35561L14.7746 6.7113L15.258 5.57869L14.9776 5.47573L16.7471 1.32908L16.4377 1.2074L14.6585 5.34468L14.3781 5.23236Z" fill="#FF5400" />
        <path d="M15.8192 5.67244L15.0166 6.83312L15.7998 7.33858L16.6024 6.1779L16.3607 6.01877L18.8264 2.45247L18.546 2.26526L16.0706 5.83156L15.8192 5.67244Z" fill="#FF5400" />
        <path d="M17.1824 6.32766L15.9834 7.48834L16.6506 8.14357L17.8496 6.97352L17.6466 6.77696L20.6054 3.90332L20.3637 3.66931L17.4048 6.53359L17.1824 6.32766Z" fill="#FF5400" />
        <path d="M18.5077 7.25435L16.8252 8.34016L17.3474 9.10771L19.0395 8.0219L18.8655 7.77853L22.0854 5.70989L21.9017 5.42908L18.6818 7.50708L18.5077 7.25435Z" fill="#FF5400" />
        <path d="M19.6966 8.41498L17.4629 9.31357L17.8207 10.156L20.0543 9.26677L19.9383 8.99532L23.1486 7.71295L23.0229 7.40405L19.8126 8.68643L19.6966 8.41498Z" fill="#FF5400" />
        <path d="M20.7123 9.87517L17.8984 10.4087L18.0822 11.3073L20.896 10.7644L20.838 10.4836L23.8065 9.92197L23.7388 9.59436L20.78 10.1653L20.7123 9.87517Z" fill="#FF5400" />
        <path d="M21.8823 13.4509L18.0822 12.7114L17.8984 13.61L21.6986 14.3495L21.7566 14.0687L23.7292 14.4525L23.7969 14.1342L21.8243 13.7411L21.8823 13.4509Z" fill="#FF5400" />
        <path d="M21.8819 15.4633L17.8207 13.8346L17.4629 14.6864L21.5241 16.3151L21.6401 16.0436L23.0229 16.6053L23.1486 16.2964L21.7658 15.7348L21.8819 15.4633Z" fill="#FF5400" />
        <path d="M21.4084 17.5601L17.3375 14.9111L16.8057 15.6693L20.8862 18.3183L21.0506 18.0843L21.8725 18.6178L22.0562 18.337L21.2343 17.8035L21.4084 17.5601Z" fill="#FF5400" />
        <path d="M20.4411 19.5257L16.6506 15.8564L15.9834 16.5023L19.7739 20.1716L19.9866 19.9656L20.3637 20.3307L20.6054 20.0967L20.2283 19.7316L20.4411 19.5257Z" fill="#FF5400" />
        <path d="M15.7705 16.6709L14.9873 17.1764L18.1783 21.8097L18.42 21.6506L18.4974 21.7629L18.7874 21.5851L18.7101 21.4634L18.9615 21.3043L15.7705 16.6709Z" fill="#FF5400" />
        <path d="M14.7798 17.2789L13.9043 17.63L16.2171 23.035L17.0926 22.684L14.7798 17.2789Z" fill="#FF5400" />
        <path d="M13.6407 17.706L12.7109 17.8829L13.9122 23.7977L14.842 23.6207L13.6407 17.706Z" fill="#FF5400" />
        <path d="M11.5264 17.9157V18.9922V24H12.474V18.9922V17.9157V17.9064H11.5264V17.9157Z" fill="#FF5400" />
        <path d="M10.105 18.805L10.4047 18.8612L9.4668 23.3541L9.80523 23.4197L10.7335 18.9267L11.0333 18.9828L11.2557 17.8877L10.3371 17.7098L10.105 18.805Z" fill="#FF5400" />
        <path d="M9.62099 18.7675L10.0948 17.6443L9.22454 17.2886L8.74106 18.4212L9.02148 18.5335L7.25195 22.6708L7.56138 22.8018L9.34058 18.6552L9.62099 18.7675Z" fill="#FF5400" />
        <path d="M8.18008 18.3276L8.99233 17.167L8.19942 16.6615L7.39685 17.8222L7.64826 17.9813L5.17285 21.557L5.45327 21.7348L7.92867 18.1685L8.18008 18.3276Z" fill="#FF5400" />
        <path d="M6.81658 17.6724L8.0156 16.5117L7.3484 15.8564L6.14938 17.0265L6.36211 17.2324L3.39355 20.0967L3.63529 20.3307L6.60385 17.4664L6.81658 17.6724Z" fill="#FF5400" />
        <path d="M5.4918 16.7457L7.1743 15.6599L6.65214 14.8923L4.96964 15.9781L5.13402 16.2215L1.91406 18.2995L2.09778 18.571L5.31775 16.5023L5.4918 16.7457Z" fill="#FF5400" />
        <path d="M4.30262 15.585L6.53628 14.6864L6.17851 13.844L3.94484 14.7332L4.06088 15.0047L0.850586 16.2964L0.97629 16.6053L4.18658 15.3136L4.30262 15.585Z" fill="#FF5400" />
        <path d="M3.28762 14.1248L6.10146 13.5912L5.91774 12.6926L3.1039 13.2355L3.16191 13.5163L0.193359 14.078L0.261046 14.4056L3.2296 13.844L3.28762 14.1248Z" fill="#FF5400" />
        <path d="M2.53342 12.4587H5.88876V11.5414H2.53342V11.8316H0V12.1685H2.53342V12.4587Z" fill="#FF5400" />
        <path d="M2.1177 10.5492L5.91783 11.2886L6.10155 10.39L2.30142 9.65058L2.2434 9.93139L0.270812 9.54761L0.203125 9.87522L2.17571 10.259L2.1177 10.5492Z" fill="#FF5400" />
        <path d="M2.1173 8.53666L6.17851 10.1654L6.53628 9.31357L2.47507 7.68486L2.35904 7.95631L0.97629 7.40405L0.850586 7.71295L2.23333 8.26521L2.1173 8.53666Z" fill="#FF5400" />
        <path d="M2.59122 6.43997L6.6621 9.08895L7.19393 8.33076L3.12304 5.68177L2.94899 5.92514L2.13675 5.3916L1.94336 5.66305L2.7556 6.19659L2.59122 6.43997Z" fill="#FF5400" />
        <path d="M3.55794 4.4743L7.3484 8.14357L8.0156 7.49771L4.22514 3.82844L4.01241 4.03437L3.63529 3.66931L3.39355 3.90332L3.77067 4.26838L3.55794 4.4743Z" fill="#FF5400" />
        <path d="M8.22904 7.32915L9.01227 6.82369L5.83099 2.19031L5.57958 2.34943L5.50222 2.23711L5.21214 2.42432L5.28949 2.53664L5.03809 2.69577L8.22904 7.32915Z" fill="#FF5400" />
        <path d="M7.78956 0.955096L6.91406 1.30615L9.2269 6.71119L10.1024 6.36013L7.78956 0.955096Z" fill="#FF5400" />
        <path d="M10.0851 0.192098L9.15527 0.369141L10.3571 6.2839L11.2869 6.10685L10.0851 0.192098Z" fill="#FF5400" />
        <path d="M11.5264 5.0078V6.08425V6.09361H12.474V6.08425V5.0078V0H11.5264V5.0078Z" fill="#FF5400" />
        <path d="M21.4667 11.8316V11.5414H18.1113V12.4587H21.4667V12.1685H24.0001V11.8316H21.4667Z" fill="#FF5400" />
        <path d="M34.1269 20.8483C34.1269 20.4375 33.849 20.1475 33.4141 20.1475H32.5321V21.5128H33.4141C33.8611 21.5128 34.1269 21.247 34.1269 20.8483ZM32.5321 19.7368H33.3899C33.8128 19.7368 34.0423 19.483 34.0423 19.0964C34.0423 18.7098 33.7886 18.4802 33.4141 18.4802H32.5442V19.7368H32.5321ZM34.6343 20.8724C34.6343 21.537 34.1994 21.9236 33.4624 21.9236H32.0488V18.0695H33.4503C34.1873 18.0695 34.5377 18.4561 34.5377 19.0239C34.5377 19.5193 34.284 19.7488 33.994 19.8817V19.9059C34.2961 20.0267 34.6343 20.2925 34.6343 20.8724Z" fill="black" />
        <path d="M37.8961 21.4765V21.9236H35.4072V18.0695H37.8478V18.5165H35.9026V19.7488H37.6182V20.1838H35.9026V21.4765H37.8961Z" fill="black" />
        <path d="M41.1217 18.5286H40.0102V21.9236H39.5149V18.5286H38.4033V18.0695H41.1217V18.5286Z" fill="black" />
        <path d="M44.36 18.5286H43.2485V21.9236H42.7531V18.5286H41.6416V18.0695H44.36V18.5286Z" fill="black" />
        <path d="M47.4889 21.4765V21.9236H45V18.0695H47.4285V18.5165H45.4954V19.7488H47.1989V20.1838H45.4954V21.4765H47.4889Z" fill="black" />
        <path d="M48.6975 19.8697H49.507C49.9903 19.8697 50.2802 19.5918 50.2802 19.181C50.2802 18.746 49.9782 18.5044 49.507 18.5044H48.6975V19.8697ZM50.389 21.9236L49.4466 20.3046H48.6975V21.9236H48.2021V18.0695H49.5674C50.3165 18.0695 50.7635 18.5044 50.7635 19.1568C50.7635 19.7126 50.4373 20.0871 49.9299 20.22L50.9327 21.9236H50.389Z" fill="black" />
        <path d="M56.3091 20.0027C56.3091 19.1448 55.705 18.4803 54.9197 18.4803C54.1223 18.4803 53.5182 19.1448 53.5182 20.0027C53.5182 20.8605 54.1223 21.525 54.9197 21.525C55.705 21.5129 56.3091 20.8605 56.3091 20.0027ZM53.0107 20.0027C53.0107 18.879 53.8202 18.0212 54.9197 18.0212C55.995 18.0212 56.8165 18.879 56.8165 20.0027C56.8165 21.1263 56.007 21.9841 54.9197 21.9841C53.8202 21.9841 53.0107 21.1384 53.0107 20.0027Z" fill="black" />
        <path d="M60.5985 20.4737C60.5985 21.4645 59.9823 21.984 59.1124 21.984C58.2425 21.984 57.6143 21.4765 57.6143 20.4737V18.0695H58.1096V20.4375C58.1096 21.1624 58.5446 21.5128 59.1124 21.5128C59.6803 21.5128 60.1031 21.1503 60.1031 20.4375V18.0695H60.5985V20.4737Z" fill="black" />
        <path d="M63.9567 18.5286H62.8452V21.9236H62.3498V18.5286H61.2383V18.0695H63.9567V18.5286Z" fill="black" />
        <path d="M66.0949 21.9841C64.9833 21.9841 64.2705 21.1625 64.2705 20.0147C64.2705 18.867 64.9954 18.0212 66.0949 18.0212C66.9164 18.0212 67.5568 18.4803 67.7018 19.2777H67.1702C67.0373 18.7945 66.6627 18.4803 66.107 18.4803C65.2975 18.4803 64.7779 19.169 64.7779 20.0147C64.7779 20.8846 65.3216 21.5129 66.0949 21.5129C66.6748 21.5129 67.0856 21.1988 67.2306 20.7517H67.7622C67.6172 21.5008 66.9527 21.9841 66.0949 21.9841Z" fill="black" />
        <path d="M71.5806 20.0027C71.5806 19.1448 70.9765 18.4803 70.1912 18.4803C69.3938 18.4803 68.7897 19.1448 68.7897 20.0027C68.7897 20.8605 69.3938 21.525 70.1912 21.525C70.9886 21.5129 71.5806 20.8605 71.5806 20.0027ZM68.2822 20.0027C68.2822 18.879 69.0917 18.0212 70.1912 18.0212C71.2664 18.0212 72.088 18.879 72.088 20.0027C72.088 21.1263 71.2785 21.9841 70.1912 21.9841C69.1038 21.9841 68.2822 21.1384 68.2822 20.0027Z" fill="black" />
        <path d="M76.7875 21.9236H76.2921V18.9635H76.2559L75.1202 21.4886H74.5886L73.4408 18.9635H73.4045V21.9236H72.9092V18.0695H73.5495L74.8302 20.957H74.8664L76.1471 18.0695H76.7875V21.9236Z" fill="black" />
        <path d="M80.2672 21.4765V21.9236H77.7783V18.0695H80.2068V18.5165H78.2616V19.7488H79.9772V20.1838H78.2616V21.4765H80.2672Z" fill="black" />
        <path d="M82.1038 21.9841C81.2943 21.9841 80.811 21.5491 80.7627 20.8242H81.2581C81.3185 21.2833 81.6205 21.5491 82.1159 21.5491C82.5146 21.5491 82.8529 21.3679 82.8529 20.933C82.8529 20.4376 82.4179 20.2926 82.0434 20.1839L81.8501 20.1235C81.4393 19.9906 80.8835 19.761 80.8835 19.0844C80.8835 18.432 81.4151 18.0212 82.1159 18.0212C82.8529 18.0212 83.324 18.4804 83.3603 19.1086H82.8649C82.8287 18.7462 82.5871 18.4562 82.1159 18.4562C81.6809 18.4562 81.3789 18.7099 81.3789 19.0724C81.3789 19.5073 81.8138 19.6281 82.14 19.7369L82.3092 19.7973C82.6354 19.906 83.3482 20.1356 83.3482 20.933C83.3603 21.6458 82.7804 21.9841 82.1038 21.9841Z" fill="black" />
        <path d="M83.4447 5.67372C83.1669 3.77687 81.6083 2.4237 79.6873 2.41162C77.2709 2.41162 74.8425 2.41162 72.4261 2.41162C72.3536 2.41162 72.2932 2.4237 72.2207 2.4237V4.79175C72.2207 4.80383 72.2207 4.81591 72.2207 4.81591C72.2207 6.24157 72.7402 7.28061 73.9605 7.486C73.9605 6.44696 73.9605 5.42 73.9605 4.38096V4.16349C74.0451 4.16349 74.1055 4.15141 74.1659 4.15141C76.0023 4.15141 77.8267 4.15141 79.6631 4.15141C80.6901 4.15141 81.4996 4.79175 81.705 5.73413C81.9587 6.94232 81.1009 8.07801 79.8564 8.1505C79.5302 8.17466 79.204 8.16258 78.8778 8.16258C77.3192 8.16258 75.7486 8.16258 74.19 8.16258C73.5255 8.16258 72.2207 8.24716 72.2207 10.0715V15.3392H73.9605V15.0976C73.9605 13.4424 73.9605 11.7871 73.9605 10.1319C73.9605 9.96278 74.0088 9.90237 74.178 9.90237C75.2774 9.91445 76.3769 9.91445 77.4763 9.90237C77.6092 9.90237 77.6817 9.95069 77.7663 10.0594C78.902 11.7509 80.0497 13.4303 81.1854 15.1217C81.27 15.2546 81.3667 15.303 81.5237 15.303C82.0432 15.2909 82.5628 15.2909 83.0823 15.2909C83.1427 15.2909 83.2152 15.2788 83.3118 15.2788C82.1037 13.4786 80.9076 11.7026 79.7235 9.93861C80.086 9.8782 80.4122 9.84196 80.7263 9.75739C82.5024 9.31036 83.7105 7.486 83.4447 5.67372Z" fill="black" />
        <path d="M84.967 14.1671H84.8583C84.7616 14.1671 84.665 14.1671 84.5683 14.1671V13.8772H84.6891C84.7979 13.8772 84.9066 13.8772 85.0153 13.8772C85.0878 13.8772 85.1482 13.9255 85.1603 13.9859C85.1724 14.0221 85.1603 14.0705 85.1362 14.1067C85.112 14.1429 85.0758 14.155 85.0274 14.1671C85.0033 14.1671 84.9912 14.1671 84.967 14.1671ZM85.0999 14.3483C85.257 14.3121 85.3657 14.1429 85.3415 13.9859C85.3174 13.8167 85.1845 13.6959 85.0153 13.6959C84.8704 13.6959 84.7133 13.6959 84.5683 13.6959H84.3992H84.375V14.8074H84.5683V14.3604C84.6529 14.3604 84.7495 14.3604 84.8341 14.3604C84.8341 14.3604 84.8341 14.3604 84.8462 14.3604L84.9791 14.5658C85.0274 14.6383 85.0757 14.7108 85.1241 14.7833C85.1362 14.7954 85.1482 14.8074 85.1724 14.8074C85.2086 14.8074 85.2328 14.8074 85.2691 14.8074H85.3053H85.3174H85.3657L85.0637 14.3604C85.0757 14.3483 85.0878 14.3483 85.0999 14.3483Z" fill="black" />
        <path d="M84.8579 15.1821C84.3504 15.1821 83.9276 14.7592 83.9276 14.2518C83.9276 13.7444 84.3384 13.3215 84.8579 13.3215C85.3653 13.3215 85.7882 13.7323 85.7882 14.2518C85.7761 14.7592 85.3653 15.1821 84.8579 15.1821ZM84.8579 13.1644C84.2538 13.1644 83.7705 13.6598 83.7705 14.2518C83.7705 14.8559 84.2659 15.3392 84.8579 15.3392C85.4499 15.3392 85.9452 14.8438 85.9452 14.2518C85.9452 13.6477 85.4499 13.1644 84.8579 13.1644Z" fill="black" />
        <path d="M67.7984 8.50071C66.8802 8.15033 65.9136 8.02951 64.9471 7.90869C64.1618 7.81204 63.3764 7.7033 62.6032 7.5704C62.0354 7.47375 61.5642 7.19587 61.2017 6.74884C60.7788 6.22932 60.7547 5.62522 61.1775 5.11779C61.3829 4.87615 61.6367 4.63451 61.9145 4.48953C63.6785 3.54715 65.4666 3.53506 67.2668 4.40496C67.883 4.707 68.3783 5.14195 68.4629 5.89102C68.4629 5.90311 68.475 5.91519 68.4871 5.91519H70.2027C70.2027 5.33526 70.0456 4.82782 69.7557 4.35663C69.2241 3.48674 68.4267 2.93097 67.4964 2.55644C66.119 2.00067 64.6934 1.89193 63.2435 2.14565C62.1199 2.33896 61.1051 2.77391 60.2472 3.54715C58.9062 4.76741 58.7612 6.59177 59.8848 7.93286C60.5734 8.74234 61.4554 9.18937 62.4824 9.35852C63.4368 9.51558 64.4034 9.62432 65.3699 9.7693C66.0707 9.86596 66.7594 9.98677 67.4118 10.2647C68.5112 10.7238 68.7891 12.2582 67.9555 12.9831C67.7863 13.1281 67.6172 13.2851 67.4118 13.3939C65.7324 14.2879 64.0168 14.3483 62.2891 13.5992C61.5762 13.2851 60.9601 12.8502 60.8513 11.9803H59.1357C59.0874 12.3911 59.2686 13.0435 59.5586 13.5268C60.0902 14.3966 60.8876 14.9403 61.8179 15.327C63.0744 15.8465 64.3792 15.9552 65.7203 15.7861C66.8198 15.6532 67.8467 15.2907 68.7408 14.6141C70.0577 13.6234 70.5168 12.1615 69.9731 10.6029C69.6228 9.5035 68.8133 8.88733 67.7984 8.50071Z" fill="black" />
        <path d="M38.4155 2.43567H38.0047H36.5307L35.093 6.44684C34.6338 7.86042 35.0205 9.0082 36.0716 9.1411L38.2222 3.02768L40.5177 9.6002H35.8662C35.4917 9.61229 33.8969 9.7331 33.329 11.485L33.3048 11.5575C33.3048 11.5695 33.2928 11.5695 33.2928 11.5816L32 15.3512H33.8969L35.3104 11.3521H41.1097L42.5112 15.3512H44.4081L39.8894 2.43567H38.4155Z" fill="black" />
        <path d="M55.3658 14.7954L49.1557 2.43567H47.5489H47.2347H45.7607V15.3874H47.5489V3.05184L53.7468 15.3874H55.3658H55.6679H57.1418V2.43567H55.3658V14.7954Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_8780_18367">
          <rect width="85.9455" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};

Logo.defaultProps = {
  emblemOnly: false,
  variant: 'dark'
};

Logo.propTypes = {
  emblemOnly: PropTypes.bool,
  variant: PropTypes.oneOf(['light', 'dark'])
};
