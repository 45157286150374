import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Card, CardContent, FormHelperText, Grid, Typography } from '@mui/material';
import { InputField } from '../../../components/InputField';
import { formDataApi } from '../../../axios';
import ChangePassword from './changePassword';
import { Done } from '@mui/icons-material';
import { styles } from '../../../components/UserDetailsTab/styles';
import { useSnackbar } from 'notistack';
import { utilStyles } from '../../../utils/styles';
import { ChevronLeft as ChevronLeftIcon } from '../../../assets/icons/chevron-left';
import { useNavigate } from 'react-router-dom';
import keyBy from 'lodash/keyBy';
import { updateName } from '../../../store/slices/session';
import { useDispatch } from 'react-redux';

const EditOverview = ({ user, email, fetchUserData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { authorityList = [], roleIDBasedMap = {} } = user || {};
  const [selectedRoles, setSelectedRoles] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const selectedRoleValues = Object.keys(roleIDBasedMap).filter((item) => roleIDBasedMap[item] === true);
    setSelectedRoles(selectedRoleValues.map(Number));
  }, [user]);

  const [avatarPreview, setAvatarPreview] = useState('/avatars/default.png');
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: user?.secUserInstance?.employee?.firstName,
      lastName: user?.secUserInstance?.employee?.lastName,
      email: user?.employeeInstance?.emailAddresses[0] || '',
      mobile: user?.employeeInstance?.mobileNumbers[0] || '',
      avatar: null,
      companyName: user?.company?.companyName,
      passwordExpired: String(user?.secUserInstance?.passwordExpired),
      accountEnabled: String(user?.secUserInstance?.enabled),
      // companies: user.companiesList,
      submit: null,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Must be a valid email').max(255),
    }),
    onSubmit: async (values, helpers) => {
      const bodyFormData = new FormData();
      const authorityListObject = keyBy(authorityList, 'id');

      const data = {
        id: Number(user?.secUserInstance?.id),
        'employee.firstName': values.firstName,
        'employee.lastName': values.lastName,
        'employee.mobileNumbers[0]': values.mobile,
        'employee.emailAddresses[0]': values.email,
        username: user?.secUserInstance?.username,
        password: user?.secUserInstance?.password,
        enabled: user?.secUserInstance?.enabled ? 'on' : 'off',
        passwordExpired: user?.secUserInstance?.passwordExpired ? 'on' : 'off',
      };

      Object.keys(data).forEach((key) => {
        if (data[key] !== '' || data[key]) {
          bodyFormData.append(key, data[key]);
        }
      });

      selectedRoles.forEach((selectedRole) => {
        bodyFormData.append(authorityListObject[selectedRole]['authority'], 'on');
      });
      try {
        const response = await formDataApi.post('modifyUserAction', bodyFormData);
        if (response?.data?.message) {
          fetchUserData();
          dispatch(updateName({ firstName: values.firstName, lastName: values.lastName }));
          enqueueSnackbar(response?.data?.message, { variant: 'success' });
        }
      } catch (err) {
        enqueueSnackbar('Something went wrong! Account Data could not be updated', { variant: 'error' });
      }
      try {
        helpers.resetForm();
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });
  return (
    <>
      <Card>
        <CardContent>
          <Box sx={utilStyles.pb(2)}>
            <Button onClick={() => navigate(-1)} sx={styles.backButton} startIcon={<ChevronLeftIcon />} variant='text'>
              Back
            </Button>
          </Box>
          <Grid container spacing={4}>
            <Grid item md={5} xs={12}>
              <Typography color='textPrimary' variant='h6'>
                Basic Details
              </Typography>
            </Grid>
            <Grid item md={7} xs={12}>
              <form onSubmit={formik.handleSubmit}>
                <div>
                  {/* <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      pb: 3
                    }}
                  >
                    <Avatar
                      src={avatarPreview || "/static/user-chen_simmons.png"}
                      sx={{
                        height: 64,
                        mr: 2,
                        width: 64
                      }}
                    />
                    <div>
                      <Grid
                        container
                        spacing={1}
                        sx={{ pb: 1 }}
                      >
                        <Grid item>
                          <Button
                            variant='contained'
                            component='label'
                            startIcon={<UploadFile />}>
                            Upload new picture
                            <InputField
                              error={Boolean(formik.touched.avatar && formik.errors.avatar)}
                              fullWidth
                              helperText={formik.touched.avatar && formik.errors.avatar}
                              name="avatar"
                              placeholder={formik?.values?.avatar?.name}
                              onBlur={formik.handleBlur}
                              type="file"
                              sx={{
                                position: 'absolute',
                                opacity: 0,
                              }}
                              InputLabelProps={{
                                shrink: 'true'
                              }}
                              onChange={(e) => {
                                const fileReader = new FileReader();
                                fileReader.onload = () => {
                                  if (fileReader.readyState === 2) {
                                    setAvatarPreview(fileReader.result);
                                    formik.setFieldValue('avatar', fileReader.result);
                                  }
                                };
                                fileReader.readAsDataURL(e.target.files[0]);
                              }}
                            />
                          </Button>

                        </Grid>
                        <Grid item>
                          <Button
                            onClick={() => {
                              formik.setFieldValue('avatar', null);
                              setAvatarPreview(null);
                            }}
                            color="primary"
                            size="small"
                            type="button"
                            variant="text"
                          >
                            Delete
                          </Button>
                        </Grid>
                      </Grid>
                      <Typography
                        color="textSecondary"
                        variant="caption"
                      >
                        Recommended dimensions: 200x200, maximum file size: 5MB
                      </Typography>
                    </div>
                  </Box> */}
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <InputField
                        error={Boolean(formik.touched.firstName && formik.errors.firstName)}
                        fullWidth
                        helperText={formik.touched.firstName && formik.errors.firstName}
                        label='First Name'
                        name='firstName'
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputField
                        error={Boolean(formik.touched.lastName && formik.errors.lastName)}
                        fullWidth
                        helperText={formik.touched.lastName && formik.errors.lastName}
                        label='Last Name'
                        name='lastName'
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                      />
                    </Grid>
                    {/* <Grid
                      item
                      xs={6}
                    >
                      <InputField
                        disabled
                        error={Boolean(formik.touched.email && formik.errors.email)}
                        fullWidth
                        helperText={formik.touched.email && formik.errors.email}
                        label="Email address"
                        name="email"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="email"
                        value={formik.values.email}
                      />
                    </Grid> */}
                    <Grid item xs={6}>
                      <InputField
                        error={Boolean(formik.touched.mobile && formik.errors.mobile)}
                        fullWidth
                        helperText={formik.touched.mobile && formik.errors.mobile}
                        label='Mobile'
                        name='mobile'
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.mobile}
                      />
                    </Grid>
                    {/* <Grid
                      item
                      xs={12}
                    >
                      <InputField
                        error={Boolean(formik.touched.passwordExpired && formik.errors.passwordExpired)}
                        fullWidth
                        helperText={formik.touched.passwordExpired && formik.errors.passwordExpired}
                        label="Password Expired"
                        name="passwordExpired"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        select
                        value={formik.values.passwordExpired}
                      >
                        <MenuItem
                          key={'true'}
                          value={'true'}
                        >
                          True
                        </MenuItem>
                        <MenuItem
                          key={'false'}
                          value={'false'}
                        >
                          False
                        </MenuItem>
                      </InputField>
                    </Grid> */}
                    {/* <Grid
                      item
                      xs={12}
                    >
                      <InputField
                        error={Boolean(formik.touched.accountEnabled && formik.errors.accountEnabled)}
                        fullWidth
                        helperText={formik.touched.accountEnabled && formik.errors.accountEnabled}
                        label="Account enabled"
                        name="accountEnabled"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        select
                        value={formik.values.accountEnabled}
                      >
                        <MenuItem
                          key={'true'}
                          value={'true'}
                        >
                          True
                        </MenuItem>
                        <MenuItem
                          key={'false'}
                          value={'false'}
                        >
                          False
                        </MenuItem>
                      </InputField>
                    </Grid> */}
                    {/* <Grid item xs={12} sx={styles.dividerWrapper}>
                      <Divider item flexItem orientation='horizontal' sx={styles.divider} />{' '}
                    </Grid> */}
                    {/* <FormikProvider value={formik}>
                      <FieldArray
                        name="companies"
                        render={arrayHelpers => (
                          <>
                            {(formik.values.companies && formik.values.companies.length > 0) &&
                              <>
                                {formik.values.companies.map((hqAddress, index) => {
                                  return <>
                                    {index !== 0 && (
                                      <><Grid xs={10}>
                                      </Grid>
                                        <Grid xs={1}>
                                          <Button
                                            color="secondary"
                                            disabled={false}
                                            onClick={() => arrayHelpers.remove(index)}
                                            startIcon={<Delete />}
                                            size="large"
                                            sx={{
                                              mt: 2,
                                              mr: 1
                                            }}
                                            variant={"text"}
                                          >
                                            Remove
                                          </Button>
                                        </Grid>
                                      </>)}

                                    <Grid item xs={6}>
                                      <InputField
                                        fullWidth
                                        helperText={formik.touched.companies && formik.errors.companies &&
                                          formik.touched.companies[index].companyName &&
                                          formik.errors.companies[index].companyName}
                                        label='Company name'
                                        onBlur={() => formik.setFieldTouched(`companies.${index}.companyName`, true)}
                                        name={`companies.${index}.companyName`}
                                        value={formik.values.companies[index].companyName}
                                        onChange={(event) => {
                                          formik.setFieldValue(
                                            `companies.${index}.companyName`, event.target.value,
                                          )
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <InputField
                                        fullWidth
                                        helperText={formik.touched.companies && formik.errors.companies &&
                                          formik.touched.companies[index].role &&
                                          formik.errors.companies[index].role}
                                        label='Role'
                                        onBlur={() => formik.setFieldTouched(`companies.${index}.role`, true)}
                                        name={`companies.${index}.role`}
                                        value={formik.values.companies[index].role}
                                        onChange={(event) => {
                                          formik.setFieldValue(
                                            `companies.${index}.role`, event.target.value,
                                          )
                                        }}
                                      />
                                    </Grid>
                                    {formik.values.companies?.length > 1 &&
                                      <Grid item xs={12} sx={styles.dividerWrapper}>
                                        <Divider item flexItem orientation='horizontal' sx={styles.divider} />
                                      </Grid>}
                                  </>
                                })}
                                <Grid item xs={12} sx={styles.dividerWrapper}>
                                  <Button
                                    color="primary"
                                    disabled={false}
                                    onClick={() => arrayHelpers.push({ companyName: "", role: "" })}
                                    startIcon={<Add />}
                                    size="large"
                                    sx={styles.filterButton}
                                    variant={"text"}
                                  >
                                    Add Company
                                  </Button>
                                </Grid>
                              </>
                            }
                          </>
                        )}
                      />
                    </FormikProvider> */}
                    {formik.errors.submit && (
                      <Grid item xs={12}>
                        <FormHelperText error>{formik.errors.submit}</FormHelperText>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Button disabled={!formik.dirty} color='primary' size='large' type='submit' variant='contained' startIcon={<Done />}>
                        Save Details
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <ChangePassword user={user} fetchUserData={fetchUserData} />
    </>
  );
};

export default EditOverview;
