export const styles = {
  dividerWrapper: {
    width: "100%",
  },
  divider: {
    borderColor: "#C1C4CC !important",
    marginBottom: "24px"
  },
  checkbox: {
    display: "flex",
    alignItems: "center",
  },
  checkboxWrapper: {
    display: "flex",
  },
  typography: {
    fontSize: 14
  },
  header: {
    marginBottom: '20px'
  },
  headerWrapper: {
    display: 'flex',
    alignItems: "baseline",
  },
  trashIcon: {
    color: '#7C85A2'
  },
  rolesContainer: {
    width: '100%',
    display: 'flex',
    flexFlow: 'wrap',
    gap: '24px',
  },
  singleRolesWrapper: {
    display: 'flex',
    width: '100%',
    marginLeft: '48px',
    gap: '48px'
  },
  input: {
    height: '48px',
    fontSize: '12px',
    fontWeight: '450',
    color: 'black'
  },
};
