import React, { useState } from 'react';
import { Box, Button, IconButton, Menu, MenuList, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import ListIcon from '@mui/icons-material/List';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { usePopover } from '../../hooks/use-popover';
import { DotsVertical as DotsVerticalIcon } from '../../assets/icons/dots-vertical';
import { styles } from './styles';
import { useNavigate } from 'react-router-dom';
import PermissionChecker from '../PermissionChecker';

export const CompanyTableMenu = ({ row, column, route, deleteAction }) => {
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const [openDialog, setOPenDialog] = useState(false);
  const [action, setAction] = useState('');

  const navigate = useNavigate();

  const handleDialogClose = () => {
    setOPenDialog(false);
  };

  const handleDelete = () => {
    handleClose();
    setOPenDialog(false);
    deleteAction(row.id);
  };

  const actionItems = [
    {
      label: 'Modify',
      type: 'modify_company',
      icon: <EditIcon />,
      permissions: ['ROLE_BASE_PERMISSION_SYSTEM_INDEX_UPDATE_COMPANY'],
    },
    {
      label: 'User List',
      type: 'company_user_list',
      icon: <ListIcon />,
    },
    {
      label: 'Configuration',
      type: 'company_configuration',
      icon: <SettingsSuggestIcon />,
    },
    // {
    //   label: 'Delete',
    //   type: 'delete',
    //   icon: <Delete />,
    //   onClick: handleDelete,
    //   permissions: ['ROLE_BASE_PERMISSION_SYSTEM_INDEX_DELETE_COMPANY'],
    // },
  ];

  const handleMenuItemClick = (action) => {
    if (action.type === 'modify_company') {
      navigate(`/company/edit/${row.id}`);
      return;
    }
    if (action.type === 'company_user_list') {
      navigate(`/company/${row.id}/users`);
      return;
    }
    if (action.type === 'company_configuration') {
      navigate(`/company/${row.id}/config`);
      return;
    }
    setOPenDialog(true);

    setAction(() => {
      switch (action.type) {
        case 'delete':
          return {
            title: 'Delete Company?',
            confirmation: `Are you sure you want to delete this Company?`,
            callback: action.callback,
            ...action,
          };
        default:
          return action;
      }
    });
  };

  const renderDialogContent = (action) => (
    <DialogContent sx={styles.tableMenuDialog}>
      <Box sx={styles.tableMenuDialog}>
        <DialogTitle>{action.title}</DialogTitle>
        {action.confirmation && <DialogContentText>{action.confirmation}</DialogContentText>}
      </Box>
      {action.children}
      <DialogActions>
        <Button size='large' onClick={handleDialogClose}>
          Cancel
        </Button>
        <Button size='large' variant='contained' onClick={action.callback}>
          Delete
        </Button>
      </DialogActions>
    </DialogContent>
  );

  const renderMenuActions = () =>
    actionItems.map((action) => (
      <PermissionChecker requiredPermissions={action.permissions} noAuthRedirect={false}>
        <MenuItem
          key={action.type}
          onClick={() =>
            handleMenuItemClick({
              label: action.label,
              type: action.type,
              callback: action.onClick,
            })
          }
        >
          <ListItemIcon>{action.icon}</ListItemIcon>
          <ListItemText variant='body2'>{action.label}</ListItemText>
        </MenuItem>
      </PermissionChecker>
    ));

  return (
    <>
      <IconButton onClick={handleOpen} ref={anchorRef} color='primary'>
        <DotsVerticalIcon fontSize='small' />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList dense>{renderMenuActions()}</MenuList>
      </Menu>
      <Dialog open={openDialog} onClose={() => setOPenDialog(false)}>
        {renderDialogContent(action)}
      </Dialog>
    </>
  );
};
