import { Grid, MenuItem, Divider, Typography } from '@mui/material';
import { styles } from './styles';
import React, {useMemo} from 'react';
import { useSelector } from 'react-redux';
import { hasPermission } from '../../utils/permission';
import { InputField } from '../InputField';

const UserRoles = (props) => {
  const { roles, companies, selectedRoles, setSelectedRoles, selectedCompany, setSelectedCompany } = props;
  const authorities = useSelector((state) => state.session.user.authorities);
  const isSuperAdminUser = hasPermission(authorities, ['ROLE_SUPER_ADMINISTRATOR']);
  const isAdminUser = hasPermission(authorities, ['ROLE_ADMINISTRATOR']);

  const onSelect = (evnt) => {
    let { value } = evnt.target;
    setSelectedRoles([value]);
  };

  const userRoles = useMemo(() => {
    return isAdminUser ? roles : roles.filter(item => !item.authority.startsWith("ROLE_ADMINISTRATOR_N"));
  },[isAdminUser])

  return (
    <>
      <Grid item xs={12} sx={styles.headerWrapper}>
        <Grid item xs={8.27}>
          <Typography sx={styles.header}>User Roles (Required)</Typography>
        </Grid>
      </Grid>

      <Grid sx={styles.rolesContainer}>
        <Grid sx={styles.singleRolesWrapper} item>
          {isSuperAdminUser ? (
            <Grid item xs={4}>
              <InputField
                fullWidth
                label='Company'
                name='customer'
                disabled={true}
                value={selectedCompany}
                onChange={(event) => setSelectedCompany(event.target.value)}
                select
              >
                {companies.map((company) => (
                  <MenuItem key={company.id} value={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </InputField>
            </Grid>
          ) : null}
          <Grid item xs={4}>
            <InputField
              fullWidth
              label='Role'
              name='role'
              select
              SelectProps={{
                multiple: false,
                value: selectedRoles,
                onChange: onSelect,
              }}
            >
              {userRoles.map((role) => (
                <MenuItem key={role.id} value={role.id}>
                  {role.displayName}
                </MenuItem>
              ))}
            </InputField>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={styles.dividerWrapper}>
        <Divider flexItem orientation='horizontal' sx={styles.divider} />
      </Grid>
    </>
  );
};

export default React.memo(UserRoles);
