import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

export const usePositionStatus = () => {
  const [disableStatusMenuItem, setDisableStatusMenuItem] = useState(false);
  const [positionStatus, setPositionStatus] = useState('');
  const { positions, selectedPositions } = useSelector((state) => state.positions);

  useEffect(() => {
    let currentStatus,
      prevStatus,
      index = 0;
    positions.data.every((position) => {
      if (selectedPositions.indexOf(position.id) !== -1) {
        currentStatus = position.status;
        if (index === 0) {
          prevStatus = currentStatus;
        }
        if (prevStatus !== currentStatus) {
          setDisableStatusMenuItem(true);
          return false;
        } else {
          setDisableStatusMenuItem(false);
        }
        prevStatus = currentStatus;
        index++;
      }
      return true;
    });
    setPositionStatus(currentStatus);
  }, [positions, selectedPositions]);

  return [disableStatusMenuItem, positionStatus];
};
