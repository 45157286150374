import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { Edit } from '@mui/icons-material';
import { toast } from 'react-hot-toast';

import { utilStyles } from '../../utils/styles';
import { getFullName } from '../../utils/get-initials';
import { formDataApi } from '../../axios';
import { styles as positionStyles } from '../Positions/styles';
import { styles } from './styles';
import Overview from './Overview';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const Account = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();
  const user = useSelector((state) => state.session.user);
  const [userDisplayData, setUserDisplayData] = useState({ isLoading: true });
  const fullName = useCallback(() => {
    return getFullName(user?.firstName, user?.middleName, user?.lastName);
  }, [user?.firstName, user?.middleName, user?.lastName]);
  const [modifiedData, setModifiedData] = useState({ isLoading: true });

  const location = useLocation();

  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (id && location.pathname.includes('/dashboard/account/update')) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  }, [id]);

  //   useEffect(() => {
  //   if (user.userDBId && isEditMode) {
  //     const apiPath = '/modifyUserAction'
  //     setModifiedData({ ...modifiedData, isLoading: true })
  //     async function fetchData() {
  //       const bodyFormData = new FormData();
  //       bodyFormData.append('id', Number(user?.userDBId));

  //       bodyFormData.append('username', "sklAdmin");

  //       try {
  //         const response = await formDataApi.post(apiPath, bodyFormData);
  //         if (response?.data) {
  //           setUserDisplayData(response?.data)
  //         }
  //       } catch (err) {
  //         setUserDisplayData({ isLoading: false })
  //         toast.error('Something went wrong! Account Data could not be fetched.');
  //       }
  //     }
  //     fetchData();
  //   }
  // }, [user.userDBId, isEditMode])

  const fetchUserData = async () => {
    if (user.userDBId) {
      const apiPath = isEditMode ? '/modifyUser' : '/displayUser';
      setUserDisplayData({ ...userDisplayData, isLoading: true });
      const bodyFormData = new FormData();
      bodyFormData.append('id', Number(user?.userDBId));
      try {
        const response = await formDataApi.post(apiPath, bodyFormData);
        if (response?.data) {
          setUserDisplayData(response?.data);
        }
      } catch (err) {
        setUserDisplayData({ isLoading: false });
        enqueueSnackbar('Something went wrong! Account Data could not be fetched', { variant: 'error' });
      }
    }
  }

  useEffect(() => {
    async function fetchUser() {
      await fetchUserData()
    }
    fetchUser();
  }, [user.userDBId, isEditMode]);

  return (
    <Box sx={positionStyles.growBox}>
      <Container maxWidth='lg' sx={positionStyles.pageWrapper}>
        <Box id='positionHeader' sx={positionStyles.positionHeader}>
          <Box sx={positionStyles.flexCenterNone}>
            <Typography variant='h4'>{`${userDisplayData?.secUserInstance?.employee?.firstName} ${userDisplayData?.secUserInstance?.employee?.lastName}`}</Typography>
            <Box sx={positionStyles.growBox} />
            {!isEditMode && (
              <Button
                sx={utilStyles.mx(2)}
                color='primary'
                onClick={() => navigate(`/dashboard/account/update/${user.userDBId}`)}
                size='large'
                startIcon={<Edit fontSize='small' />}
                variant='outlined'
              >
                Update profile
              </Button>
            )}
          </Box>
        </Box>
        <Overview fetchUserData={fetchUserData} isEditMode={isEditMode} user={userDisplayData} fullName={fullName()} />
      </Container>
    </Box>
  );
};

export default Account;
