import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import React, { useState } from 'react';
import { ActionsMenu } from '../ActionsMenu';
import { styles } from './styles';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { formDataApi } from '../../axios';
import { useSnackbar } from 'notistack';

const DepartmentData = ({ department, id }) => {
  const [openDialog, setOPenDialog] = useState(false);
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const handleDialogClose = () => {
    setOPenDialog(false);
  };

  const handleDeleteDesignation = async () => {
    const bodyFormData = new FormData();
    bodyFormData.append('id', id);
    try {
      const response = await formDataApi.post('/deleteDepartment', bodyFormData);
      if (response.data?.status === 'SUCCESS') {
        enqueueSnackbar('Department deleted successfuly', { variant: 'success' });
        navigate('/settings/departments');
      } else throw new Error(response.data?.message)
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
    setOPenDialog(false);
  };
  const renderDialogContent = () => (
    <DialogContent>
      <Box>
        <DialogTitle sx={styles.dialogTitle}>Delete Designation</DialogTitle>
        <DialogContentText>Are you sure you want to delete department?</DialogContentText>
      </Box>
      <DialogActions sx={styles.buttonsWrapper}>
        <Button size='large' onClick={handleDialogClose}>
          Cancel
        </Button>
        <Button size='large' variant='contained' onClick={() => handleDeleteDesignation()}>
          Confirm
        </Button>
      </DialogActions>
    </DialogContent>
  );

  const actions = [
    {
      label: 'Edit',
      onClick: () => {
        navigate(`/settings/departments/edit/${id}`);
      },
      icon: <Edit fontSize='small' />,
      permissions: ['ROLE_BASE_PERMISSION_INDEX_UPDATE_DEPARTMENT'],
    },
    {
      label: 'Delete',
      onClick: () => {
        setOPenDialog(true);
      },
      icon: <Delete fontSize='small' />,
      permissions: ['ROLE_BASE_PERMISSION_INDEX_DELETE_DEPARTMENT'],
    },
  ];

  const details = {
    Name: department?.data?.name ?? '',
    Status: department?.data?.status ?? '',
  };

  return (
    <Grid container spacing={4}>
      <Grid item md={12} xs={12}>
        <Grid
          container
          spacing={3}
          sx={{
            mb: 5,
          }}
        >
          <Grid item xs={12}>
            <Box sx={styles.titleWrapper}>
              <Typography color='neutral.600' variant='subtitle2'>
                Department Details
              </Typography>
              <Box sx={styles.growBox} />
              <ActionsMenu
                actions={actions}
                label='Actions'
                size='medium'
                variant='outlined'
                sx={styles.actionMenuButton}
              />
              <Dialog open={openDialog} onClose={() => setOPenDialog(false)}>
                {renderDialogContent()}
              </Dialog>
            </Box>
          </Grid>
          {Object.keys(details).map((key) => (
            <Grid
              key={key}
              item
              xs={4}
              sx={{
                pt: '32px !important',
              }}
            >
              <Typography color='neutral.600' variant='body2'>
                {key}
              </Typography>
              {typeof details[key] === 'string' || !details[key] ? (
                <Typography
                  color='neutral.700'
                  variant='h6'
                  sx={{
                    pt: 2,
                  }}
                >
                  {details[key] || ''}
                </Typography>
              ) : (
                details[key]
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DepartmentData;
