import { makeStyles } from '@mui/styles';
import overview from '../../assets/images/overview.png';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  logo: {
    height: theme.spacing(8),
    width: theme.spacing(28),
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(5),
      width: theme.spacing(20),
    },
  },
  leftSidebar: {
    position: 'relative',
    display: 'flex',
    background: 'radial-gradient(109.43% 109.43% at 98.49% 99.08%, #2C677A 0%, #11262E 100%)',
    width: '55%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  container: {
    padding: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5),
    },
  },
  overviewContainer: {
    position: 'absolute',
    right: theme.spacing(0),
    bottom: theme.spacing(0),
    backgroundImage: `url(${overview})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'right bottom',
    width: theme.spacing(60),
    height: '40vh',
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(0),
    },
  },
  rightSidebar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  form: {
    maxWidth: theme.spacing(56),
    width: '100%',
  },
}));
