import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../components/CustomTable';
import { api, formDataApi } from '../../axios';
import { columns } from './columns';
import FormData from 'form-data';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import PermissionChecker from '../../components/PermissionChecker';
import { styles } from './styles';
import AddIcon from '@mui/icons-material/Add';
import { updateAllSelectedRoles, updateRoles, updateSelectedRoles } from '../../store/slices/roles';

const mockRoles = [
  { name: 'Candidate', id: 1 },
  { name: 'Payroll Manager', id: 2 },
  { name: 'Payroll Head', id: 3 },
  { name: 'Employee', id: 4 },
  { name: 'Manager', id: 5 },
  { name: 'Administrator', id: 6 },
  { name: 'Contractor', id: 7 },
];
const Roles = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { roles, selectedRoles } = useSelector((state) => state.roles);

  const { enqueueSnackbar } = useSnackbar();

  const [controller, setController] = useState({
    page: 0,
    query: '',
    sort: 'desc',
    sortBy: 'id',
    view: 'all',
    searched: false,
    pageSize: 10,
  });

  const fetchData = useCallback(async (searched, sort, sortBy, page, pageSize) => {
    setLoading(true);
    try {
      if (searched) {
        const query = new URLSearchParams({});
        if (sortBy) {
          query.append('sort', sortBy);
          query.append('order', sort);
        }
        query.append('offset', page * pageSize);
        query.append('max', pageSize);
        const response = await api.post(`/searchRole?${query.toString()}`);
        if (response.data) {
          dispatch(updateRoles(response.data));
        }
      } else {
        const query = new URLSearchParams({});
        if (sortBy) {
          query.append('sort', sortBy);
          query.append('order', sort);
        }
        query.append('offset', page * pageSize);
        query.append('max', pageSize);
        const response = await api.post(`/listRoles?${query.toString()}`);
        if (response.data) {
          dispatch(updateRoles(response.data));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (fetchData)
      fetchData(controller.searched, controller.sort, controller.sortBy, controller.page, controller.pageSize);
  }, [controller, fetchData]);

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage - 1,
    });
  };

  const handlePageSizeChange = (newPageSize) => {
    setController({
      ...controller,
      page: 0,
      pageSize: newPageSize
    });
  }

  const handleSortChange = (event, property) => {
    const isAsc = controller.sortBy === property && controller.sort === 'asc';

    setController({
      ...controller,
      page: 0,
      sort: isAsc ? 'desc' : 'asc',
      sortBy: property,
    });
  };

  const handleSelect = (event, rowId) => {
    dispatch(updateSelectedRoles({ isChecked: event.target.checked, rowId }));
  };

  const handleSelectAll = (event) => {
    dispatch(updateAllSelectedRoles({ isChecked: event.target.checked }));
  };

  const handleRowClick = (id) => {
    navigate(`/settings/roles/${id}`);
  };

  const handleDeleteRole = async (id) => {
    const bodyFormData = new FormData();
    bodyFormData.append('id', id);
    try {
      const response = await formDataApi.post('/deleteRole', bodyFormData);
      if (response.data?.status === 'SUCCESS') {
        enqueueSnackbar('Designation deleted successfuly', { variant: 'success' });
        fetchData(controller.searched, controller.sort, controller.sortBy, controller.page, controller.pageSize);
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };
  return (
    <Box sx={styles.growBox}>
      <Container maxWidth='lg' sx={styles.pageWrapper}>
        <Grid container direction='column' spacing={0}>
          <Grid
            sx={{ pt: '12px', pb: '32px' }}
            item
            container
            spacing={1}
            justifyContent='flex-start'
            alignItems='center'
            py={4}
          >
            <Grid item>
              <Typography variant='h4'>Roles</Typography>
            </Grid>
            <Grid item xs></Grid>
            <Grid item xs='auto'>
              <PermissionChecker requiredPermissions={['ROLE_BASE_PERMISSION_SECROLE_CREATE']} noAuthRedirect={false}>
                <Button
                  color='primary'
                  onClick={() => navigate('/settings/roles/add')}
                  size='large'
                  startIcon={<AddIcon fontSize='small' />}
                  variant='contained'
                  sx={{ ':hover': { backgroundColor: 'secondary.main' } }}
                >
                  Add Role
                </Button>
              </PermissionChecker>
            </Grid>
          </Grid>
          <Grid item sx={{ pt: '0px !important' }}>
            <Card>
              <CardContent>
                <CustomTable
                  isLoading={loading}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                  onSelect={handleSelect}
                  onSelectAll={handleSelectAll}
                  onRowClick={handleRowClick}
                  onSortChange={handleSortChange}
                  page={controller.page + 1}
                  columns={columns}
                  rows={roles.data}
                  rowsCount={roles.count}
                  selectedRows={selectedRoles}
                  sort={controller.sort}
                  sortBy={controller.sortBy}
                  showRowSelector={false}
                  showPagignation={true}
                  keyColumn='id'
                  action={handleDeleteRole}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Roles;
