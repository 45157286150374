import { createSlice } from '@reduxjs/toolkit';

const locations = createSlice({
  name: 'locations',
  initialState: {
    selectedLocations: [],
    locations: {
      count: 0,
      data: [],
    },
  },
  reducers: {
    updateLocations(state, action) {
      const { locationInstanceList = [], locationInstanceCount = 0 } = action.payload;
      state.locations.data = locationInstanceList;
      state.locations.count = locationInstanceCount;
    },
    updateSelectedLocations(state, action) {
      const { isChecked, rowId } = action.payload;

      if (isChecked) {
        state.selectedLocations.push(rowId);
        return;
      }
      state.selectedLocations = state.selectedLocations.filter((selectedRow) => selectedRow !== rowId);
    },
    updateAllSelectedLocations(state, action) {
      const { isChecked } = action.payload;
      state.selectedLocations = [];
      if (isChecked) {
        state.locations.data.map((row) => state.selectedLocations.push(row['id']));
        return;
      }
      state.selectedLocations = [];
    },
  },
});

export const { updateLocations, updateSelectedLocations, updateAllSelectedLocations } = locations.actions;

export default locations.reducer;
