import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Checkbox, Container, Grid, Typography, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { styles } from './styles';
import { formDataApi } from '../../axios';
import { styles as userStyles } from '../../components/UserDetailsTab/styles'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputField } from '../../components/InputField';

const CompanyConfigModal = ({label, modalTitle, modalDescription, apiKey, isCheckedRes}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();

  useEffect(() => {
    setIsChecked(isCheckedRes)
  },[isCheckedRes])

  const handleIntegrationChange = async () => {
    const bodyFormData = new FormData()
    bodyFormData.append(apiKey, !isChecked);
    if(params.companyId)
      bodyFormData.append('companyId', params.companyId);

    const apiPath = '/modifyCompanyConfigAction'

    try {
      const response = await formDataApi.post(apiPath, bodyFormData)
      if (response?.data?.status &&
        response?.data?.status?.toLowerCase() === 'success') {
        enqueueSnackbar(response?.data?.message, { variant: 'success' });
        setIsChecked(!isChecked)
      } else {
        enqueueSnackbar(response?.data?.message, { variant: 'error' });
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
    setOpenDialog(false)
  }
  

  return <React.Fragment>
          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            <DialogContent>
              <Box>
                <DialogTitle sx={styles.dialogTitle}>{modalTitle}</DialogTitle>
                <DialogContentText>{`Do you want to ${!isChecked ? "enable" : "disable"} ${modalDescription}?`}</DialogContentText>
              </Box>
              <DialogActions sx={styles.buttonsWrapper}>
                <Button size='large' onClick={() => setOpenDialog(false)}>
                  Cancel
                </Button>
                <Button size='large' variant='contained' onClick={handleIntegrationChange}>
                  Confirm
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
          <Grid item xs={12} sx={userStyles.checkboxWrapper}>
            <Grid item xs={4.5} sx={userStyles.checkbox}>
              <Checkbox
                color='secondary'
                checked={isChecked}
                name='enabled'
                disabled={false}
                onChange={() => setOpenDialog(true)}
              />
              <Typography color='textPrimary' sx={userStyles.typography}>
                {label}
              </Typography>
            </Grid>
          </Grid>
      </React.Fragment>
}

const CompanyConfig = () => {
  const [companyData, setCompanyData] = useState()
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const getModifyConfigData = async () => {
    try {
      const bodyFormData = new FormData()
      if(params.companyId)
        bodyFormData.append('companyId', params.companyId);
      const response = await formDataApi.post('/modifyCompanyConfig', bodyFormData);
      if (response?.data?.status && response?.data?.status?.toLowerCase() === 'success') {
              setCompanyData(response?.data?.companyConfig)
            } else {
              enqueueSnackbar(response?.data?.message, { variant: 'error' });
            }
    } catch (error) {
     enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  useEffect(() => {
      getModifyConfigData();
    }, []);

  useEffect(() => {
      if(companyData)
        formik.setValues({
          baseUrl: companyData?.riseBaseUrl,
          username: companyData?.riseUsername,
          password: companyData?.risePassword
        })
    },[companyData])

    const formik = useFormik({
      initialValues: {
        baseUrl: "",
        username: "",
        password: ""
      },
      validationSchema: Yup.object().shape({
        baseUrl: Yup.string().required('BaseUrl is required.'),
        username: Yup.string().required('UserName is required.'),
        password: Yup.string().required('Password is required.'),
      }),
      onSubmit: async (values) => {
        try {
          const bodyFormData = new FormData()
          if(params.companyId)
            bodyFormData.append('companyId', params.companyId);
          bodyFormData.append('riseBaseUrl', values.baseUrl);
          bodyFormData.append('riseUsername', values.username);
          bodyFormData.append('risePassword', values.password);
          const response = await formDataApi.post('modifyCompanyConfigAction', bodyFormData);
          if (response?.data?.status === "SUCCESS") {
            enqueueSnackbar(response?.data?.message, { variant: 'success' });
          }else enqueueSnackbar(response?.data?.message, { variant: 'error' });
        } catch (err) {
          enqueueSnackbar("Something went wrong", { variant: 'error' });
        } finally{
          getModifyConfigData()
        }
      }
    })

  return (
    <Box sx={styles.growBox}>
      
      <Container maxWidth='lg' sx={styles.pageWrapper}>
        <Grid container direction='column' spacing={2}>
          <Grid
            sx={{ py: '32px !important' }}
            item
            container
            spacing={1}
            justifyContent='flex-start'
            alignItems='center'
            py={4}
          >
            <Grid item>
              <Typography variant='h4'>Company Configuration</Typography>
              <Typography variant='h5' sx={{pt: 1}}>{companyData?.company?.companyName}</Typography>
            </Grid>
            <Grid item xs></Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Card>
            <CardContent>
              <CompanyConfigModal label="Integrated with Canvas" modalTitle="Canvas integration" modalDescription="Canvas integration" apiKey="isIntegratedWithCanvas" isCheckedRes={companyData?.isIntegratedWithCanvas || null} />
              <CompanyConfigModal label="Client Disengagement" modalTitle="Client Disengagement" modalDescription="Client Disengagement" apiKey="isClientDisengaged" isCheckedRes={companyData?.company?.isClientDisengaged || null} />
              <Grid item my={2}>
                <Typography variant='h6' mb={4}>RISE Configuration</Typography>
                <form onSubmit={formik.handleSubmit}>
                  <Grid display={"flex"} mb={2}>
                    <InputField
                      sx={{marginRight : "20px"}}
                      error={Boolean(formik.touched.baseUrl && formik.errors.baseUrl)}
                      fullWidth
                      helperText={formik.touched.baseUrl && formik.errors.baseUrl}
                      label="Base URL"
                      name="baseUrl"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.baseUrl}
                    />
                    <InputField
                      sx={{marginRight : "20px"}}
                      error={Boolean(formik.touched.username && formik.errors.username)}
                      fullWidth
                      helperText={formik.touched.username && formik.errors.username}
                      label="Username"
                      name="username"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.username}
                    />
                      <InputField
                      error={Boolean(formik.touched.password && formik.errors.password)}
                      fullWidth
                      helperText={formik.touched.password && formik.errors.password}
                      label="Password"
                      name="password"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      type="password"
                    />
                  </Grid>
                  <Grid textAlign={"right"}>
                    <Button
                      color="primary"
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Grid>
                </form>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Container>
    </Box>
  );
};

export default CompanyConfig;
