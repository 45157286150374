import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../../axios';
import { endpoints } from '../constants';

const initialState = {
  positionSummaryByStatus: {
    isLoading: false,
    hasErrored: false,
    data: [],
  },
  changeRequestSummaryByStatus: {
    isLoading: false,
    hasErrored: false,
    data: [],
  },
  positionMappingSummaryByStatus: {
    isLoading: false,
    hasErrored: false,
    data: [],
  },
  recruitmentPolicySummary: {
    isLoading: false,
    hasErrored: false,
    data: [],
  },
  positionClosureSummary: {
    isLoading: false,
    hasErrored: false,
    data: [],
  },
};

export const getPositionSummaryByStatus = createAsyncThunk(
  'getPositionSummaryByStatus',
  (data = {}, { rejectWithValue }) =>
    api
      .post(endpoints.POSITION_SUMMARY_BY_STATUS, data)
      .then((res) => {
        if (res?.data?.status !== 'SUCCESS') {
          return rejectWithValue(res.data);
        }
        return res.data;
      })
      .catch((error) => console.log(error))
);

export const getChangeRequestSummaryByStatus = createAsyncThunk(
  'getChangeRequestSummaryByStatus',
  (data = {}, { rejectWithValue }) =>
    api
      .post(endpoints.CR_SUMMARY_BY_STATUS, data)
      .then((res) => {
        if (res?.data?.status !== 'SUCCESS') {
          return rejectWithValue(res.data);
        }
        return res.data;
      })
      .catch((error) => console.log(error))
);

export const getPositionMappingSummaryByStatus = createAsyncThunk(
  'getPositionMappingSummaryByStatus',
  (data = {}, { rejectWithValue }) =>
    api
      .post(endpoints.POSITION_MAPPING_SUMMARY_BY_STATUS, data)
      .then((res) => {
        if (res?.data?.status !== 'SUCCESS') {
          return rejectWithValue(res.data);
        }
        return res.data;
      })
      .catch((error) => console.log(error))
);

export const getRecruitmentPolicySummary = createAsyncThunk(
  'getRecruitmentPolicySummary',
  (data = {}, { rejectWithValue }) =>
    api
      .post(endpoints.POSITION_RECRUITMENT_POLICY_MAPPING_SUMMARY, data)
      .then((res) => {
        if (res?.data?.status !== 'SUCCESS') {
          return rejectWithValue(res.data);
        }
        return res.data;
      })
      .catch((error) => console.log(error))
);

export const getPositionClosureSummary = createAsyncThunk(
  'getPositionClosureSummary',
  (data = {}, { rejectWithValue }) =>
    api
      .post(endpoints.POSITION_CLOSURE_SUMMARY_BY_STATUS, data)
      .then((res) => {
        if (res?.data?.status !== 'SUCCESS') {
          return rejectWithValue(res.data);
        }
        return res.data;
      })
      .catch((error) => console.log(error))
);

const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  extraReducers: {
    [getPositionSummaryByStatus.pending]: (state) => {
      state.positionSummaryByStatus.isLoading = true
    },
    [getPositionSummaryByStatus.rejected]: (state) => {
      state.positionSummaryByStatus.hasErrored = true
    },
    [getPositionSummaryByStatus.fulfilled]: (state, action) => {
      state.positionSummaryByStatus.isLoading = false
      state.positionSummaryByStatus.data = action.payload
    },
    [getChangeRequestSummaryByStatus.pending]: (state) => {
      state.changeRequestSummaryByStatus.isLoading = true
    },
    [getChangeRequestSummaryByStatus.rejected]: (state) => {
      state.changeRequestSummaryByStatus.hasErrored = true
    },
    [getChangeRequestSummaryByStatus.fulfilled]: (state, action) => {
      state.changeRequestSummaryByStatus.isLoading = false
      state.changeRequestSummaryByStatus.data = action.payload
    },
    [getPositionMappingSummaryByStatus.pending]: (state) => {
      state.positionMappingSummaryByStatus.isLoading = true
    },
    [getPositionMappingSummaryByStatus.rejected]: (state) => {
      state.positionMappingSummaryByStatus.hasErrored = true
    },
    [getPositionMappingSummaryByStatus.fulfilled]: (state, action) => {
      state.positionMappingSummaryByStatus.isLoading = false
      state.positionMappingSummaryByStatus.data = action.payload
    },
    [getRecruitmentPolicySummary.pending]: (state) => {
      state.recruitmentPolicySummary.isLoading = true
    },
    [getRecruitmentPolicySummary.rejected]: (state) => {
      state.recruitmentPolicySummary.hasErrored = true
    },
    [getRecruitmentPolicySummary.fulfilled]: (state, action) => {
      state.recruitmentPolicySummary.isLoading = false
      state.recruitmentPolicySummary.data = action.payload
    },
    [getPositionClosureSummary.pending]: (state) => {
      state.positionClosureSummary.isLoading = true
    },
    [getPositionClosureSummary.rejected]: (state) => {
      state.positionClosureSummary.hasErrored = true
    },
    [getPositionClosureSummary.fulfilled]: (state, action) => {
      state.positionClosureSummary.isLoading = false
      state.positionClosureSummary.data = action.payload
    },
  }
});

export const { } = dashboard.actions;

export default dashboard.reducer;
