import React, { useCallback, useState } from "react";
import { Button, FormControl, InputLabel, Menu, MenuItem, Select, TextField } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { usePopover } from "../../hooks/use-popover";
import { Box } from "@mui/system";
import { styles } from "./styles"
import { CheckOutlined } from "@mui/icons-material";
import { utilStyles } from "../../utils/styles";
import { bulkUpdateCRStatuses, getApproverChangeRequests, getChangeRequests } from "../../store/slices/listChangeRequests";
import { useDispatch, useSelector } from "react-redux";
import { styles as userStyles } from '../Users/styles'
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import usePaginationAttributes from "../../hooks/use-pagination-attributes";

const ChangeRequestBulkActionMenu = (props) => {
  const { disabled, onArchive, onDelete, selectedCount, tab, ...other } = props;
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const [openDialog, setOpenDialog] = useState(false);
  const { selectedCRs } = useSelector((state) => state.changeRequests);
  const [action, setAction] = useState("");
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.session.user);
  const { pageSize } = usePaginationAttributes();

  const reFetchRequests = () => {
    const newBody = new FormData();
    newBody.append('s', 'Active');
    newBody.append('a', currentUser?.empDBID)
    const query = new URLSearchParams({});
    query.append('offset', 0);
    query.append('max', pageSize);

    dispatch(getApproverChangeRequests({
      query,
      body: newBody
    }))

    dispatch(getChangeRequests({
      query
    }))
  }

  const handleBulkApproveReject = useCallback(({ status }) => {
    const body = new FormData();
    body.append('status', status);
    body.append('ids', selectedCRs.join(", "));
    dispatch(bulkUpdateCRStatuses({
      body
    })).then(res => {
      enqueueSnackbar(res?.payload?.message, { variant: 'success' });
      reFetchRequests()
      navigate(-1)
      handleDialogClose()
      handleClose()
    }).catch((err) => {
      enqueueSnackbar("Failed to update change request.", { variant: "error" });
    })
  }, [dispatch, navigate, selectedCRs])

  const actions = tab === "approver" ? [
    {
      label: 'Approve',
      type: 'approve',
      icon: <CheckOutlined />,
      onClick: () => handleBulkApproveReject({ status: "Approved" })
    },
    // {
    //   label: 'Edit',
    //   type: 'edit',
    //   icon: <EditIcon />
    // },
    {
      label: 'Reject',
      type: 'reject',
      icon: <CloseIcon />,
      onClick: () => handleBulkApproveReject({ status: "Rejected" })

    },
  ] : []

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleMenuItemClick = (action) => {
    setOpenDialog(true);
    if (action.type === 'edit') {
      navigate(`bulk/modify`);
      return;
    }
    setAction(() => {
      switch (action.type) {
        case 'approve':
          return {
            title: 'Bulk approve change requests',
            confirmation: `Are you sure you want to approve ${selectedCount} change requests?`,
            primaryButtonLabel: 'Approve',
            ...action,
          }

        case 'edit':
          return {
            title: 'Edit change requests',
            children: (
              <>
                <FormControl fullWidth>
                  <InputLabel id="hm">Hiring Manager</InputLabel>
                  <Select
                    labelId="hm"
                    id="demo-simple-select"
                    // value={}
                    label="Hiring Manager"
                  // onChange={handleChange}
                  >
                    <MenuItem value='reason'>Reason</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ mt: '0px !important' }}>
                  <InputLabel id="rm">Recruitment manager</InputLabel>
                  <Select
                    labelId="rm"
                    id="demo-simple-select"
                    // value={}
                    label="Recruitment manager"
                  // onChange={handleChange}
                  >
                    <MenuItem value='reason'>Reason</MenuItem>
                  </Select>
                </FormControl>
              </>
            ),
            primaryButtonLabel: 'Confirm',
            ...action,
          }

        case 'reject':
          return {
            title: 'Reject Change Requests',
            confirmation: `Are you sure you want to reject ${selectedCount} change requests?`,
            children: (
              <>
                <TextField
                  fullWidth
                  label="Comment"
                  placeholder="Type here"
                  size="small"
                  variant="outlined"
                  multiline
                  rows={5}
                />
              </>

            ),
            primaryButtonLabel: 'Reject',
            ...action,
          }

        default: return action
      }
    })
  };

  const renderDialogContent = (action) => (
    <DialogContent>
      <Box>
        <DialogTitle sx={userStyles.dialogTitle}>{action.title}</DialogTitle>
        {
          action.confirmation && (
            <DialogContentText>
              {action.confirmation}
            </DialogContentText>
          )
        }
      </Box>
      {action.children}
      <DialogActions >
        <Button
          variant='text'
          color='primary'
          sx={utilStyles.mr(1)}
          onClick={handleDialogClose}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={action.callback}
        >
          {action.primaryButtonLabel}
        </Button>
      </DialogActions>
    </DialogContent>
  );

  const renderMenuActions = () => (
    actions.map((action) => (
      <MenuItem key={action.type} onClick={() => handleMenuItemClick({
        label: action.label,
        type: action.type,
        callback: action.onClick,
      })}>
        <ListItemIcon>
          {action.icon}
        </ListItemIcon>
        <ListItemText variant="body2">{action.label}</ListItemText>
      </MenuItem>
    ))
  )

  return (
    <>
      <Button
        color="primary"
        disabled={disabled}
        onClick={handleOpen}
        ref={anchorRef}
        size="large"
        startIcon={<ExpandMoreIcon />}
        variant="contained"
        {...other}
      >
        Bulk Actions
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={styles.buttonMenu}
      >
        {renderMenuActions()}
      </Menu>
      <Dialog
        sx={styles.dialog}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        {renderDialogContent(action)}
      </Dialog>
    </>
  );
};

export default ChangeRequestBulkActionMenu;
