export const styles = {
  usersHeader: {
    py: 4,
  },
  growBox: {
    flexGrow: 1,
  },
  dataWrappper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  flexCenterNone: {
    alignItems: "center",
    display: "flex",
  },
  mx: (n) => ({
    mx: n,
  }),
  pageWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },

  searchFilterContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    py: 1.5,
  },
  actionMenu: (selectedUsers) => ({
    mr: 2,
    display: selectedUsers.length > 0 ? "flex" : "none",
    order: {
      sm: 1,
      xs: 2,
    },
  }),
  searchInput: {
    order: {
      sm: 2,
      xs: 1,
    },
    width: "40%",
  },
  exportButton: {
    order: 3,
    color: (theme) => theme.palette.text.secondary,
  },
  filterButton: {
    order: 4,
  },
};
