export const styles = {
    auditLogBox: {
        width: "100%",
        p: ' 0 10px'
    },
    auditLogTitle: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        color: '#6E6E72',
    },
    auditLogRowTitle: {
        pr: 1,
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        color: '#3F455A'
    },
    auditLogDate: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        color: '#7C85A2'
    }

}