import { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
  Divider,
  MenuItem,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import { ChevronLeft as ChevronLeftIcon } from '../../../assets/icons/chevron-left';
import { styles as positionStyles } from '../../Positions/styles';
import { styles } from '../../ViewCRPolicies/styles';
import { useNavigate, useParams } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { StyledMenu } from '../../ViewCRPolicies';
import PermissionChecker from '../../../components/PermissionChecker';
import EditIcon from '@mui/icons-material/Edit';
import { Delete } from '@mui/icons-material';
import RoleDetails from './RoleDetails';
import { formDataApi } from '../../../axios';
import { useSnackbar } from 'notistack';

const ViewRoles = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [roleDetails, setRolesDetails] = useState({ isLoading: true });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openDialog, setOpenDialog] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setRolesDetails({ ...roleDetails, isLoading: true });
    async function fetchData() {
      const bodyFormData = new FormData();
      bodyFormData.append('id', params.id);
      try {
        const response = await formDataApi.post('/viewRole', bodyFormData);
        console.log('view role ', response.data);
        if (response?.data?.grantedPermissions) {
          setRolesDetails({
            name: 'Granted Permissions',
            candidate: response.data.grantedPermissions || [],
            displayName: response.data.secRoleInstance?.displayName || '',
          });
        }
      } catch (err) {
        setRolesDetails({ isLoading: false });
        enqueueSnackbar('Something went wrong! Position could not be fetched', { variant: 'error' });
      }
    }
    fetchData();
  }, [params.id]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    handleClose();
  };

  const handleDelete = async () => {
    setOpenDialog(false);
    const bodyFormData = new FormData();
    bodyFormData.append('id', params.id);
    try {
      const response = await formDataApi.post('/deleteRole', bodyFormData);
      if (response?.data?.status !== 'ERROR') {
        enqueueSnackbar('Role deleted successfuly', { variant: 'success' });
      }
    } catch (err) {
      enqueueSnackbar('Something went wrong!', { variant: 'error' });
    }
  };

  return (
    <>
      <Box sx={positionStyles.growBox}>
        <Container maxWidth='lg' sx={styles.pageWrapper}>
          <Grid container direction='column' spacing={2}>
            <Grid item container justifyContent='space-between' sx={{ py: '32px !important' }}>
              <Grid item>
                <Typography variant='h4'>Roles</Typography>
              </Grid>
            </Grid>
            <Grid item sx={{ pt: '0px !important' }}>
              <Card {...props}>
                <CardContent>
                  <Grid container direction='column' spacing={2}>
                    <Grid item sx={{ display: 'flex' }}>
                      <Button
                        startIcon={<ChevronLeftIcon />}
                        variant='text'
                        color='secondary'
                        onClick={() => navigate('/settings/roles')}
                      >
                        {'Back'}
                      </Button>
                      <Box sx={styles.growBox} />
                      <Box>
                        <Button
                          id='demo-customized-button'
                          aria-controls={open ? 'demo-customized-menu' : undefined}
                          aria-haspopup='true'
                          aria-expanded={open ? 'true' : undefined}
                          variant='contained'
                          disableElevation
                          onClick={handleClick}
                          endIcon={!open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                          sx={styles.selectButton}
                        >
                          <span>Actions</span>
                        </Button>
                        <StyledMenu
                          id='demo-customized-menu'
                          MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                        >
                          <PermissionChecker
                            requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_UPDATE_CHANGE_REQUEST_POLICY']}
                            noAuthRedirect={false}
                          >
                            <MenuItem disableRipple onClick={() => navigate(`/settings/roles/edit/${params.id}`)}>
                              <EditIcon />
                              Modify
                            </MenuItem>
                          </PermissionChecker>
                          {/* <PermissionChecker
                            requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_DELETE_CHANGE_REQUEST_POLICY']}
                            noAuthRedirect={false}
                          >
                            <MenuItem onClick={handleOpenDialog} disableRipple>
                              <Delete />
                              Delete
                            </MenuItem>
                          </PermissionChecker> */}
                        </StyledMenu>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ py: 2 }} variant='h6'>
                      {roleDetails?.displayName}
                    </Typography>
                    <RoleDetails data={roleDetails} />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent sx={styles.tableMenuDialog}>
          <Box sx={styles.tableMenuDialog}>
            <DialogTitle>Delete Role</DialogTitle>
            <DialogContentText>Are you sure you want to delete this Role?</DialogContentText>
          </Box>
          <DialogActions>
            <Button size='medium' variant='text' onClick={() => setOpenDialog(false)}>
              Cancel
            </Button>
            <Button size='medium' variant='contained' onClick={handleDelete}>
              Delete
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewRoles;
