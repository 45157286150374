import PropTypes from 'prop-types';
import { Button, Menu, MenuItem } from '@mui/material';
import { usePopover } from '../../hooks/use-popover';
import { ChevronDown as ChevronDownIcon } from '../../assets/icons/chevron-down';
import PermissionChecker from '../PermissionChecker';

export const ActionsMenu = (props) => {
  const { actions, label, ...other } = props;
  const [anchorRef, open, handleOpen, handleClose] = usePopover();

  return (
    <>
      <Button
        color='primary'
        endIcon={<ChevronDownIcon fontSize='small' />}
        onClick={handleOpen}
        size='large'
        variant='contained'
        ref={anchorRef}
        {...other}
      >
        {label}
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {actions.map((item) => (
          <PermissionChecker requiredPermissions={item.permissions || []} noAuthRedirect={false}>
            <MenuItem
              key={item.label}
              onClick={(args) => {
                if (item.onClick) {
                  item.onClick(args);
                }

                handleClose();
              }}
            >
              {item?.icon || <></>}
              {item.label}
            </MenuItem>
          </PermissionChecker>
        ))}
      </Menu>
    </>
  );
};

ActionsMenu.defaultProps = {
  label: 'Actions',
};

ActionsMenu.propTypes = {
  actions: PropTypes.array.isRequired,
  label: PropTypes.string,
};
