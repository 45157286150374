import { Grid, Skeleton, Typography, Divider, Button, Menu } from '@mui/material';
import { useState, useEffect } from 'react';
import { ChevronDown as ChevronDownIcon } from '../../assets/icons/chevron-down';
import { styles } from './styles';
import { Box } from '@mui/system';
import { styles as positionStyles } from '../../pages/Positions/styles';
import { Status } from '../Status';
import { formDataApi } from '../../axios';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { PositionStatusChip } from '../Position/PositionStatusChip';
import moment from 'moment';
import AuditLog from '../AuditLog';

import { useSelector } from 'react-redux';
import { hasPermission } from '../../utils/permission';
import { usePopover } from '../../hooks/use-popover';
import { PositionMenuList } from '../Position/PositionMenuList';
import PositionBulkActionDialog from '../Position/PositionBulkActionDialog';
import { searchChangeCRPolicesAction } from '../../store/slices/listCRPolicies';
import { useDispatch } from 'react-redux';

const PositionData = ({fetchData, ...props}) => {
  const authorities = useSelector((state) => state.session.user.authorities);
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const [openPositionDialog, setOpenPositionDialog] = useState(false);
  const [bulkActionsDialogProperties, setBulkActionsDialogProperties] = useState({});
  const [actionType, setActionType] = useState('');
  const dispatch = useDispatch();
  const params = useParams();

  const userHasCompaDisplayPermisson = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_RECRUIT_INDEX_COMPA_VIEW_POSITION',
  ]);
  const position = {...props.position?.positionInstance, id: params?.id};
  const customObjectInstance = props.position?.customObjectInstance;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const data = new FormData();
    data.append('s', 'Active');
    data.append('all', true);
    dispatch(searchChangeCRPolicesAction(data));
  }, [dispatch]);

  const handleBulkStatusChangeConfirmation = async (selectedPositions, items) => {
    try {
      const data = new FormData();

      if (items.actionType && items.actionType === 'map-cr-policy') {
        data.append('positionIds', selectedPositions.join(','));
        data.append('changeRequestPolicyId', items.crPolicy);
        const response = await formDataApi.post('/updatePositionsMappedToChangeRequestPolicy', data);
        if (response.status === 200) {
          if(response.data?.status === 'ERROR') throw new Error(response.data?.message)
          enqueueSnackbar(response.data.message, { variant: 'success' });
          fetchData()
        }
      }else if (items.actionType && items.actionType === 'resync') {
        data.append('positionIds', selectedPositions.join(','));
        const response = await formDataApi.post('/resyncPosition', data);
        if (response.status === 200) {
          if(response.data?.status === 'ERROR') throw new Error(response.data?.message)
          enqueueSnackbar(response.data.message, { variant: 'success' });
          fetchData()
        }
      } else if (items.singlePositionUpdate) {
        data.append('id', selectedPositions.join(','));
        delete items.singlePositionUpdate;
        for (const key in items) {
          data.append(key, items[key]);
        }
        const response = await formDataApi.post('/modifyPosition', data);
        if (response.status === 200) {
          if(response.data?.status === 'ERROR') throw new Error(response.data?.message)
          enqueueSnackbar("Position status updated successfully.", { variant: 'success' });
          fetchData()
        }
      } else if(items.actionType && items.actionType === 'update-start-date'){
        data.append('positionId', selectedPositions.join(','));
        data.append('startDate', items.startDate);
        const response = await formDataApi.post('/updatePositionStartDate', data);
        if (response.status === 200) {
          if(response.data?.status === 'ERROR') throw new Error(response.data?.message)
          enqueueSnackbar(response.data.message, { variant: 'success' });
          fetchData()
        }
      }else {
        data.append('positionIds', selectedPositions.join(','));
        for (const key in items) {
          const statusUpdatedKey = items[key] === 'Open' ? 'Open Pending Intake' : items[key];
          data.append(key, statusUpdatedKey);
          if (key === 'publicationStatus' && items[key] === 'Published') {
            data.append('status', 'Open Active Sourcing');
          }
        }
        const response = await formDataApi.post('/bulkUpdatePositionStatuses', data);
        if (response.status === 200) {
          if(response.data?.status === 'ERROR') throw new Error(response.data?.message)
          enqueueSnackbar(response.data.message, { variant: 'success' });
          fetchData()
        }
      }
    } catch (error) {
      console.log('error while updating bulk status update', error);
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
    }
  };

  if (props.position.isLoading) {
    return (
      <Box sx={{ p: 2 }}>
        <Skeleton height={42} />
        <Skeleton height={42} />
        <Skeleton height={42} />
      </Box>
    );
  }

  const compaFields = userHasCompaDisplayPermisson
    ? {
      'Budget Lower Limit': position?.budgetLowerLimit.toString(),
      'Budget Mid Limit': position?.budgetMidLimit.toString(),
      'Budget Upper Limit': position?.budgetUpperLimit.toString(),
    }
    : {};

  const backgroundColors = {
    'Not Integrated': '#0F9292',
    Pending: '#0F9292',
    'In Process': '#EFA12D',
    Successful: '#27AB6E',
    'Failed': '#EC4C47',
  };

  const details = {
    // Title: position?.title,
    Designation: position?.designation && position?.designation?.name,
    'Position Code': position?.positionCode,
    Location: position?.location && position?.location?.name,
    Department: position?.department && position?.department?.name,
    ...compaFields,
    Status: <PositionStatusChip label={position?.status} size='small' />,
    // 'Publication Status': (
    //   <Status
    //     color={position?.publicationStatus !== 'Unpublished' ? '#27AB6E' : '#EFA12D'}
    //     label={position?.publicationStatus}
    //   />
    // ),
    'CANVAS INT': (
      <Status color={backgroundColors[position?.canvasIntegrationStatus]} label={position?.canvasIntegrationStatus} 
        icon={position?.canvasIntegrationStatus === "Failed" 
        ? <InfoOutlinedIcon sx={{ color:  backgroundColors[position?.canvasIntegrationStatus], fontSize: "16px", paddingLeft: "2px"}} titleAccess={position?.integrationErrorMessage}/> 
        : null}/>
    ),
    'Filled Date': position?.filledOn,
    'Position Start Date': position?.startDate ?  moment(position?.startDate).format('MMM DD YYYY') : '',
  };

  const additionalDetails = {
    'Change Policy': position?.changeRequestPolicy && position?.changeRequestPolicy?.name,
    'Recruitment Policy': position?.recruitmentPolicy && position?.recruitmentPolicy?.name,
    'Position Created Date': position?.createdOn ?  moment(position?.createdOn).format('MMM DD YYYY') : '',
    'Position Created By': position?.createdBy && position?.createdBy?.fullName,
    'Position Modified Date': position?.modifiedOn ?  moment(position?.modifiedOn).format('MMM DD YYYY') : '',
    'Position Modified By': position?.modifiedBy && position?.modifiedBy?.fullName,
    'Position Published Date': position?.publishedOn ?  moment(position?.publishedOn).format('MMM DD YYYY') : '',
    'Position Published By': position?.publishedBy && position?.publishedBy?.fullName,
  };

  const customAttributes = {};
  if (customObjectInstance?.customFieldValues) {
    customObjectInstance.customFieldValues.forEach((item) => {
      customAttributes[item.fieldLabel] =
        item['fieldDataType'] === 'Date'
          ? !!item['fieldValue']
            ? moment(item['fieldValue']).format('MMM DD YYYY')
            : ''
          : item['fieldValue'];
    });
  }

  const handleResult = (result) => {
    setOpenPositionDialog(false);
    if (!result) return;

    if (actionType === 'Open' || actionType === 'Cancelled') {
      result = { ...result, status: actionType };
    } else if (actionType === 'Published') {
      result = { ...result, publicationStatus: actionType };
    } else if (actionType === 'map-cr-policy') {
      result = { ...result, actionType };
    } else if (actionType === 'change-status') {
      result = { ...result, singlePositionUpdate: true };
    }else if (actionType === 'resync') {
      result = { ...result, actionType };
    }else if (actionType === 'update-start-date') {
      result = { ...result, actionType  };
    }
    // here we need to dispatch the action using store
    handleBulkStatusChangeConfirmation([params.id], result);
  };

  return (
    <Grid container spacing={4}>
      <Grid item md={12} xs={12}>
        <Grid
          container
          spacing={3}
          sx={{
            mb: 5,
          }}
        >
          <Grid item xs={12}>
            <Box sx={styles.titleWrapper}>
              <Typography color='neutral.600' variant='subtitle2'>
                Position Details
              </Typography>
              <Box sx={positionStyles.growBox} />
              {actionType !== -1 && <Button
                  color='primary'
                  endIcon={<ChevronDownIcon fontSize='small' />}
                  onClick={handleOpen}
                  size='large'
                  variant='contained'
                  ref={anchorRef}
                >
                  Actions
                </Button>}
                <Menu
                  anchorEl={anchorRef.current}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  open={true}
                  onClose={handleClose}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  disableScrollLock={true}
                  style={{ visibility: open ? 'visible' : 'hidden' }}
                >
                  <PositionMenuList row={position} handleClose={handleClose} setActionType={setActionType} setOpenPositionDialog={setOpenPositionDialog} setBulkActionsDialogProperties={setBulkActionsDialogProperties}/>
              </Menu>
              {openPositionDialog && <PositionBulkActionDialog
                actionType={actionType}
                data={position}
                open={true}
                onResult={handleResult}
                keepMounted
                {...bulkActionsDialogProperties}
              />}
            </Box>
          </Grid>
          {Object.keys(details).map((key) => (
            <Grid
              key={key}
              item
              xs={4}
              sx={{
                pt: '32px !important',
              }}
            >
              <Typography color='neutral.600' variant='body2'>
                {key}
              </Typography>
              {typeof details[key] === 'string' || !details[key] ? (
                <Typography
                  color='neutral.700'
                  variant='h6'
                  sx={{
                    pt: 2,
                  }}
                >
                  {details[key] || '-'}
                </Typography>
              ) : (
                details[key]
              )}
            </Grid>
          ))}
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        sx={{
          pt: '0 !important',
        }}
        item
        md={12}
        xs={12}
      >
        <Grid
          container
          spacing={3}
          sx={{
            mb: 5,
          }}
        >
          <Grid item xs={12}>
            <Box>
              <Typography color='neutral.600' variant='subtitle2'>
                Custom Attributes
              </Typography>
            </Box>
          </Grid>
          {Object.keys(customAttributes).map((key) => (
            <Grid
              key={key}
              item
              xs={4}
              sx={{
                pt: '32px !important',
              }}
            >
              <Typography color='neutral.600' variant='body2'>
                {key}
              </Typography>
              {typeof customAttributes[key] === 'string' || !customAttributes[key] ? (
                <Typography
                  color='neutral.700'
                  variant='h6'
                  sx={{
                    pt: 2,
                  }}
                >
                  {customAttributes[key] || '-'}
                </Typography>
              ) : (
                customAttributes[key]
              )}
            </Grid>
          ))}
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        sx={{
          pt: '0 !important',
        }}
        item
        md={12}
        xs={12}
      >
        <Grid
          container
          spacing={3}
          sx={{
            mb: 5,
          }}
        >
          <Grid item xs={12}>
            <Box>
              <Typography color='neutral.600' variant='subtitle2'>
                Additional Details
              </Typography>
            </Box>
          </Grid>
          {Object.keys(additionalDetails).map((key) => (
            <Grid
              key={key}
              item
              xs={4}
              sx={{
                pt: '32px !important',
              }}
            >
              <Typography color='neutral.600' variant='body2'>
                {key}
              </Typography>
              {typeof additionalDetails[key] === 'string' || !additionalDetails[key] ? (
                <Typography
                  color='neutral.700'
                  variant='h6'
                  sx={{
                    pt: 2,
                  }}
                >
                  {additionalDetails[key] || '-'}
                </Typography>
              ) : (
                additionalDetails[key]
              )}
            </Grid>
          ))}
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <AuditLog data={props.position?.commentsMap} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PositionData;
