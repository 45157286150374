import React, { useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { updateUser } from './store/slices/session';
import { api } from './axios';
import CachingController from './CachingController';
import { hasPermission } from './utils/permission';

const useStyles = makeStyles(() => ({
  page: {
    flexGrow: 1,
    overflow: 'auto',
  },
}));

const App = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.session);

  useEffect(() => {
    const getCurrentUser = async () => {
      try {
        const resposne = await api.post('/getCurrentUser');
        if (resposne?.data) {
          const url = new URL(resposne.request.responseURL);
          if (url.pathname === '/login/auth') {
            dispatch(updateUser({}));
            navigate('/login');
          }
          dispatch(updateUser(resposne.data));
          if (location.pathname === '/') {
            const isSuperAdmin = hasPermission(resposne.data.authorities, ['ROLE_SUPER_ADMINISTRATOR']);
            isSuperAdmin ? navigate('/company') : navigate('/dashboard');
          }
        } else {
          navigate('/login');
        }
      } catch (error) {
        dispatch(updateUser({}));
        navigate('/login');
      }
    };
    getCurrentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !user.userDBId ? (
    <LinearProgress />
  ) : (
    <>
      <CachingController />
      <div className={classes.page}>
        <Outlet />
      </div>
    </>
  );
};

export default App;
