import React, { useState } from 'react';
import { Box, Button, IconButton, Menu, MenuList, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import { usePopover } from '../../hooks/use-popover';
import { DotsVertical as DotsVerticalIcon } from '../../assets/icons/dots-vertical';
import { styles } from './styles';
import { useNavigate } from 'react-router-dom';
import PermissionChecker from '../../components/PermissionChecker';

export const RoleTableMenu = ({ row, column, route, deleteAction }) => {
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const [openDialog, setOPenDialog] = useState(false);
  const [action, setAction] = useState('');

  const navigate = useNavigate();

  const handleDialogClose = () => {
    setOPenDialog(false);
  };

  const actionItems = [
    {
      label: 'Modify',
      type: 'modify_role',
      icon: <EditIcon />,
      permissions: ['ROLE_BASE_PERMISSION_SECROLE_UPDATE'],
    },
    // {
    //   label: 'Delete',
    //   type: 'delete',
    //   icon: <Delete />,
    //   onClick: handleDelete,
    //   permissions: ['ROLE_BASE_PERMISSION_SECROLE_DELETE'],
    // },
  ];

  const handleMenuItemClick = (action) => {
    if (action.type === 'modify_role') {
      navigate(`/settings/roles/edit/${row.id}`);
      return;
    }

    setOPenDialog(true);

    setAction(() => {
      switch (action.type) {
        case 'delete':
          return {
            title: 'Delete Role',
            confirmation: `Are you sure you want to delete this Role?`,
            callback: action.callback,
            ...action,
          };
        default:
          return action;
      }
    });
  };

  const renderDialogContent = (action) => (
    <DialogContent sx={styles.tableMenuDialog}>
      <Box sx={styles.tableMenuDialog}>
        <DialogTitle>{action.title}</DialogTitle>
        {action.confirmation && <DialogContentText>{action.confirmation}</DialogContentText>}
      </Box>
      {action.children}
      <DialogActions>
        <Button size='large' onClick={handleDialogClose}>
          Cancel
        </Button>
        <Button size='large' variant='contained' onClick={action.callback}>
          Delete
        </Button>
      </DialogActions>
    </DialogContent>
  );

  const renderMenuActions = () =>
    actionItems.map((action, index) => (
      <PermissionChecker requiredPermissions={action.permissions} key={index} noAuthRedirect={false}>
        <MenuItem
          key={action.type}
          onClick={() =>
            handleMenuItemClick({
              label: action.label,
              type: action.type,
              callback: action.onClick,
            })
          }
        >
          <ListItemIcon>{action.icon}</ListItemIcon>
          <ListItemText variant='body2'>{action.label}</ListItemText>
        </MenuItem>
      </PermissionChecker>
    ));

  return (
    <>
      <IconButton
        onClick={handleOpen}
        ref={anchorRef}
        color='primary'
      >
        <DotsVerticalIcon fontSize='small' />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList dense>{renderMenuActions()}</MenuList>
      </Menu>
      <Dialog open={openDialog} onClose={() => setOPenDialog(false)}>
        {renderDialogContent(action)}
      </Dialog>
    </>
  );
};
