export const styles = {
    crPoliciesHeader: {
        py: 4
    },
    crPolicies: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    growBox: {
        flexGrow: 0.5
    },
    dataWrappper: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        boxShadow: ' 0 0 9px #9c9c9c61'
    },
    flexCenterNone: {
        alignItems: 'center',
        display: 'flex'
    },
    mx: (n) => ({
        mx: n
    }),
    pageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    }
}
