import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';
import { Delete, Edit, ViewArray } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { utilStyles } from '../../utils/styles';
import { ActionsMenu } from '../ActionsMenu';
import { styles } from './styles';
import { Box } from '@mui/system';
import { styles as positionStyles } from '../../pages/Positions/styles';
import { useNavigate, useParams } from 'react-router-dom';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { formDataApi } from '../../axios';
import AuditLog from '../AuditLog';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

const UserData = ({ user, id }) => {
  const [range, setRange] = useState('view');
  const [openDialog, setOPenDialog] = useState();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.session.user);
  const isLoggedInUser = currentUser.userDBId.toString() === id;
  const params = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const handleDialogClose = () => {
    setOPenDialog();
  };

  const handleDeleteUser = () => {
    async function fetchData() {
      const bodyFormData = new FormData();
      if(params.companyId){
        bodyFormData.append('company', params.companyId);
      }
      bodyFormData.append('id', id);
      try {
        const response = await formDataApi.post('/deleteUser', bodyFormData);
        if (response.data) {
          enqueueSnackbar('User deleted successfuly', { variant: 'success' });
          navigate(params.companyId ? `/company/${params.companyId}/users` : '/users')
        }
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    }

    fetchData();
    setOPenDialog();
  };
  const renderDialogContent = () => (
    <DialogContent>
      <Box>
        <DialogTitle sx={styles.dialogTitle}>{openDialog?.title}</DialogTitle>
        <DialogContentText>
          {openDialog?.subtitle && <Typography variant='h6' mb={2}>{openDialog?.subtitle}</Typography>}
          {openDialog?.message}
        </DialogContentText>
      </Box>
      <DialogActions sx={styles.buttonsWrapper}>
        <Button size='large' onClick={handleDialogClose}>
          Cancel
        </Button>
        <Button size='large' variant='contained' onClick={openDialog?.action}>
          Confirm
        </Button>
      </DialogActions>
    </DialogContent>
  );

  const handleSendCredentials = async () => {
    try {
      const bodyFormData = new FormData();
      if(params.companyId){
        bodyFormData.append('company', params.companyId);
      }
      bodyFormData.append('id', user?.secUserInstance?.employeeId);
      const response = await formDataApi.post('/sendESSInviteToEmp', bodyFormData);
      if (response?.data?.status !== 'ERROR') {
        enqueueSnackbar(response.data.message, { variant: 'success' });
      } else {
        enqueueSnackbar(response.data.message, { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    } finally{
      setOPenDialog();
    }
  };

  const actions = [
    {
      label: 'Edit',
      onClick: () => {
        setRange('edit');
      },
      icon: <Edit fontSize='small' sx={utilStyles.mr(2)} />,
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_EDIT_SECUSER'],
    },
    {
      label: 'Delete',
      onClick: () => {
        setOPenDialog({title: "Delete User", message: 'Are you sure you want to delete user?', action: () => handleDeleteUser()});
      },
      icon: <Delete fontSize='small' sx={utilStyles.mr(2)} />,
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_DELETE_SECUSER'],
    },
    {
      label: ' Send Credentials',
      onClick: () => {
        setOPenDialog({title: "Send Credentials", subtitle: "Please confirm if you'd like to send new credentials to the user.", message:  "This will reset their current password, and they will only be able to log in with the new password provided.", action: () => handleSendCredentials()});
      },
      icon: <MailOutlineIcon fontSize='small' sx={utilStyles.mr(2)} />,
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_SEND_ESS_INVITE_TO_EMPLOYEE'],
    },
  ];

  useEffect(() => {
    if (range === 'edit') {
      navigate(params.companyId ? `/company/${params.companyId}/users/edit/${id}` : `/users/edit/${id}`);
    } else if (range === 'delete') {
      setRange('delete');
    } else if (range === 'send_credentials'){
      setRange('send_credentials');
    }
  }, [range]);

  if (user.isLoading) {
    return (
      <Box sx={{ p: 2 }}>
        <Skeleton height={42} />
        <Skeleton height={42} />
        <Skeleton height={42} />
      </Box>
    );
  }
  const RoleKeys = Object.entries(user.roleMap).filter((item) => item.includes(true));

  const details = {
    Username: user?.secUserInstance?.username,
    'Email ID': user?.employeeInstance?.emailAddresses?.[0],
    'First Name': user?.employeeInstance?.firstName,
    'Last Name': user?.employeeInstance?.lastName,
    'Password Expired': user?.secUserInstance?.passwordExpire ? 'Yes' : 'No',
    'Account Enabled': user?.secUserInstance?.enabled ? 'Yes' : 'No',
    'Contact Number': user?.employeeInstance?.mobileNumbers?.[0],
  };

  return (
    <Grid container spacing={4}>
      <Grid item md={12} xs={12}>
        <Grid
          container
          spacing={3}
          sx={{
            mb: 5,
          }}
        >
          <Grid item xs={12}>
            <Box sx={styles.titleWrapper}>
              <Typography color='neutral.600' variant='subtitle2'>
                User Details
              </Typography>
              <Box sx={positionStyles.growBox} />
              <ActionsMenu
                actions={actions.filter(action =>  (action.label !== "Delete" ||  !isLoggedInUser))}
                label='Actions'
                size='large'
                variant='outlined'
                sx={styles.actionMenuButton}
              />
              <Dialog open={openDialog ? true : false} onClose={() => setOPenDialog()}>
                {renderDialogContent()}
              </Dialog>
            </Box>
          </Grid>
          {Object.keys(details).map((key) => (
            <Grid
              key={key}
              item
              xs={4}
              sx={{
                pt: '32px !important',
              }}
            >
              <Typography color='neutral.600' variant='body2'>
                {key}
              </Typography>
              {typeof details[key] === 'string' || !details[key] ? (
                <Typography
                  color='neutral.700'
                  variant='h6'
                  sx={{
                    pt: 2,
                  }}
                >
                  {details[key] || ''}
                </Typography>
              ) : (
                details[key]
              )}
            </Grid>
          ))}
        </Grid>
        <Box sx={styles.titleWrapper}>
          <Typography color='neutral.600' variant='subtitle2'>
            User Roles
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          pt: '32px !important',
        }}
      >
        <Typography color='neutral.600' variant='body2'>
          Role
        </Typography>

        <Typography
          color='neutral.700'
          variant='h6'
          sx={{
            pt: 2,
          }}
        >
          {RoleKeys.map((key, i) => key[0]).join()}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <AuditLog data={user?.commentsMap} />
      </Grid>
    </Grid>
  );
};

export default UserData;
