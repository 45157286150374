import React, { useEffect, useState } from "react";
import { Stats } from "../../../components/Stats";
import { api } from "../../../axios";
import { useSnackbar } from "notistack";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined';
import ThumbUpOffAltOutlinedIcon from '@mui/icons-material/ThumbUpOffAltOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useSelector } from "react-redux";

export const stats = [
  {
    content: "",
    icon: CheckCircleOutlineIcon,
    iconColor: "success.contrast",
    label: "Active",
  },
  {
    content: "",
    icon: ThumbUpOffAltOutlinedIcon,
    iconColor: "warning.main",
    label: "Approved",
  },
  {
    content: "",
    icon: DoNotDisturbAltOutlinedIcon,
    iconColor: "#fff",
    label: "Rejected",
  },
  {
    content: "",
    icon: CloseOutlinedIcon,
    iconColor: "#fff",
    label: "Cancelled",
  },
];

const ChangeRequestsStats = () => {
  const [data, setData] = useState(stats);
  const { changeRequests } = useSelector((state) => state.changeRequests);

  useEffect(() => {
    if (changeRequests?.statusWiseStats) {
      const newStats = [
        {
          ...stats[0], content: changeRequests?.statusWiseStats?.Active,
        },
        {
          ...stats[1], content: changeRequests?.statusWiseStats?.Approved,
        },
        {
          ...stats[2], content: changeRequests?.statusWiseStats?.Rejected,
        },
        {
          ...stats[3], content: changeRequests?.statusWiseStats?.Cancelled,
        }
      ]
      setData(newStats)
    }
  }, [changeRequests])

  return (
    <Stats stats={data} cardRatioTo12={4} />
  );
};

export default ChangeRequestsStats;
