import React, { useState } from 'react';
import { IconButton, Menu } from '@mui/material';
import { DotsVertical as DotsVerticalIcon } from '../../assets/icons/dots-vertical';
import { usePopover } from '../../hooks/use-popover';
import PositionBulkActionDialog from './PositionBulkActionDialog';
import { PositionMenuList } from './PositionMenuList';

export const PositionTableMenu = ({ row, onBulkStatusChangeConfirmation }) => {
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const [openPositionDialog, setOpenPositionDialog] = useState(false);
  const [actionType, setActionType] = useState('');
  const [bulkActionsDialogProperties, setBulkActionsDialogProperties] = useState({});

  const handleResult = (result) => {
    setOpenPositionDialog(false);
    if (!result) return;

    if (actionType === 'Open' || actionType === 'Cancelled') {
      result = { ...result, status: actionType };
    } else if (actionType === 'Published') {
      result = { ...result, publicationStatus: actionType };
    } else if (actionType === 'map-cr-policy') {
      result = { ...result, actionType };
    } else if (actionType === 'change-status') {
      result = { ...result, singlePositionUpdate: true };
    }else if (actionType === 'resync') {
      result = { ...result, actionType };
    }else if (actionType === 'update-start-date') {
      result = { ...result, actionType  };
    }
    // here we need to dispatch the action using store
    onBulkStatusChangeConfirmation([row.id], result);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        ref={anchorRef}
        color='primary'
        disabled={actionType === -1}
      >
        <DotsVerticalIcon fontSize='small' />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={true}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableScrollLock={true}
        style={{ visibility: open ? 'visible' : 'hidden' }} // Hide until open
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <PositionMenuList row={row} setActionType={setActionType} handleClose={handleClose} setOpenPositionDialog={setOpenPositionDialog} setBulkActionsDialogProperties={setBulkActionsDialogProperties}/>
      </Menu>
      {openPositionDialog && <PositionBulkActionDialog
        actionType={actionType}
        data={row}
        open={true}
        onResult={handleResult}
        keepMounted
        {...bulkActionsDialogProperties}
      />}
    </>
  );
};
