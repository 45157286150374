import { createSlice } from '@reduxjs/toolkit';
import { sessionInitialState } from '../state';

const session = createSlice({
  name: 'session',
  initialState: sessionInitialState,
  reducers: {
    updateUser(state, action) {
      state.user = action.payload;
    },
    logout(state, action) {},
    updateName(state, action) {
      const { firstName, lastName } = action.payload;
      state.user.firstName = firstName;
      state.user.lastName = lastName;
    },
    updateIsIntegratedWithCanvas(state, action) {},
  },
});

export const { updateUser, logout, updateName } = session.actions;

export default session.reducer;
