import React, { useState } from 'react';
import { Grid, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FormData from 'form-data';
import LoginLayout from '../../components/LoginLayout';
import { InputField } from '../../components/InputField';

import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';

const ForgotPassword = () => {
  const [username, setUsername] = useState('');
  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleForgotPassword = async () => {
    try {
      setLoading(true);
      const data = new FormData();
      data.append('u', username);
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/restAuthApi/forgotPasswordAction`, data); //'http://135.181.205.50:8080/restAuthApi/forgotPasswordAction', data);
      if (response.data?.status === 'SUCCESS') {
        enqueueSnackbar(response.data.message, {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(response.data.message, {
          variant: 'error',
        });
      }
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
      setFailed(true);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <LoginLayout>
        <Grid container spacing={2} direction='column'>
          <Grid item>
            <IconButton color='primary' onClick={() => navigate('/login')}>
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <InputField
              autoFocus
              fullWidth
              label='Username'
              name='username'
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              type='email'
              value={username}
              error={failed}
            />
          </Grid>
          <Grid item>
            <LoadingButton
              loading={loading}
              variant='contained'
              color='primary'
              onClick={handleForgotPassword}
              disabled={!username}
              fullWidth
            >
              Reset Password
            </LoadingButton>
          </Grid>
        </Grid>
      </LoginLayout>
    </>
  );
};

export default ForgotPassword;
