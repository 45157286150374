import React from 'react';
import { Typography, Grid, Card, CardContent, Divider, Avatar } from '@mui/material';
import { styles } from './styles';
import { Box } from '@mui/system';
import { toTitleCase } from '../../../utils/string'

const ViewOverview = ({ user, fullName, email }) => {
  const roles = Object.keys(user?.roleMap || {})?.length ?
    Object.keys(user?.roleMap).filter(key => user?.roleMap[key]).map(key => toTitleCase(key)).join(", ") : '-'
  return (
    <Card>
      <CardContent>
        <Grid container spacing={4}>
          <Grid item md={5} xs={12}>
            <Typography color='textPrimary' variant='h6'>
              Basic Details
            </Typography>
          </Grid>
          <Grid item md={7} xs={12}>
            <Grid container xs={12} spacing={0}>
              {/* <Grid
                item
                xs={12}
              >
                <Avatar
                  src={user?.pic || "/static/user-chen_simmons.png"}
                  sx={{
                    height: 64,
                    mr: 2,
                    mb: 1.5,
                    width: 64
                  }}
                />
              </Grid> */}
              <Grid item xs={6}>
                <Typography variant='body2' sx={styles.label}>
                  First name
                </Typography>
                <Typography variant='h6' sx={styles.value}>
                  {user?.secUserInstance?.employee?.firstName}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='body2' sx={styles.label}>
                  Last name
                </Typography>
                <Typography variant='h6' sx={styles.value}>
                  {user?.secUserInstance?.employee?.lastName}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='body2' sx={styles.label}>
                  Email address
                </Typography>
                <Typography variant='h6' sx={styles.value}>
                  {user?.employeeInstance?.emailAddresses[0] || ''}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='body2' sx={styles.label}>
                  Mobile
                </Typography>
                <Typography variant='h6' sx={styles.value}>
                  {user?.employeeInstance?.mobileNumbers[0] || ''}
                </Typography>
              </Grid>
              {/* <Grid
                item
                xs={6}
              >
                <Typography variant="body2" sx={styles.label}>Company name</Typography>
                <Typography variant="h6" sx={styles.value}>{user?.company?.companyName || "-"}</Typography>
              </Grid> */}
              {/* <Grid
                item
                xs={6}
              >
                <Typography variant="body2" sx={styles.label}>Password Expired</Typography>
                <Typography variant="h6" sx={styles.value}>{String(user?.secUserInstance?.passwordExpired) || '-'}</Typography>
              </Grid>
              <Grid
                item
                xs={6}
              >
                <Typography variant="body2" sx={styles.label}>Account enabled</Typography>
                <Typography variant="h6" sx={styles.value}>{String(user?.secUserInstance?.enabled) || '-'}</Typography>
              </Grid> */}
            </Grid>

            <Grid item xs={12} sx={styles.dividerWrapper}>
              <Divider item flexItem orientation='horizontal' />{' '}
            </Grid>
            {user?.companiesList?.length &&
              user.companiesList.map((company) => (
                <Box sx={{ display: 'flex', mt: 3 }}>
                  <Grid item xs={6}>
                    <Typography variant='body2' sx={styles.label}>
                      Company
                    </Typography>
                    <Typography variant='h6' sx={styles.value}>
                      {company.companyName || '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2' sx={styles.label}>
                      Role
                    </Typography>
                    <Typography variant='h6' sx={styles.value}>
                      {roles}
                    </Typography>
                  </Grid>
                </Box>
              ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ViewOverview;
