import React, { useState } from 'react';
import { Box, Button, IconButton, Menu, MenuList, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import { usePopover } from '../../hooks/use-popover';
import { DotsVertical as DotsVerticalIcon } from '../../assets/icons/dots-vertical';
import { Delete } from '@mui/icons-material';
import { styles } from './styles';
import { useNavigate } from 'react-router-dom';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PermissionChecker from '../PermissionChecker';

export const DepartmentTableMenu = ({ row, isViewed, deleteAction }) => {
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const [openDialog, setOPenDialog] = useState(false);
  const [action, setAction] = useState('');

  const navigate = useNavigate();

  const handleDialogClose = () => {
    setOPenDialog(false);
    deleteAction(row.id);
  };

  const handleDelete = () => {
    handleClose();
    setOPenDialog(false);
    deleteAction(row.id);
  };

  const actionItems = [
    {
      label: 'Modify',
      type: 'modify_departments',
      icon: <EditIcon />,
      permissions: ['ROLE_BASE_PERMISSION_INDEX_UPDATE_DEPARTMENT'],
    },
    {
      label: 'Delete',
      type: 'delete',
      icon: <Delete />,
      onClick: handleDelete,
      permissions: ['ROLE_BASE_PERMISSION_INDEX_UPDATE_DEPARTMENT'],
    },
  ];

  const handleMenuItemClick = (action) => {
    if (action.type === 'modify_departments') {
      navigate(`/settings/departments/edit/${row.id}`);
      return;
    }

    setOPenDialog(true);

    setAction(() => {
      switch (action.type) {
        case 'delete':
          return {
            title: 'Delete Department',
            confirmation: `Are you sure you want to delete this Department?`,
            callback: action.callback,
            ...action,
          };
        default:
          return action;
      }
    });
  };

  const renderDialogContent = (action) => (
    <DialogContent sx={styles.tableMenuDialog}>
      <Box sx={styles.tableMenuDialog}>
        <DialogTitle>{action.title}</DialogTitle>
        {action.confirmation && <DialogContentText>{action.confirmation}</DialogContentText>}
      </Box>
      {action.children}
      <DialogActions>
        <Button size='large' onClick={handleDialogClose}>
          Cancel
        </Button>
        <Button size='large' variant='contained' onClick={action.callback}>
          Delete
        </Button>
      </DialogActions>
    </DialogContent>
  );

  const renderMenuActions = () =>
    actionItems.map((action) => (
      <PermissionChecker requiredPermissions={action.permissions} noAuthRedirect={false}>
        <MenuItem
          key={action.type}
          onClick={() =>
            handleMenuItemClick({
              label: action.label,
              type: action.type,
              callback: action.onClick,
            })
          }
        >
          <ListItemIcon>{action.icon}</ListItemIcon>
          <ListItemText variant='body2'>{action.label}</ListItemText>
        </MenuItem>
      </PermissionChecker>
    ));

  return (
    <>
      {!isViewed ? (
        <IconButton onClick={handleOpen} ref={anchorRef} color='primary'>
          <DotsVerticalIcon fontSize='small' />
        </IconButton>
      ) : (
        <Button
          id='demo-customized-button'
          ref={anchorRef}
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          variant='contained'
          disableElevation
          onClick={handleOpen}
          endIcon={!open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          sx={styles.selectButton}
        >
          {!open ? (
            <span>
              <EditIcon fontSize='17px' /> Modify
            </span>
          ) : (
            <span>Opitions</span>
          )}
        </Button>
      )}

      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList dense>{renderMenuActions()}</MenuList>
      </Menu>
      <Dialog open={openDialog} onClose={() => setOPenDialog(false)}>
        {renderDialogContent(action)}
      </Dialog>
    </>
  );
};
