import PropTypes from 'prop-types';
import { DatePicker as DatePickerField} from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { alpha } from '@mui/material/styles';
import moment from 'moment';

export const DatePicker = (props) => {
  const {
    error,
    helperText,
    value,
    onChange,
    label,
    name,
    sx,
    ...other
  } = props;
  const isDate = Object.prototype.toString.call(value) === '[object Date]';
  const dateValue = isDate ? moment(value) : (value ? moment(value) : null);

  return (
    <>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePickerField
        label={label}
        value={dateValue}
        onChange={(date) => onChange(name, moment(date).format("YYYY-MM-DD"))}
        className='mui-date-picker'
        slotProps={{
          textField: {
            error,
            helperText,
          },
        }}
        sx={{
          width: '100%',
          '& .MuiInputBase-root': {
            height: 48,
            color: 'text.primary',
            fontSize: 12,
            fontWeight: 500,
          },
          '& .MuiInputLabel-root': {
            color: 'text.primary',
            fontSize: 12,
            fontWeight: 500,
          },
          '& .MuiFilledInput-root': {
            backgroundColor: 'background.paper',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: (theme) => (theme?.palette?.mode == 'light' ? 'neutral.300' : 'neutral.700'),
            borderRadius: 1,
            boxShadow: '0px 1px 2px 0px rgba(9, 30, 66, 0.08)',
            overflow: 'hidden',
            p: 0,
            transition: (theme) => theme?.transitions?.create(['border-color', 'box-shadow']),
            '&:before': {
              borderBottom: 0,
            },
            '&:hover': {
              backgroundColor: 'background.paper',
            },
            '&.Mui-focused': {
              backgroundColor: 'background.paper',
              boxShadow: (theme) => `${alpha(theme?.palette?.primary?.main, 0.25)} 0 0 0 0.2rem`,
            },
            '&.Mui-disabled': {
              backgroundColor: 'action.disabledBackground',
              boxShadow: 'none',
              borderColor: alpha('#D6DBE1', 0.5),
            },
            '.MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)':
              {
                mt: 0,
                ml: 1.5,
              },
          },
          ...sx,
        }}
        {...other}
      /></LocalizationProvider>
    </>
  );
};

DatePicker.propTypes = {
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.instanceOf(Date),
};
