import { Box, Button, Grid, Card, CardContent, Container, Typography, Divider } from '@mui/material';
import { ChevronLeft as ChevronLeftIcon } from '../../assets/icons/chevron-left';
import { styles } from './styles';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { formDataApi } from '../../axios';
import { useSnackbar } from 'notistack';
import LocationDetailsTab from '../../components/LocationDetailsTab';

const AddEditLocation = (props) => {
  const params = useParams();
  const [locationDetails, setLocationDetails] = useState({
    data: {},
    isLoading: false,
  });
  const location = useLocation();
  const isEditMode = location.pathname.includes('edit');
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchData = async () => {
      setLocationDetails({ ...locationDetails, isLoading: true });
      const bodyFormData = new FormData();
      isEditMode && bodyFormData.append('id', params.id);
      const url = isEditMode ? '/modifyLocation' : '/newLocation';
      try {
        const response = await formDataApi.post(url, bodyFormData);
        if (response?.data) {
          setLocationDetails({ data: response.data, isLoading: false });
        }
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    };

    fetchData();
  }, [params.id]);

  return (
    <Box sx={styles.growBox}>
      <Container maxWidth='lg' sx={styles.pageWrapper}>
        <Grid container direction='column' spacing={2}>
          <Grid item sx={{ py: '32px !important' }}>
            <Typography variant='h4'>{isEditMode ? 'Edit Location' : 'Add Location'}</Typography>
          </Grid>
          <Grid item sx={{ pt: '0px !important' }}>
            <Card {...props}>
              <CardContent>
                <Grid container direction='column' spacing={2}>
                  <Grid item>
                    <Button
                      startIcon={<ChevronLeftIcon />}
                      variant='text'
                      color='secondary'
                      onClick={() => navigate('/settings/locations')}
                    >
                      Back
                    </Button>
                  </Grid>
                  {/*
                  <Grid item>
                    <Typography sx={styles.divider} color='textPrimary' variant='h4'>
                      {isEditMode ? 'Edit Location' : 'Add Location'}
                    </Typography>
                  </Grid>*/}
                </Grid>
                <Grid item>
                  <LocationDetailsTab isEditMode={isEditMode} locationDetails={locationDetails} />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AddEditLocation;
