import { Box, Card, Grid, Typography, IconButton } from "@mui/material";

export const Stats = ({ stats, cardRatioTo12 }) => (
  <Card
    sx={{
      mb: 3,
      backgroundColor: "transparent",
      boxShadow: "none",
      border: "none",
    }}
  >
    <Grid sx={{ gap: "20px", flexWrap: "unset" }} container id="statGrid">
      {stats.map((item, index) => {
        const { icon: Icon, iconColor, content, label } = item;

        return (
          <Grid
            item
            key={item.label}
            md={cardRatioTo12 || 3}
            sm={6}
            sx={{
              background: "#FFFFFF",
              /* neutral/300 */

              border: "1px solid #D5D8E1",
              borderRadius: "5px",
            }}
            xs={12}
          >
            <Box
              sx={{
                alignItems: "center",
                borderRadius: 1,
                display: "flex",
                p: 2,
              }}
            >
              <IconButton
                sx={{ backgroundColor: "#215263", borderRadius: "50%" }}
                disableRipple
              >
                <Icon sx={{ color: "#FFFFFF" }} size="small" />
              </IconButton>
              <Box sx={{ ml: 2 }}>
                <Typography color="textSecondary" variant="overline">
                  {label}
                </Typography>
                <Typography color="textPrimary" variant="h6">
                  {content}
                </Typography>
              </Box>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  </Card>
);
