export const getInitials = (name = '') => name
  .replace(/\s+/, ' ')
  .split(' ')
  .slice(0, 2)
  .map((v) => v && v[0].toUpperCase())
  .join('');

export const getFullName = (firstName, middleName, lastName) => {
  let name = ""
  if (firstName) {
    name = firstName;
  }
  if (middleName) {
    if (!firstName) {
      name = middleName
    } else {
      name += ` ${middleName}`
    }
  }
  if (lastName) {
    if (!firstName && !middleName) {
      name = middleName
    } else {
      name += ` ${lastName}`
    }
  }
  return name
}