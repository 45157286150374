export const styles = {
  dropZoneArea: {
    '& .MuiDropzoneArea-root': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '180px',
    },
  },
  tableContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  table: {
    minWidth: 800,
  },
  avatar: {
    width: 64,
    height: 64,
  },
  titleWrapper: {
    alignItems: 'center',
    display: 'flex',
  },
  link: {
    display: 'block',
  },
  resourceUnavailable: {
    flexGm: 2,
    row: 1,
  },

  //Bulk Actions Menu
  buttonMenu: {
    '& .MuiPaper-root': {
      minWidth: 160,
    },
  },
  infoLine: {
    width: '100%',
    height: '1px',
    background: '#C1C4CC',
    my: "12px"
  },
  dialog: {
    '& .MuiDialogTitle-root': {
      textAlign: 'center',
      paddingBottom: "15px !important"
    },
    '& .MuiDialogContent-root': {
      paddingTop: "8px !important",
    },
    '& .MuiDialogActions-root': {
      paddingTop: "0px !important"
    }
  },
  tableMenuDialog: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
