import React from 'react';
import { Paper, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { styles } from './styles';
import moment from 'moment';

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  maxWidth: '100%',
  color: theme.palette.text.primary,
}));

const AuditLogTable = ({ user, title, desc, timestamp }) => {
  return (
    <StyledPaper
      sx={{
        my: 1,
        mx: 'auto',
        p: 2,
      }}
    >
      <Grid container rowSpacing={1}>
        <Grid item xs={16}>
          <Typography component="span" sx={styles.auditLogRowTitle}>{`${user} :`}</Typography>
          <Typography component="span" sx={styles.auditLogRowTitle} dangerouslySetInnerHTML={{ __html: title }}></Typography>
        </Grid>
        <Grid item xs={16}>
          <Typography sx={styles.auditLogDate}>{`${moment(timestamp).format("MMM DD YYYY HH:MM:SS")}`}</Typography>
        </Grid>
      </Grid>
    </StyledPaper>
  );
};

export default AuditLogTable;
