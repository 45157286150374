import React from "react";
import { useSelector } from "react-redux";

const withAuth = (Component) => (props) => {
  const { user } = useSelector((state) => state.session);
  /* eslint-disable react/jsx-props-no-spreading */
  return user ? <Component auth={user} {...props} /> : null;
};

export default withAuth;
