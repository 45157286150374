import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormHelperText, Grid, Divider, Stack, Skeleton, MenuItem } from '@mui/material';
import { styles } from './styles';
import { useNavigate } from 'react-router-dom';
import { InputField } from '../InputField';
import { LoadingButton } from '@mui/lab';
import { formDataApi } from '../../axios';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';

const DepartmentDetailsTab = ({ isEditMode, departmentDetails }) => {
  const navigate = useNavigate();
  const { status = [], results = {} } = departmentDetails?.data || {};
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const handleCustomDetails = async (details) => {
    try {
      setLoading(true);
      const bodyFormData = new FormData();
      for (const key in details) {
        bodyFormData.append(key, details[key]);
      }
      const pathToCall = isEditMode ? '/modifyDepartmentAction' : '/newDepartmentAction';
      const response = await formDataApi.post(pathToCall, bodyFormData);
      if (response?.data?.status !== 'ERROR') {
        enqueueSnackbar(response.data.message, { variant: 'success' });
        navigate('/settings/departments');
      } else {
        enqueueSnackbar(response.data.message, { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      id: results?.id ?? '',
      name: results?.name ?? '',
      status: results?.status ?? '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().max(255).required('Name is required'),
      status: Yup.string().max(255).required('Status is required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        handleCustomDetails(values);
        //helpers.resetForm();
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });
  if (departmentDetails?.isLoading) {
    return (
      <Box sx={{ py: 4 }}>
        <Skeleton height={42} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }
  return (
    <>
      <Grid container spacing={4} sx={{ py: 4 }}>
        <Grid item md={12} xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <div>
              <Grid container columnSpacing={6} rowSpacing={3}>
                <Grid item xs={4}>
                  <InputField
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    fullWidth
                    helperText={formik.touched.name && formik.errors.name}
                    label='Name'
                    name='name'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputField
                    fullWidth
                    error={Boolean(formik.touched.status && formik.errors.status)}
                    label='Status'
                    name={'status'}
                    helperText={formik.touched.status && formik.errors.status}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    select
                    children={status.map((status) => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                    value={formik.values.status}
                  />
                </Grid>
                {formik.errors.submit && (
                  <Grid item xs={12}>
                    <FormHelperText error>{formik.errors.submit}</FormHelperText>
                  </Grid>
                )}
                <Grid item xs={12} sx={styles.dividerWrapper}>
                  <Divider flexItem orientation='horizontal' sx={styles.divider} />{' '}
                </Grid>
                <Grid item xs={12}>
                  <Stack direction='row' justifyContent='end'>
                    <LoadingButton loading={loading} color='primary' size='large' type='submit' variant='contained'>
                      {isEditMode ? 'Update' : 'Create'}
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Grid>
            </div>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default DepartmentDetailsTab;
