import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';
import { usePopover } from '../../hooks/use-popover';
import { DotsVertical as DotsVerticalIcon } from '../../assets/icons/dots-vertical';
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import TrashIcon from '@mui/icons-material/Delete';
import { formDataApi } from '../../axios';
import { styles } from './styles';

import PermissionChecker from '../PermissionChecker';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

export const UsersTableMenu = ({ row, column, keyColumn, action: clickAction }) => {
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const [openDialog, setOPenDialog] = useState(false);
  const [action, setAction] = useState('');
  const currentUser = useSelector((state) => state.session.user);
  const uId = row[keyColumn];
  const isLoggedInUser = currentUser.userDBId === row["userID"];
  const params = useParams();

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const handleDialogClose = () => {
    setOPenDialog(false);
  };

  const handleDeleteUser = () => {
    handleClose();
    async function deleteUser() {
      const bodyFormData = new FormData();
      if(params.companyId){
        bodyFormData.append('company', params.companyId);
      }
      bodyFormData.append('id', uId);
      try {
        const response = await formDataApi.post('/deleteUser', bodyFormData);
        if (response.data) {
          clickAction(false, [], 'desc', 'id', 0)
          enqueueSnackbar('User deleted successfuly', { variant: 'success' });
        }
      } catch (err) {
        enqueueSnackbar('Something went wrong! User could not be deleted', { variant: 'error' });
      }
    }

    deleteUser();
    setOPenDialog(false);
  };

  const handleEditUser = () => {
    handleClose();
    navigate(params.companyId? `/company/${params.companyId}/users/edit/${uId}` : `/users/edit/${uId}`);
  };

  const handleSendCredentials = async () => {
    const id = row['id'];
    handleClose();
    try {
      const bodyFormData = new FormData();
      if(params.companyId){
        bodyFormData.append('company', params.companyId);
      }
      bodyFormData.append('id', id);
      const response = await formDataApi.post('/sendESSInviteToEmp', bodyFormData);
      if (response?.data?.status !== 'ERROR') {
        enqueueSnackbar(response.data.message, { variant: 'success' });
      } else {
        enqueueSnackbar(response.data.message, { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
    setOPenDialog(false);
  };

  const actionItems = [
    {
      label: 'Edit',
      type: 'edit',
      icon: <EditIcon />,
      onClick: () => handleEditUser(),
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_EDIT_SECUSER'],
    },
    {
      label: 'Delete',
      type: 'delete',
      icon: <TrashIcon />,
      onClick: handleDeleteUser,
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_DELETE_SECUSER'],
    },
    {
      label: 'Send Credentials',
      type: 'send_credentials',
      icon: <MailOutlineIcon />,
      onClick: handleSendCredentials,
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_SEND_ESS_INVITE_TO_EMPLOYEE'],
    },
  ];

  const handleMenuItemClick = (action) => {
    if (action.type !== 'edit') setOPenDialog(true);

    setAction(() => {
      switch (action.type) {
        case 'edit':
          return {
            title: 'Edit',
            callback: action.callback(uId),
          };

        case 'delete':
          return {
            title: 'Delete user',
            confirmation: `Are you sure you want to delete user?`,
            callback: () => action.callback(uId),
            ...action,
          };
        case 'send_credentials':
          return {
            title: 'Send Credentials',
            subtitle: "Please confirm if you'd like to send new credentials to the user.",
            confirmation: "This will reset their current password, and they will only be able to log in with the new password provided.",
            callback: () => action.callback(uId),
            ...action,
          };

        default:
          return action;
      }
    });
  };

  const renderDialogContent = (action) => (
    <DialogContent>
      <Box>
        <DialogTitle sx={styles.dialogTitle}>{action.title}</DialogTitle>
        <DialogContentText>
          {action?.subtitle && <Typography variant='h6' mb={2}>{action?.subtitle}</Typography>}
          {action.confirmation && action.confirmation}
        </DialogContentText>
      </Box>
      <DialogActions sx={styles.buttonsWrapper}>
        <Button size='large' onClick={handleDialogClose}>
          Cancel
        </Button>
        <Button size='large' variant='contained' onClick={action.callback}>
          Confirm
        </Button>
      </DialogActions>
    </DialogContent>
  );

  const renderMenuActions = () =>
    actionItems.map((action) => (
      (action.type !== "delete" ||  !isLoggedInUser)  && <PermissionChecker requiredPermissions={action.permissions} noAuthRedirect={false}>
        <MenuItem
          key={action.type}
          onClick={() =>
            handleMenuItemClick({
              label: action.label,
              type: action.type,
              callback: action.onClick,
            })
          }
        >
          <ListItemIcon>{action.icon}</ListItemIcon>
          <ListItemText variant='body2'>{action.label}</ListItemText>
        </MenuItem>
      </PermissionChecker>
    ));

  return (
    <>
      <IconButton onClick={handleOpen} ref={anchorRef} color='primary'>
        <DotsVerticalIcon fontSize='small' />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList dense>{renderMenuActions()}</MenuList>
      </Menu>
      <Dialog open={openDialog} onClose={() => setOPenDialog(false)}>
        {renderDialogContent(action)}
      </Dialog>
    </>
  );
};
