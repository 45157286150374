import React, { useMemo } from 'react';
import { Box, MenuItem, TextField, Typography } from '@mui/material';
import { styles } from './styles';
import { InputField } from '../../../components/InputField';
import { utilStyles } from '../../../utils/styles';
import { styles as viewStyles } from '../ViewChangeRequest/styles';
import { useSelector } from 'react-redux';
import { hasPermission } from '../../../utils/permission';
import minorCategoryIcon from '../../../assets/images/minorCategory.svg';
import majorCategoryIcon from '../../../assets/images/majorCategory.svg';
import { PositionStatusChip } from '../../../components/Position/PositionStatusChip';

const UpdateChangeRequests = ({ position, formik, categoryType, basicAttributesCategory, metaCustomFields, isBulkModify, preCreateUpdateDetails }) => {
  const {
    departmentsList = [],
    designationsList = [],
    locationsList = [],
    employeeList = [],
    availablePositionStatuses = []
  } = preCreateUpdateDetails || {};
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasCompaPermisson = hasPermission(authorities, ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_COMPA_CREATE_CHANGE_REQUEST'])

  const designationOptions = useMemo(() => !designationsList?.length
    ? [{ id: null, name: '' }]
    : designationsList.filter(item => item?.id !== position.designationId).map((item) => ({ id: item?.id, name: item?.name })),[designationsList, position?.designationId]);
  const departmentOptions = useMemo(() => !departmentsList?.length
    ? [{ id: null, name: '' }]
    : departmentsList.filter(item => item?.id !== position.departmentId).map((item) => ({ id: item?.id, name: item?.name })),[departmentsList, position?.departmentId]);
  const locationOptions = useMemo(() => !locationsList?.length
    ? [{ id: null, name: '' }]
    : locationsList.filter(item => item?.id !== position.locationId).map((item) => ({ id: item?.id, name: item?.name })),[locationsList, position?.locationId]);
  const recruiterOptions = useMemo(() => !employeeList?.length
    ? [{ id: null, name: '' }]
    : employeeList.filter(item => item?.id !== position.employeeId).map((item) => ({ id: item?.id, name: item?.fullName })),[employeeList, position?.employeeId]);

  const filteredStatus = useMemo(() => {
    return availablePositionStatuses?.filter((status) => status !== position?.status);
  },[availablePositionStatuses, position?.status])

  const compaData = userHasCompaPermisson ?
    [{
      attributeName: 'Budget Lower Limit',
      currentValue: position?.budgetLowerLimit,
      category: basicAttributesCategory['Budget Lower Limit']?.name,
      newValue: (
        <InputField
          error={Boolean(formik.touched.budgetLowerLimit && formik.errors.budgetLowerLimit)}
          fullWidth
          helperText={formik.touched.budgetLowerLimit && formik.errors.budgetLowerLimit}
          label='Budget Lower Limit'
          name='budgetLowerLimit'
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.budgetLowerLimit}
          type="number"
        />
      ),
    },
    {
      attributeName: 'Budget Mid Limit',
      currentValue: position?.budgetMidLimit,
      category: basicAttributesCategory['Budget Mid Limit']?.name,
      newValue: (
        <InputField
          error={Boolean(formik.touched.budgetMidLimit && formik.errors.budgetMidLimit)}
          fullWidth
          helperText={formik.touched.budgetMidLimit && formik.errors.budgetMidLimit}
          label='Budget Mid Limit'
          name='budgetMidLimit'
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.budgetMidLimit}
          type="number"
        />
      ),
    },
    {
      attributeName: 'Budget Upper Limit',
      currentValue: position?.budgetUpperLimit,
      category: basicAttributesCategory['Budget Upper Limit']?.name,
      newValue: (
        <InputField
          error={Boolean(formik.touched.budgetUpperLimit && formik.errors.budgetUpperLimit)}
          fullWidth
          helperText={formik.touched.budgetUpperLimit && formik.errors.budgetUpperLimit}
          label='Budget Upper Limit'
          name='budgetUpperLimit'
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.budgetUpperLimit}
          type="number"
        />
      ),
    }
    ]
    : []

  const data = [
    {
      attributeName: 'Designation',
      currentValue: position?.designation?.name,
      category: basicAttributesCategory['Designation']?.name,
      newValue: (
        <InputField
          error={Boolean(formik.touched.designation && formik.errors.designation)}
          fullWidth
          helperText={formik.touched.designation && formik.errors.designation}
          label='Designation'
          name='designation'
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          select
          value={formik.values.designation}
        >
          {designationOptions.map((designationOption) => (
            <MenuItem key={designationOption.id} value={designationOption.id}>
              {designationOption.name}
            </MenuItem>
          ))}
        </InputField>
      ),
    },
    ...['On Hold - HM Delay', 'Offer Released', 'Offer Accepted', 'Offer Negotiation'].indexOf(position?.status) < 0 ?  [{
      attributeName: 'Status',
      currentValue: <PositionStatusChip label={position?.status } />,
      category: basicAttributesCategory['Position Status']?.name,
      newValue: (
        <InputField
          error={Boolean(formik.touched.status && formik.errors.status)}
          fullWidth
          helperText={formik.touched.status && formik.errors.status}
          label='Status'
          name='status'
          select
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.status}
        >
          {(filteredStatus || [])
            .map(
              (status, index) => (
                <MenuItem key={index} value={status}>
                  <PositionStatusChip label={status } />
                </MenuItem>
              )
            )}
        </InputField>
      ),
    }] : [],
    {
      attributeName: 'Department',
      currentValue: position?.department?.name,
      category: basicAttributesCategory['Department']?.name,
      newValue: (
        <InputField
          error={Boolean(formik.touched.department && formik.errors.department)}
          fullWidth
          helperText={formik.touched.department && formik.errors.department}
          label='Department'
          name='department'
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.department}
          select
        >
          {departmentOptions.map((departmentOption) => (
            <MenuItem key={departmentOption.id} value={departmentOption.id}>
              {departmentOption.name}
            </MenuItem>
          ))}
        </InputField>
      ),
    },
    {
      attributeName: 'Location',
      currentValue: position?.location?.name,
      category: basicAttributesCategory['Location']?.name,
      newValue: (
        <InputField
          error={Boolean(formik.touched.location && formik.errors.location)}
          fullWidth
          helperText={formik.touched.location && formik.errors.location}
          label='Location (Optional)'
          name='location'
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.location}
          select
        >
          {locationOptions.map((departmentOption) => (
            <MenuItem key={departmentOption.id} value={departmentOption.id}>
              {departmentOption.name}
            </MenuItem>
          ))}
        </InputField>
      ),
    },
    ...compaData,
    ...metaCustomFields,
  ];

  const filteredData = useMemo(() => {
    return data.filter(item => item.category === categoryType)
  },[data, categoryType])

  return (
    <Box sx={utilStyles.mt(3)}>
      <Box sx={{ display: 'flex' }}>
        <Box sx={styles.flexItem1(isBulkModify)}>
          <Typography variant='tableHeader' color='text.secondary'>
            ATTRIBUTE NAME
          </Typography>
        </Box>
        {!isBulkModify && (
          <Box sx={styles.flexItem2}>
            <Typography variant='tableHeader' color='text.secondary'>
              CURRENT VALUE
            </Typography>
          </Box>
        )}
        <Box sx={viewStyles.flexItem3(isBulkModify)}>
          <Typography variant='tableHeader' color='text.secondary'>
            NEW VALUE
          </Typography>
        </Box>
      </Box>
      {preCreateUpdateDetails?.isLoading
        ? <Box mt="16px" textAlign={"center"}>Loading...</Box>
        : filteredData.map((field) => (
        <Box sx={{ display: 'flex' }}>
          <Box sx={styles.flexItem1(isBulkModify)}><img src={field.category === "Major" ? majorCategoryIcon : minorCategoryIcon} alt="Minor" style={{paddingRight: "6px"}} />{field.attributeName}</Box>
          {!isBulkModify && <Box sx={styles.flexItem2}>{field.currentValue && field.currentValue !== 'undefined' ? field.currentValue : ""}</Box>}
          <Box sx={styles.flexItem3(isBulkModify)}>{field.newValue}</Box>
        </Box>
      ))}
      <TextField
        sx={{ mt: 3 }}
        error={Boolean(formik.touched.comments && formik.errors.comments)}
        fullWidth
        placeholder='Type here'
        size='small'
        variant='outlined'
        multiline
        rows={4}
        label='Comment'
        name='comments'
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.comments}
      />
    </Box>
  );
};

export default UpdateChangeRequests;
