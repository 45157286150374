import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { changeRequestInitialState } from "../state";
import { api, formDataApi } from "../../axios";
import { endpoints } from "../constants";


export const getChangeRequests = createAsyncThunk('getChangeRequests', (data = {}, { rejectWithValue }) => api.post(`${endpoints.LIST_CHANGE_REQUEST}?${data.query.toString()}`, data)
  .then((res) => {
    if (res?.data?.status !== 'SUCCESS') {
      return rejectWithValue(res.data)
    }
    return res.data
  })
  .catch((error) => rejectWithValue(error)))

export const getApproverChangeRequests = createAsyncThunk('getApproverChangeRequests', (data = {}, { rejectWithValue }) => formDataApi.post(`${endpoints.SEARCH_CHANGE_REQUEST_ACTION}?${data.query.toString()}`, data.body)
  .then((res) => {
    if (res?.data?.status !== 'SUCCESS') {
      return rejectWithValue(res.data)
    }
    return res.data
  })
  .catch((error) => rejectWithValue(error)))

export const bulkUpdateCRStatuses = createAsyncThunk('bulkUpdateCRStatuses', (data = {}, { rejectWithValue }) => formDataApi.post(`${endpoints.BULK_UPDATE_CR_STATUSES}`, data.body)
  .then((res) => {
    if ((res?.data?.status || res.response.data.status) !== 'SUCCESS') {
      return rejectWithValue(res.data || res.response.data)
    }
    return res.data
  })
  .catch((error) => rejectWithValue(error)))


export const updateCRStatus = createAsyncThunk('updateCRStatus', (data = {}, { rejectWithValue }) => formDataApi.post(`${endpoints.CHANGE_CR_STATUS}`, data.body)
  .then((res) => {
    if ((res?.status || res?.data?.status || res.response.data.status) !== 'SUCCESS') {
      return rejectWithValue(res.data || res.response.data)
    }
    return res.data
  })
  .catch((error) => {
    rejectWithValue(error)
  }))

export const cancelChangeRequest = createAsyncThunk('cancelActiveChangeRequest', (data = {}, { rejectWithValue }) => formDataApi.post(`${endpoints.CANCEL_CHANGE_REQUEST}`, data.body)
  .then((res) => {
    if ((res?.status || res?.data?.status || res.response.data.status) !== 'SUCCESS') {
      return rejectWithValue(res.data || res.response.data)
    }
    return res.data
  })
  .catch((error) => {
    rejectWithValue(error)
  }))

export const searchChangeRequestAction = createAsyncThunk('searchChangeRequestAction', (data = {}, { rejectWithValue }) => formDataApi.post(endpoints.SEARCH_CHANGE_REQUEST_ACTION, data)
  .then((res) => {
    if (res?.data?.status !== 'SUCCESS') {
      return rejectWithValue(res.data)
    }
    return res.data
  })
  .catch((error) => rejectWithValue(error)))

export const deleteChangeRequest = createAsyncThunk('deleteChangeRequest', (data = {}, { rejectWithValue }) => api.post(endpoints.DELETE_CHANGE_REQUEST, data)
  .then((res) => {
    if (res?.data?.status !== 'SUCCESS') {
      return rejectWithValue(res.data)
    }
    return res.data
  })
  .catch((error) => rejectWithValue(error)))


const changeRequests = createSlice({
  name: "changeRequests",
  initialState: changeRequestInitialState,
  reducers: {
    updateCRs(state, action) {
      const { changeRequestCount = 0, changeRequests = [] } = action.payload;
      state.changeRequests.changeRequests = changeRequests;
      state.changeRequests.changeRequestCount = changeRequestCount;
    },
    updateApproverCRs(state, action) {
      const { changeRequestCount = 0, changeRequests = [] } = action.payload;
      state.approverChangeRequests.data.changeRequests = changeRequests;
      state.approverChangeRequests.data.changeRequestCount = changeRequestCount;
    },
    updateSelectedCRs(state, action) {
      const { isChecked, rowId } = action.payload;

      if (isChecked) {
        state.selectedCRs.push(rowId);
        return;
      }
      state.selectedCRs = state.selectedCRs.filter((selectedRow) => selectedRow !== rowId);
    },
    updateAllSelectedCRs(state, action) {
      const { isChecked, isApproverCR } = action.payload;
      state.selectedCRs = [];
      if (isChecked && isApproverCR) {
        state?.approverChangeRequests?.data?.changeRequests.map((row) => state.selectedCRs.push(row['id']));
        return;
      } else if (isChecked) {
        state?.changeRequests?.changeRequests.map((row) => state.selectedCRs.push(row['id']));
        return;
      }
      state.selectedCRs = [];
    },
    resetSelectedCRs(state, action) {
      state.selectedCRs = [];
    },
  },
  extraReducers: {
    [getChangeRequests.pending]: (state) => {
      state.isLoading = true
    },
    [getChangeRequests.rejected]: (state) => {
      state.hasErrored = true
    },
    [getChangeRequests.fulfilled]: (state, action) => {
      state.isLoading = false
      state.changeRequests = action.payload
    },
    [getApproverChangeRequests.pending]: (state) => {
      state.approverChangeRequests.isLoading = true
    },
    [getApproverChangeRequests.rejected]: (state) => {
      state.approverChangeRequests.hasErrored = true
    },
    [getApproverChangeRequests.fulfilled]: (state, action) => {
      state.approverChangeRequests.isLoading = false
      state.approverChangeRequests.data = action.payload
    },
    [searchChangeRequestAction.pending]: (state) => {
      state.isLoading = true
    },
    [searchChangeRequestAction.rejected]: (state) => {
      state.hasErrored = true
    },
    [searchChangeRequestAction.fulfilled]: (state, action) => {
      state.isLoading = false
      state.crPolicies = action.payload
    },
  }
});

export const { updateSelectedCRs, updateAllSelectedCRs, updateApproverCRs,
  resetSelectedCRs, updateCRs } = changeRequests.actions;

export default changeRequests.reducer