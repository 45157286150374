import React from 'react';
import { Box, Typography } from '@mui/material';
import { styles } from './styles';
import AuditLogTable from './AuditLogTable';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AuditLog = ({ data }) => {
  return (
    <Accordion>
      <AccordionSummary sx={{ p: 0 }} expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
        <Typography sx={styles.auditLogTitle}>Audit Log</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={styles.auditLogBox}>
          {data?.map((item, index) => (
            <AuditLogTable
              key={index}
              user={item.user}
              title={item.message}
              desc={item.desc}
              timestamp={item.timestamp}
            />
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default AuditLog;
