import React, { useState, useEffect } from 'react'
import {
  IconButton,
  Menu,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import { usePopover } from '../../hooks/use-popover'
import { DotsVertical as DotsVerticalIcon } from '../../assets/icons/dots-vertical'
import { Check, Clear, } from '@mui/icons-material'
import { styles } from './styles'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom'
import { updateCRStatus, getApproverChangeRequests, getChangeRequests, resetSelectedCRs, cancelChangeRequest } from '../../store/slices/listChangeRequests'
import usePaginationAttributes from '../../hooks/use-pagination-attributes'
import { useSnackbar } from 'notistack'
import { ApproveRejectDialogContent} from '../../pages/ChangeRequests/ViewChangeRequest';

export const ChangeRequestTableManue = ({
  row,
  tab
}) => {
  const [anchorRef, open, handleOpen, handleClose] = usePopover()
  const [openDialog, setOpenDialog] = useState(false);
  const [action, setAction] = useState('');
  const [isRequestingUpdateCRStatus, setRequestingUpdateCRStatus] = useState(false);
  const { pageSize } = usePaginationAttributes();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.session.user);

  const reFetchRequests = () => {
    const newBody = new FormData();
    newBody.append('s', 'Active');
    newBody.append('a', currentUser?.empDBID)
    const query = new URLSearchParams({});
    query.append('offset', 0 * pageSize);
    query.append('max', pageSize);

    dispatch(getApproverChangeRequests({
      query,
      body: newBody
    }))

    dispatch(getChangeRequests({
      query
    }))
  }
  const changeCRStatus = (payload) => {
    if(isRequestingUpdateCRStatus) return;
    setRequestingUpdateCRStatus(true);
    const body = new FormData();
    body.append('changeRequestId', row?.id);
    if(payload.actType !== 'Cancel'){
      Object.keys(payload).forEach(key => body.append(key, payload[key]))
      dispatch(updateCRStatus({
        body
      })).then(res => {
        if(res?.payload?.status === "SUCCESS")
        enqueueSnackbar(res?.payload?.message, { variant: 'success' });
      else 
        enqueueSnackbar(res?.payload?.message, { variant: 'error' });
        dispatch(resetSelectedCRs())
        reFetchRequests()
      }).catch((err) => {
        enqueueSnackbar("Failed to update change request.", { variant: "error" });
      }).finally(() => {
        setRequestingUpdateCRStatus(false);
      })
    }else{
      body.append('rejectionComment', payload?.comment);
      dispatch(cancelChangeRequest({
        body
      })).then(res => {
        if(res?.payload?.status === "SUCCESS")
        enqueueSnackbar(res?.payload?.message, { variant: 'success' });
      else 
        enqueueSnackbar(res?.payload?.message, { variant: 'error' });
        dispatch(resetSelectedCRs())
        reFetchRequests()
      }).catch((err) => {
        enqueueSnackbar("Failed to cancel change request.", { variant: "error" });
      }).finally(() => {
        setRequestingUpdateCRStatus(false);
      })
    }
  }

  const handleModifyPolicy = () => {
    handleDialogClose()
  }

  const handleDialogClose = () => {
    setOpenDialog(false);
    handleClose()
    setAction();
  };


  const [hasApproverRole, setHasApproverRole] = useState(false)

  useEffect(() => {
    const approvers = row?.position?.changeRequestPolicy?.approvers;
    const currentCRCategory = row?.categoryType?.name;
    if (currentUser?.empDBID && currentCRCategory && Array.isArray(approvers) && approvers.length > 0 ) {
      const currentCRCategoryApprover = approvers.find(item => (item?.categoryType?.name === currentCRCategory) && (parseInt(currentUser?.empDBID) === item?.employeeId))
      if(currentCRCategoryApprover)  setHasApproverRole(true)
    }
  }, [currentUser?.empDBID, row?.position?.changeRequestPolicy?.approvers])

  let actionMenus = [];

  if(row?.status === "Active"){
      row?.createdBy?.employeeId === parseInt(currentUser?.empDBID) && actionMenus.push({
        label: 'Modify',
        type: 'modify_policy',
        icon: <EditIcon />,
        onClick: handleModifyPolicy,
      })
      row?.createdBy?.employeeId === parseInt(currentUser?.empDBID) && tab !== "approver" && actionMenus.push({
        label: 'Cancel',
        type: 'cancel_policy',
        icon: <CloseIcon />,
        primaryButtonLabel: "Cancel"
      })
  }

  if(hasApproverRole && tab === "approver"){
    actionMenus.push({
      label: 'Approve',
      type: 'approve',
      icon: <Check />,
      primaryButtonLabel: "Approve"
    })
    actionMenus.push({
      label: 'Reject',
      type: 'reject',
      icon: <Clear />,
      primaryButtonLabel: "Reject"
    })
  }

  const handleMenuItemClick = (action) => {
    if (action.type === 'modify_policy') {
      navigate(`/changeRequests/${action?.data?.id}/modify/${action?.data?.parentEntityId}`);
    }

    setOpenDialog(true);
    setAction(action)
  }

  const renderMenuActions = () =>
    actionMenus.map((action) => (
      <MenuItem
        key={action.type}
        onClick={() =>
          handleMenuItemClick({
            label: action.label,
            type: action.type,
            callback: action.onClick,
            data: row,
            ...action
          })
        }
      >
        <ListItemIcon>{action.icon}</ListItemIcon>
        <ListItemText variant='body2'>{action.label}</ListItemText>
      </MenuItem>
    ))

  return (
    <>
      <IconButton onClick={handleOpen} ref={anchorRef} color='primary' disabled={row['publicationStatus'] === 'Published' || actionMenus?.length === 0}>
        <DotsVerticalIcon fontSize='small' />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuList dense>{renderMenuActions()}</MenuList>
      </Menu>
      <Dialog sx={styles.dialog} open={openDialog} onClose={handleDialogClose}>
        <ApproveRejectDialogContent 
          actionType={action?.type} 
          changeRequest={row}
          handleClose={handleDialogClose} 
          handleConfirm={changeCRStatus}
        />
      </Dialog>
    </>
  )
}