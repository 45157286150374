import React, { useEffect, useReducer, useState } from 'react';
import { Box, Button, Card, CardContent, Container, Typography } from '@mui/material';
import { CRPoliciesFilter } from '../../components/CRPolicies/CRPoliciesFilter';
import { Plus } from '../../assets/icons/plus';
import CRPoliciesAdd from '../../components/CRPoliciesAdd';
import CustomTable from '../../components/CustomTable';
import { useSelection } from '../../hooks/use-selection';
import { columns } from './columns.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { getCRPolicies, searchChangeCRPolicesAction } from '../../store/slices/listCRPolicies';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { styles } from '../ChangeRequests/styles';

import PermissionChecker from '../../components/PermissionChecker';

const CRPolicies = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const crPolicies = useSelector((state) => state.crPolicies);
  const { enqueueSnackbar } = useSnackbar();
  const [controller, setController] = useState({
    filters: [],
    page: 0,
    sort: 'desc',
    sortBy: 'id',
    view: 'all',
    searched: false,
    pageSize: 10,
  });
  const [addPoliciesShow, setAddPoliciesShow] = useState(false);
  const [viewMappedPositionsData, setViewMappedPositionsData] = useReducer(
    (state, action) => ({
      ...state,
      ...action,
    }),
    {
      show: false,
      id: '',
    }
  );

  const [selectedPositions, handleSelect, handleSelectAll] = useSelection(crPolicies.crPolicies);

  useEffect(() => {
    if (!addPoliciesShow && !controller.filters.length) {
      const query = new URLSearchParams({});
      if (controller.sortBy) {
        query.append('sort', controller.sortBy);
        query.append('order', controller.sort);
      }
      query.append('offset', controller.page * controller.pageSize);
      query.append('max', controller.pageSize);
      dispatch(
        getCRPolicies({
          query,
        })
      );
    }
  }, [dispatch, addPoliciesShow, controller.page, controller.pageSize, controller.filters, controller.sort, controller.sortBy]);

  useEffect(() => {
    if (!!Object.keys(crPolicies.deleteCRPolicy).length &&  crPolicies.deleteCRPolicy.status) {
      enqueueSnackbar(crPolicies.deleteCRPolicy.message, { variant: crPolicies.deleteCRPolicy.status === "SUCCESS" ? 'success' : "error" });
      if(crPolicies.deleteCRPolicy.status === "SUCCESS"){
        const query = new URLSearchParams({});
        query.append('sort', controller.sortBy);
        query.append('order', controller.sort);
        query.append('offset', controller.page * controller.pageSize);
        query.append('max', controller.pageSize);
        dispatch(
          getCRPolicies({
            query,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crPolicies.deleteCRPolicy]);

  useEffect(() => {
    if (!!controller.filters.length) {
      const data = new FormData();
      controller.filters.forEach((filter) => data.append(filter.property, filter.value));
      dispatch(searchChangeCRPolicesAction(data));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controller.filters]);

  const handleFiltersApply = (newFilters) => {
    const parsedFilters = newFilters.map((filter) => ({
      property: filter.property.name,
      value: filter.value,
      operator: filter.operator.value,
    }));

    setController({
      ...controller,
      page: 0,
      filters: parsedFilters,
      searched: true,
    });
  };

  const handleFiltersClear = () => {
    setController({
      ...controller,
      page: 0,
      filters: [],
      searched: false,
    });
  };

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage - 1,
    });
  };

  const handlePageSizeChange = (newPageSize) => {
    setController({
      ...controller,
      page: 0,
      pageSize: newPageSize
    });
  }

  const handleSortChange = (event, property) => {
    const isAsc = controller.sortBy === property && controller.sort === 'asc';

    setController({
      ...controller,
      page: 0,
      sort: isAsc ? 'desc' : 'asc',
      sortBy: property,
    });
  };

  const handleQueryChange = (newQuery) => {
    setController({
      ...controller,
      page: 0,
      query: newQuery,
    });
  };

  const handleRowClick = (row) => {
    navigate(`/changerequest/crpolicies/view/${row}`);
  };

  return (
    <Box sx={styles.growBox}>
      <Container maxWidth='lg' sx={styles.pageWrapper}>
        <Box id='changeRequestHeader' sx={{ pt: '12px', pb: '32px' }}>
          <Box sx={styles.flexCenterNone}>
            <Typography variant='h4'>Change Policy</Typography>
            <Box sx={styles.growBox} />
            {/*<Grid item xs='auto'>
                <Button
                  sx={styles.mx(2)}
                  color='primary'
                  onClick={() => setOpenBulkUploadDialog(true)}
                  size='large'
                  startIcon={<UploadIcon fontSize='small' />}
                  variant='outlined'
                >
                  Bulk upload
                </Button>
                <PositionBulkUploadDialog
                  openBulkUploadDialog={openBulkUploadDialog}
                  onResult={handleBulkUploadResult}
                />
              </Grid>*/}
            {!addPoliciesShow ? (
              <PermissionChecker
                requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_CREATE_CHANGE_REQUEST_POLICY']}
                noAuthRedirect={false}
              >
                <Button
                  color='primary'
                  onClick={() => setAddPoliciesShow(!addPoliciesShow)}
                  size='large'
                  startIcon={<Plus fontSize='small' />}
                  variant='contained'
                >
                  Add
                </Button>
              </PermissionChecker>
            ) : (
              <></>
            )}
          </Box>
        </Box>
        <Card sx={styles.dataWrappper}>
          <CardContent>
            {addPoliciesShow ? (
              <CRPoliciesAdd addPoliciesShow={addPoliciesShow} setAddPoliciesShow={setAddPoliciesShow} />
            ) : (
              <>
                <CRPoliciesFilter
                  disabled={false}
                  filters={controller.filters}
                  onFiltersApply={handleFiltersApply}
                  onFiltersClear={handleFiltersClear}
                  onQueryChange={handleQueryChange}
                  query={controller.query}
                  selectedPositions={selectedPositions}
                  filterProperties={columns}
                  updatePolicyMappings={false}
                />
                <CustomTable
                  isLoading={crPolicies.isLoading}
                  onRowClick={handleRowClick}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                  onSelect={handleSelect}
                  onSelectAll={handleSelectAll}
                  onSortChange={handleSortChange}
                  page={controller.page + 1}
                  columns={columns}
                  rows={crPolicies.crPolicies}
                  rowsCount={crPolicies.changeRequestPolicyCount}
                  selectedRows={selectedPositions}
                  sort={controller.sort}
                  sortBy={controller.sortBy}
                  showRowSelector={false}
                  showPagignation
                  keyColumn='id'
                  route='changerequest/crpolicies'
                  rowId={viewMappedPositionsData.id}
                />
              </>
            )}
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default CRPolicies;
