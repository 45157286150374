export const styles = {
  tableContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  table: {
    minWidth: 800,
  },
  avatar: {
    width: 64,
    height: 64,
  },
  titleWrapper: {
    alignItems: "center",
    display: "flex",
  },
  link: {
    display: "block",
  },
  resourceUnavailable: {
    flexGrow: 1,
    m: 2,
  },
  tableCell: {
    whiteSpace: 'nowrap', // Prevent text from wrapping
    overflow: 'hidden',   // Hide overflow text
    textOverflow: 'ellipsis', // Display ellipsis (...) for overflow
    maxWidth: "250px",
  }
};
