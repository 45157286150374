export const styles = {
  addTable: {
    width: ' 100%',
    p: '15px 50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  backButton: {
    width: '100px',
    color: '#3F455A'
  },
  addTableTitle: {
    color: '#3F455A'
  },
  basicInformation: {
    width: '100%',
    mt: '24px',
  },
  basicInformationText: {
    color: '#9AA1B7'
  },
  display: 'flex',
  inputBox: {
    maxWidth: "850px",
    flexWrap: 'wrap',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pt: '20px'
  },
  inputs: {
    width: '400px',
    m: '7px 0'
  },
  removeButtonBox: {
    width: '400px'
  },
  removeButton: {
    color: '#7C85A2'
  },
  bottomBLine: {
    width: '100%',
    height: '1px',
    background: '#C1C4CC',
    mt: '30px'
  },
  inputsAdditionalPreOfferBox: {
    maxWidth: "850px",
    flexWrap: 'wrap',
    pt: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  employeeListsBox: {
    width: '100%',
  },
  inputsAdditionalPreOffer: {
    width: '400px',
    m: '5px 0'
  },
  additionalPreOfferButton: {
    maxWidth: "850px",
    flexWrap: 'wrap',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mt: '20px',
  },
  createButtonBox: {
    width: '100%',
    pt: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    mt: '5px'
  },
  createButton: {
    mt: '10px'
  }
}
