import React from 'react';
import { Box, Typography } from '@mui/material';
import { styles } from '../ModifyChangeRequest/styles';
import { utilStyles } from '../../../utils/styles';
import { styles as viewStyles } from './styles';
import moment from 'moment';
import minorCategoryIcon from '../../../assets/images/minorCategory.svg';
import majorCategoryIcon from '../../../assets/images/majorCategory.svg';
import { PositionStatusChip } from '../../../components/Position/PositionStatusChip';

const UpdateChangeRequests = ({ changeRequest }) => {
  const { attributes,categoryType } = changeRequest.changeRequest;

  const data = attributes.map((data) => ({
    attributeName: data.displayName,
    name: data.name,
    currentValue: data?.type === "Date" ?
      (!!data.currentValue ? moment(data.currentValue).format('DD-MMM-YYYY') : "") : data.currentValue,
    newValue: data.hasOwnProperty('newValueInstance') ? data.newValueInstance.name : data.newValue,
  }));

  return (
    <Box sx={utilStyles.mt(3)}>
      <Box sx={{ display: 'flex' }}>
        <Box sx={styles.flexItem1(false)}>
          <Typography variant='tableHeader' color='text.secondary'>
            ATTRIBUTE NAME
          </Typography>
        </Box>
        <Box sx={styles.flexItem2}>
          <Typography variant='tableHeader' color='text.secondary'>
            ORIGINAL VALUE
          </Typography>
        </Box>
        <Box sx={viewStyles.flexItem3(false)}>
          <Typography variant='tableHeader' color='text.secondary'>
            REQUESTED VALUE
          </Typography>
        </Box>
      </Box>
      {data.map((field) => (
        <Box sx={{ display: 'flex' }}>
          <Box sx={styles.flexItem1(false)}><img src={categoryType?.name === "Major" ? majorCategoryIcon : minorCategoryIcon} alt={categoryType?.name} style={{paddingRight: "6px"}} />{field.attributeName}</Box>
          <Box sx={styles.flexItem2}>{field.currentValue && field.currentValue !== 'undefined' 
            ? (field.name === "status" ? <Box><PositionStatusChip label={field.currentValue } /></Box> : field.currentValue )
            : "" }
          </Box>
          <Box sx={viewStyles.flexItem3(false)}>{field.name === "status" 
            ? <Box><PositionStatusChip label={field.newValue } /></Box>  
            : field.newValue}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default UpdateChangeRequests;
