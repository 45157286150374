import { useEffect, useState } from 'react';
import { Grid, Box, Button, Card, CardContent, Container, Typography } from '@mui/material';
import { ChevronLeft as ChevronLeftIcon } from '../../assets/icons/chevron-left';
import { styles } from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import { formDataApi } from '../../axios';
import DepartmentData from '../../components/DepartmentData';
import { useSnackbar } from 'notistack';

const ViewDepartment = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [departmentDetails, setDepartmentDetails] = useState({ data: {}, isLoading: true });

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setDepartmentDetails({ ...departmentDetails, isLoading: true });
    async function fetchData() {
      const bodyFormData = new FormData();
      bodyFormData.append('id', params.id);
      try {
        const response = await formDataApi.post('/viewDepartment', bodyFormData);
        if (response?.data) {
          setDepartmentDetails({ data: response.data.results, isLoading: false });
        }
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      } finally {
      }
    }
    fetchData();
  }, [params.id]);

  return (
    <Box sx={styles.growBox}>
      <Container maxWidth='lg' sx={styles.pageWrapper}>
        <Grid container direction='column' spacing={2}>
          <Grid sx={{ py: '32px !important' }} item container justifyContent='space-between'>
            <Grid item>
              <Typography variant='h4'>Departments</Typography>
            </Grid>
          </Grid>
          <Grid item sx={{ pt: '0px !important' }}>
            <Card {...props}>
              <CardContent>
                <Grid container direction='column' spacing={2}>
                  <Grid item>
                    <Button
                      startIcon={<ChevronLeftIcon />}
                      variant='text'
                      color='secondary'
                      onClick={() => navigate('/settings/departments')}
                    >
                      Back
                    </Button>
                  </Grid>
                </Grid>
                <Grid item>
                  <DepartmentData department={departmentDetails} id={params.id} />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ViewDepartment;
