import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { useStyles } from './styles';
import Logo from '../../assets/images/Logo.png';

import { Helmet } from 'react-helmet-async';
import { usePageTitles } from '../../hooks/use-page-title';

const LoginLayout = ({ children }) => {
  const styles = useStyles();

  const pageTitle = usePageTitles();

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <main className={styles.root}>
        <div className={styles.leftSidebar}>
          <div className={styles.container}>
            <Grid container direction='column' spacing={1}>
              <Grid item>
                <img src={Logo} alt='ANSR Logo' className={styles.logo} />
              </Grid>
              <Grid item>
                <Typography variant='h2' sx={{ color: '#FFF' }}>
                  HQ: BUILDING GLOBAL TEAMS
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' sx={{ color: '#FFF' }}>
                  Enabling enterprises to build and scale global teams through effective Demand planning, tracking and
                  Change Management.
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={styles.overviewContainer}></div>
        </div>
        <Paper className={styles.rightSidebar}>
          <form className={styles.form}>{children}</form>
        </Paper>
      </main>
    </>
  );
};

export default LoginLayout;
